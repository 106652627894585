<style lang="scss">
</style>

<template>
  <v-col cols="12" :class="{ 'd-none': curriculums.length === 0 }">
    <group-card title="Eelnevalt vaadatud tööotsijad">
      <div class="mx-md-5 mt-5">
        <v-container>
          <CurriculumsGrid :curriculums="curriculums"></CurriculumsGrid>

          <v-row justify="center" v-if="!allLoaded">
            <v-col cols="12" class="d-flex justify-center">
              <div
                class="mb-5"
                :title="allLoaded ? 'Rohkem tööotsijaid ei ole' : 'Lae rohkem tööotsijaid'"
              >
                <base-btn
                  color="black"
                  :dark="!allLoaded"
                  :loading="loading"
                  @click="loadMore"
                  small
                >
                  Rohkem vaadatud tööotsijaid
                </base-btn>
              </div>
            </v-col>
          </v-row>
        </v-container>
      </div>
    </group-card>
  </v-col>
</template>

<script>
import CurriculumsGrid from "../../curriculum/components/index/CurriculumsGrid.vue";
import cacheMixin from "@/mixins/cacheMixin";
export default {
  mixins: [cacheMixin],
  components: {
    CurriculumsGrid,
  },
  props: {
    excludedIds: {
      type: Array,
      default: () => [],
    },
    refetchEachTime: {
      type: Boolean,
      default: true,
    },
  },

  created() {
    this.pullCache(["curriculums", "query", "total", "loadedPage"], "LastVisitedCurriculums");
    this.fetch(this.refetchEachTime);
  },
  destroyed() {
    this.saveCache();
  },

  data() {
    return {
      loading: null,
      curriculums: [],
      query: {
        page: 1,
        per_page: 8,
        order_by: "updated_at",
        order_desc: true,
        excluded_ids: this.excludedIds,
        my_visits: true,
      },
      total: null,
      loadedPage: null,
    };
  },
  computed: {
    allLoaded() {
      if (this.total === null) return null;
      return this.curriculums.length >= this.total;
    },
  },
  methods: {
    async fetch(refetch = false) {
      if (!refetch) {
        if (this.loadedPage === this.query.page) return;
      } else {
        this.query.page = 1;
        this.loadedPage = null;
      }

      this.loading = true;
      try {
        const response = await this.axios.post("curriculum/query", this.query);
        this.curriculums = refetch
          ? response.data.data
          : [...this.curriculums, ...response.data.data];
        this.query = response.data.query;
        this.total = response.data.meta.total;
        this.loadedPage = this.query.page;
      } finally {
        this.loading = false;
      }
    },
    loadMore() {
      if (this.loading) return;
      this.query.page++;
      this.fetch();
    },
  },
};
</script>
