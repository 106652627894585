<template>
  <div>
    <DialogMenu
      v-model="show"
      :close-on-content-click="false"
      transition="scale-transition"
      offset-y
      :dialog-props="{ 'max-width': '290px', width: '290px', height: '100%' }"
      :menu-props="{ 'max-width': '290px', width: '290px', height: '100%' }"
    >
      <template v-slot:activator="{ on }">
        <base-btn
          class="bg-sm-white"
          v-on="on"
          v-if="['button', 'btn'].includes(activatorType)"
          v-bind="$attrs"
        >
          <slot>
            <v-icon>mdi-calendar-month-outline</v-icon>
          </slot>
        </base-btn>
        <v-text-field
          v-else
          readonly
          v-bind="$attrs"
          :value="dateForHumans"
          v-on="on"
          @click:clear="date = null"
        ></v-text-field>
      </template>
      <v-sheet class="date-input">
        <div class="d-flex justify-end">
          <base-btn icon text @click="show = false">
            <v-icon>mdi-close</v-icon>
          </base-btn>
        </div>
        <v-date-picker
          locale="et"
          :color="color"
          :header-color="color"
          :first-day-of-week="1"
          no-title
          v-model="date"
          @input="show = false"
          v-bind="pickerProps"
        ></v-date-picker>
      </v-sheet>
    </DialogMenu>
  </div>
</template>

<script>
import format from "date-fns/format";
import parseISO from "date-fns/parseISO";
import { capitalize } from "@/common/helpers/capitalize";
import DialogMenu from "./DialogMenu.vue";
export default {
  components: {
    DialogMenu,
  },
  props: {
    activatorType: {
      type: String,
      default: null,
    },
    mobileWrapperComponent: {
      type: String,
      default: "v-dialog",
    },
    desktopWrapperComponent: {
      type: String,
      default: "v-menu",
    },
    value: {
      type: String,
      default: null,
    },
    format: {
      type: String,
      default: "dd.MM.yyyy",
    },
    week: {
      type: Boolean,
      default: null,
    },
    pickerProps: {
      type: Object,
      default: null,
    },
    color: {
      type: String,
      default: "primary",
    },
  },

  data() {
    return {
      show: false,
      date: this.value ? this.value : null/* format(new Date(), "yyyy-MM-dd") */,
    };
  },

  computed: {
    dateForHumans() {
      return this.date ? capitalize(format(parseISO(this.date), this.format)) : "";
    },
  },

  watch: {
    value(value) {
      this.date = value;
    },

    date(date) {
      this.$emit("input", date);
    },
  },
};
</script>

<style lang="scss">
.date-input {
  .v-picker__actions.v-card__actions {
    padding-top: 0;
  }
}
</style>
