<style lang="scss">
</style>

<template>
  <section>
    <div class="d-md-flex align-center justify-space-between mb-5">
      <div style="width: 640px; max-width: 100%" class="order-sm-last">
        <JobsSearchField
          class="mb-3"
          :search="searchTerm"
          :location="locationTerm"
          :gentle="false"
          @searched="onSearchClick"
        />
      </div>

      <div>
        <v-chip
          close
          v-if="query.location_term"
          key="location-term"
          @click:close="onLocationTermClosed()"
          :title="`Asukoht: ` + query.location_term"
          class="ma-1"
        >
          {{ this.$capitalize(query.location_term) }}
        </v-chip>

        <v-chip
          close
          v-if="query.search_term"
          key="search-term"
          @click:close="onSearchTermClosed()"
          :title="`Otsingusõna: ` + query.search_term"
          class="ma-1"
        >
          {{ this.$capitalize(query.search_term) }}
        </v-chip>

        <h3 class="text-h5 font-weight-thin pb-2 pt-1" v-html="constraintsDescription" />
      </div>
    </div>

    <div>
      <PublicJoblistingsList :wrap-details-chips="true" :items="items" />
      <div class="text-center">
        <div v-if="searchTerm && allLoaded"></div>
        <div
          v-else
          class="pb-5"
          :title="allLoaded ? 'Rohkem tööpakkumisi ei ole' : 'Lae rohkem tööpakkumisi'"
        >
          <base-btn
            color="black"
            :dark="!allLoaded"
            :loading="loading"
            @click="fetchNextPageItems"
            :disabled="allLoaded"
          >
            <span v-if="!total">Tööpakkumisi ei leitud</span>
            <span v-else-if="allLoaded">Rohkem tööpakkumisi ei ole</span>
            <span v-else>Lae rohkem tööpakkumisi</span>
          </base-btn>

          <div
            class="pt-3 text-body-2 text--disabled"
            v-if="query.page > 1 && total - items.length > 0"
          >
            <em>
              Laadida on veel {{ total - items.length }}
              {{ total - items.length > 1 ? "tööpakkumist" : "tööpakkumine" }}
            </em>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import JobsSearchField from "../../../jobs/components/hero/JobsSearchField.vue";
import PublicJoblistingsList from "./PublicJoblistingsList.vue";
import ManagerJoblistingsList from "./ManagerJoblistingsList.vue";

export default {
  components: {
    ManagerJoblistingsList,
    PublicJoblistingsList,
    JobsSearchField,
  },
  props: {
    storeState: {
      type: Object,
      required: true,
    },
    storeAccessor: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      searchTerm: this.storeState.query.search_term,
      locationTerm: this.storeState.query.location_term,
    };
  },
  created() {
    this.fetchFirstPageItems(true);
  },
  computed: {
    constraintsDescription() {
      if (this.loading) {
        return "Palun oota..";
      } else if (!this.total) {
        return "Kriteeriumitele vastavaid tööpakkumisi ei leitud.";
      }
      const one = this.total === 1;
      let text = ``;

      text += `Leiti ${this.total} `;
      text += one ? "tööpakkumine" : "tööpakkumist";
      text += ".";

      return text;
    },
    loading() {
      return this.storeState.loading;
    },
    total() {
      return this.storeState.total;
    },
    query() {
      return this.storeState.query;
    },
    items() {
      return this.storeState.items;
    },
    allLoaded() {
      if (this.total === null) return null;
      return this.items.length >= this.total;
    },
  },

  methods: {
    onSearchClick({ searchTerm, locationTerm }) {
      this.searchTerm = searchTerm;
      this.locationTerm = locationTerm;
      this.$store.commit(`${this.storeAccessor}SET_QUERY`, {
        search_term: this.searchTerm,
        location_term: this.locationTerm,
      });
      this.fetchFirstPageItems();
    },

    queryGet(field) {
      const str = this._.get(this.query, field, "").toLowerCase();
      if (!str) return null;
      return str.split(",");
    },

    onSearchTermClosed() {
      this.$store.commit(`${this.storeAccessor}SET_QUERY`, {
        search_term: "",
      });
      this.fetchFirstPageItems();
    },

    onLocationTermClosed() {
      this.$store.commit(`${this.storeAccessor}SET_QUERY`, {
        location_term: "",
      });
      this.fetchFirstPageItems();
    },

    fetchFirstPageItems(skipIfFetched = false) {
      this.$store.dispatch(`${this.storeAccessor}fetchFirstPageItems`, skipIfFetched);
    },

    fetchNextPageItems() {
      this.$store.dispatch(`${this.storeAccessor}fetchNextPageItems`);
    },
  },
};
</script>
