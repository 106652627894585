<style lang="scss">
.accounts-input {
  .v-text-field__prefix {
    margin-top: 0 !important;
    font-size: 12px;
  }
}
</style>

<template>
  <div class="accounts-input">
    <v-scroll-y-transition group>
      <div v-for="(item, i) in items" :key="item.id">
        <v-divider class="my-5" v-show="i !== 0"></v-divider>
        <v-row>
          <v-col cols="6" md="2" class="d-flex">
            <div class="text-left">
              <v-avatar color="grey lighten-3 cursor-default" size="28" class="mr-1">
                <span class="text-subtitle-2 text--secondary">{{ i + 1 + "." }}</span>
              </v-avatar>
              <v-btn title="Nihuta alla" icon @click="moveUp(i)" v-show="i !== 0">
                <v-icon>mdi-arrow-up</v-icon>
              </v-btn>
              <v-btn title="Nihuta üles" icon @click="moveDown(i)" v-show="i < items.length - 1">
                <v-icon>mdi-arrow-down</v-icon>
              </v-btn>
            </div>
          </v-col>
          <v-col cols="6" md="1" order-md="4" class="d-flex justify-end d-md-block">
            <v-menu bottom left>
              <template v-slot:activator="{ on, attrs }">
                <v-btn title="Kustuta rida" icon v-bind="attrs" v-on="on">
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </template>

              <v-list>
                <v-list-item @click="destroyRow(item.id)">
                  <v-list-item-action>
                    <v-icon>mdi-close</v-icon>
                  </v-list-item-action>
                  <v-list-item-title>Kustuta rida nr {{ i + 1 }} ?</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </v-col>
          <v-col cols="12" md="3">
            <v-autocomplete
              v-bind="{ ...localFormProps }"
              v-model="item.key"
              item-value="key"
              item-text="title"
              label="Kanal"
              :items="Object.values(socialMedias)"
              :error-messages="error(i, 'level')"
              :error="state(i, 'level')"
            ></v-autocomplete>
          </v-col>
          <v-col cols="12" md="6">
            <v-text-field
              label="Sinu konto"
              v-bind="localFormProps"
              v-model="item.account"
              :error-messages="error(i, 'account')"
              :prefix="_.get(socialMedias, [item.key, 'prefix'])"
              :error="state(i, 'account')"
            ></v-text-field>
          </v-col>
        </v-row>
      </div>
    </v-scroll-y-transition>
    <div class="d-flex justify-start mt-5">
      <base-btn small @click="addItem()" outlined>
        <v-icon left>mdi-plus</v-icon>
        <span v-text="addLabel"></span>
      </base-btn>
    </div>
  </div>
</template>

<script>
import LanguageSelector from "./LanguageSelector.vue";
import { reorderArray } from "@/common/helpers/reorderArray";
import { randomString } from "@/common/helpers/randomString";
import socialMedias from "@/blocks/jobs/socialMedias";
const emptyItem = {
  id: randomString(10),
  key: null,
  account: null,
};

function getItems(items) {
  return items.map((item) => {
    return {
      id: item.id ? item.id : randomString(10),
      ...item,
    };
  });
}

export default {
  components: {
    LanguageSelector,
  },
  props: {
    addLabel: {
      type: String,
      default: "Lisa konto",
    },
    formProps: {
      type: Object,
      default: {},
    },
    value: {
      type: Array,
      default: () => [],
    },
    formObject: {
      type: Object,
      default: null,
    },
    name: {
      type: String,
      default: null,
    },
  },
  computed: {
    localFormProps() {
      return { ...this.formProps, dense: true };
    },
  },
  data() {
    return {
      items: getItems(this.value),
      socialMedias,
    };
  },
  watch: {
    items: {
      handler: function (items) {
        this.$emit("input", items);
      },
      deep: true,
    },
  },
  methods: {
    addItem() {
      this.items.push({ ...emptyItem, id: randomString(10) });
    },
    moveUp(index) {
      if (index <= 0) return;
      reorderArray(this.items, index, index - 1);
    },
    moveDown(index) {
      if (index >= this.items.length - 1) return;
      reorderArray(this.items, index, index + 1);
    },
    destroyRow(id) {
      this.items = this.items.filter((el) => el.id !== id);
    },
    onLanguageChange(i, evt) {
      this.items[i].flag = evt.flag;
      this.items[i].language = evt.key;
      this.items = this._.cloneDeep(this.items);
    },
    error(i, field) {
      if (!this.formObject) return null;
      return this.formObject.error(this.name + "." + i + "." + field);
    },
    state(i, field) {
      if (!this.formObject) return null;
      return this.formObject.state(this.name + "." + i + "." + field);
    },
  },
};
</script>