<template>
  <section>
    <div>
      <h3 class="text-h5 font-weight-thin pb-2 pt-1 pb-5" v-html="constraintsDescription" />
    </div>

    <div>
      <MyJobapplicationsList :items="items" />

      <div class="text-center">
        <div
          class="pb-5"
          :title="allLoaded ? 'Rohkem kandideerimisi ei ole' : 'Lae rohkem kandideerimisi'"
        >
          <base-btn
            color="black"
            :dark="!allLoaded"
            :loading="loading"
            @click="fetchNextPageItems"
            :disabled="allLoaded"
          >
            <span v-if="!total">Kandideerimisi ei leitud</span>
            <span v-else-if="allLoaded">Rohkem kandideerimisi ei ole</span>
            <span v-else>Lae rohkem kandideerimisi</span>
          </base-btn>

          <div
            class="pt-3 text-body-2 text--disabled"
            v-if="query.page > 1 && total - items.length > 0"
          >
            <em>
              Laadida on veel {{ total - items.length }}
              {{ total - items.length > 1 ? "kandideerimist" : "kandideerimine" }}
            </em>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import MyJobapplicationsList from "./MyJobapplicationsList.vue";

export default {
  components: {
    MyJobapplicationsList,
  },
  created() {
    this.fetchFirstPageItems(true);
  },
  computed: {
    storeState() {
      return this.$store.state.jobapplication;
    },
    storeAccessor() {
      return "jobapplication/";
    },
    constraintsDescription() {
      if (this.loading) {
        return "Palun oota..";
      } else if (!this.total) {
        return "Kandideerimisi ei leitud.";
      }
      const one = this.total === 1;
      let text = ``;
      if (this.query.search_term) {
        text += `märksõnale <strong>${this.query.search_term}</strong> `;
      }

      text += `leiti ${this.total} `;
      text += one ? "kandideerimine" : " kandideerimist";

      return this.$capitalize(text);
    },
    loading() {
      return this.storeState.loading;
    },
    total() {
      return this.storeState.total;
    },
    query() {
      return this.storeState.query;
    },
    items() {
      return this.storeState.items;
    },
    allLoaded() {
      if (this.total === null) return null;
      return this.items.length >= this.total;
    },
  },
  methods: {
    queryGet(field) {
      const str = this._.get(this.query, field, "").toLowerCase();
      if (!str) return null;
      return str.split(",");
    },

    fetchFirstPageItems(skipIfFetched = false) {
      this.$store.dispatch(`${this.storeAccessor}fetchFirstPageItems`, skipIfFetched);
    },

    fetchNextPageItems() {
      this.$store.dispatch(`${this.storeAccessor}fetchNextPageItems`);
    },
  },
};
</script>
