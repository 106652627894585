<template>
  <v-container class="h-100">
    <v-row no-gutters class="d-flex justify-center mb-7">
      <v-col cols="12" sm="10" md="9" lg="8">
        <CardTemplate class="mb-5" v-for="(group, i) in fieldGroups" :key="i + 'group'">
          <template #header>
            <h2 class="text-h4">{{ group.label }}</h2>
          </template>

          <div
            v-if="group.hint"
            v-html="group.hint"
            class="font-weight-thin text-body-2 secondary--text mb-3"
          />
          <v-row class="my-5">
            <v-col
              v-bind="{ cols: '12', md: '6', ...field.colProps }"
              v-for="(field, i) in group.items"
              :key="i + 'field'"
            >
              <component
                :is="field.component ? field.component : 'VTextField'"
                v-bind="field.props ? field.props : formProps"
                :name="field.slug"
                v-model="form[field.slug]"
                :label="field.label"
                :hint="field.hint"
                :error-messages="form.error(field.slug)"
                :error="form.state(field.slug)"
                :form-object="form"
              />
            </v-col>
          </v-row>
        </CardTemplate>

        <!-- <base-btn name="submit" color="black" dark large :loading="storeLoading" @click="store()">
          <span>Salvesta CV</span>
        </base-btn> -->
      </v-col>

      <v-col cols="12" sm="2" md="3" lg="3" class="min-h-100">
        <base-card elevation="0" class="ml-5 pt-3">
          <v-card-text>
            <div class="">
              <div class="text-h6">CV staatus:</div>
              <v-switch
                class="mt-1"
                v-model="form.is_published"
                :label="form.is_published ? 'Avaldatud' : 'Mitteavalik'"
              ></v-switch>

              <v-alert tile v-if="!form.is_published" color="warning" dark>
                <div class="text-body-2">
                  Tööpakkumistele kandideerimine on võimalik vaid täidetud ja avaldatud CV-ga!
                  <br />
                  <base-btn
                    class="mt-1"
                    @click="
                      form.is_published = true;
                      store();
                    "
                    color="white"
                    light
                  >
                    Avalikusta CV
                  </base-btn>
                </div>
              </v-alert>

              <v-radio-group v-model="form.is_private">
                <template v-slot:label>
                  <div :class="!form.is_published ? `text--disabled` : ``">
                    Avaldatud CV nägemise õigus
                  </div>
                </template>

                <v-radio :value="false" :color="form.is_published ? 'primary' : 'grey'">
                  <template v-slot:label>
                    <small :class="!form.is_published ? `text--disabled` : ``">
                      Kõik ehitussektori ettevõtted näevad su CV-d ja saavad sinuga töö pakkumiseks
                      ühendust võtta
                    </small>
                  </template>
                </v-radio>
                <v-divider class="mb-2"></v-divider>
                <v-radio :value="true" :color="form.is_published ? 'primary' : 'grey'">
                  <template v-slot:label>
                    <small :class="!form.is_published ? `text--disabled` : ``">
                      CV-d kuvatakse vaid nendele ettevõtetele, kelle tööpakkumisele oled
                      kandideerinud
                    </small>
                  </template>
                </v-radio>
              </v-radio-group>
            </div>
          </v-card-text>
        </base-card>

        <div
          class="ml-5 mt-2 pt-3"
          :style="saveButtonStyle ? saveButtonStyle : 'position: sticky; top: 0'"
        >
          <base-btn
            name="submit"
            color="black"
            dark
            large
            block
            :loading="storeLoading"
            @click="store()"
          >
            <span>Salvesta CV</span>
          </base-btn>
          <v-alert tile dense v-if="successMessage" type="success" class="mt-3">
            <small v-html="successMessage" />
          </v-alert>
          <v-alert tile dense v-if="form.errors.message" type="error" class="mt-3">
            <small v-html="form.errors.message" />
            <!-- <div>
              <ul v-for="(group, ulKey) in form.errors.errors" :key="ulKey + 'ul'">
                <li v-for="(errorSentence, liKey) in group" :key="liKey + 'ul'">
                  <span v-html="form.errors.capitalize(errorSentence)" />
                </li>
              </ul>
            </div> -->
          </v-alert>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import CardTemplate from "@/blocks/page/components/templates/CardTemplate.vue";
import ImageUpload from "@/blocks/jobs/curriculum/components/save/ImageUpload.vue";
import AccountsInput from "@/blocks/jobs/curriculum/components/save/AccountsInput.vue";
import MultiImageUpload from "@/blocks/jobs/curriculum/components/save/MultiImageUpload.vue";
import SkillsInput from "@/blocks/jobs/curriculum/components/save/SkillsInput.vue";
import LanguagesInput from "@/blocks/jobs/curriculum/components/save/LanguagesInput.vue";
import CountrySelector from "@/blocks/jobs/curriculum/components/save/CountrySelector.vue";
import TimelineItemInput from "@/blocks/jobs/curriculum/components/save/TimelineItemInput.vue";
import Form from "@/common/form";
import emptyCurriculum from "@/blocks/jobs/curriculum/emptyCurriculum";
import { VTextField } from "vuetify/lib";
import { VTextarea } from "vuetify/lib";

const formProps = { hideDetails: "auto", persistentHint: true, filled: true, outlined: true };

export default {
  components: {
    VTextField,
    VTextarea,
    TimelineItemInput,
    CountrySelector,
    LanguagesInput,
    SkillsInput,
    ImageUpload,
    AccountsInput,
    MultiImageUpload,
    CardTemplate,
  },

  props: {
    curriculum: {
      type: Object,
      required: true,
    },
    userId: {
      type: [String, Number],
      required: true,
    },
    saveButtonStyle: {
      type: String,
      default: null,
    },
  },

  data() {
    return {
      formProps,
      emptyCurriculum,
      form: new Form(this.curriculum),
      errorMessage: null,
      successMessage: null,
      storeLoading: null,
      publishLoading: null,
    };
  },

  computed: {
    path() {
      return `/user/${this.userId}/curriculum-form`;
    },
    fieldGroups() {
      return [
        {
          label: "Üldandmed",
          items: [
            {
              slug: "firstname",
              label: "Eesnimi",
            },
            {
              slug: "lastname",
              label: "Perekonnanimi",
            },
            {
              slug: "email",
              label: "E-posti aadress",
            },
            {
              slug: "tel",
              label: "Telefoninumber",
            },
            {
              slug: "address_street",
              label: "Tänav ja maja nr",
            },
            {
              slug: "address_city",
              label: "Linn/asula",
            },
            {
              slug: "address_country",
              label: "Riik",
              component: "CountrySelector",
            },
          ],
        },
        {
          label: "Profiilipilt",
          hint: `<p>• CV-s olev foto tekitab suuremat usaldust ning teenid tööandja poolt kindlasti ka mõned plusspunktid juba ainuüksi pildi lisamise eest. Lisaks paistab Sinu CV ilma pildita cv-dest rohkem silma.</p>
          <p>• Selfied jäta sotsiaalmeediasse. Ükskõik, kui ilusa pildi sa endast ka ei suudaks ise teha, palu esinduslikuma foto saamiseks siiski mõne sõbra abi.</p>`,
          items: [
            {
              slug: "profile_image_src",
              label: "",
              component: "ImageUpload",
              colProps: { md: "12" },
              props: {
                path: this.path + "/profile-image",
                imageName: "profiilipilt",
                onImageUploaded: (data, field) => {
                  this.$store.commit("auth/SET", {
                    user: { ...this.$store.state.auth.user, curriculum: data.item },
                  });
                  this.form.profile_image_src = data.item.profile_image_src;
                },
                onImageDestroyed: (data) => {
                  this.$store.commit("auth/SET", {
                    user: { ...this.$store.state.auth.user, curriculum: data.item },
                  });
                  this.form.profile_image_src = data.item.profile_image_src;
                },
              },
            },
          ],
        },
        {
          label: "Sinu amet",
          items: [
            {
              slug: "job_title",
              label: "Ametinimetus",
              hint: "Näiteks: maaler, objektijuht",
            },
            {
              slug: "wage_request",
              label: "Palgasoov",
              hint: `Sisesta vabas vormis või jäta tühjaks. Näiteks: "1500 €, bruto".`,
            },
          ],
        },
        {
          label: "Sinu kirjeldus",
          hint: "Kirjuta siia kõige olulisem info enda kohta, mida potentsiaalne tööandja peaks sinu kohta teadma. Näiteks: Kaua oled ehitussektoris töötanud? Millist tööd otsid? Miks valida just Sind?",
          items: [
            {
              slug: "description",
              label: "Kirjelda ennast",
              component: "VTextarea",
              colProps: { md: "12" },
            },
          ],
        },
        {
          label: "Töökogemus",
          hint: "Sisesta info enda töökogemuse kohta.",
          items: [
            {
              slug: "experience",
              component: "TimelineItemInput",
              colProps: { md: "12" },
              props: {
                formProps,
                addLabel: "Lisa töökogemuse rida",
                titleProps: { label: "Positsioon ja/või ettevõtte nimi" },
                descriptionProps: { label: "Krjelda enda saavutusi ja tulemusi" },
              },
            },
          ],
        },
        {
          label: "Haridus",
          hint: "Sisesta info enda hariduse kohta.",
          items: [
            {
              slug: "education",
              component: "TimelineItemInput",
              colProps: { md: "12" },
              props: {
                addLabel: "Lisa hariduse rida",
                formProps,
                titleProps: { label: "Kooli nimi" },
              },
            },
          ],
        },
        {
          label: "Koolitused ja sertifikaadid",
          hint: "Sisesta kõik koolitused, milles oled osalenud ning sertifikaadid, mida omad.",
          items: [
            {
              slug: "certificates",
              component: "TimelineItemInput",
              colProps: { md: "12" },
              props: {
                addLabel: "Lisa koolitus/sertifikaat",
                formProps,
                disabledFields: ["to"],
                titleProps: { label: "Sertifikaadi/koolituse pealkiri" },
              },
            },
          ],
        },
        {
          label: "Keeleoskus",
          hint: "Vali kõik keeled, mida valdad.",
          items: [
            {
              slug: "languages",
              component: "LanguagesInput",
              colProps: { md: "12" },
              props: {
                addLabel: "Lisa keel",
                formProps,
              },
            },
          ],
        },
        {
          label: "Sinu isikuomadused",
          hint: "Vali välja vähemalt kolm isikuomadust ning hinda ennast nendes.",
          items: [
            {
              slug: "skills",
              component: "SkillsInput",
              colProps: { md: "12" },
              props: {
                formProps,
              },
            },
          ],
        },
        {
          label: "Referentsid",
          hint: `Lae üles fotod sinu osalusel valminud objektidest.`,
          items: [
            {
              slug: "references",
              label: "",
              component: "MultiImageUpload",
              colProps: { md: "12" },
              props: {
                formProps,
                entryId: this.curriculum.id,
                entryType: "App\\Models\\Curriculum",
              },
            },
          ],
        },
        {
          label: "Sinu kontod suhtlusrakendustes",
          hint: `Sisesta kontod, mille vahendusel soovid, et potentsiaalne tööandja saaks sinuga ühendust võtta.`,
          items: [
            {
              slug: "accounts",
              label: "",
              component: "AccountsInput",
              colProps: { md: "12" },
              props: {
                formProps,
              },
            },
          ],
        },
      ];
    },
  },

  methods: {
    store() {
      this.storeLoading = true;
      this.successMessage = null;
      this.form
        .post(this.path)
        .then((response) => {
          this.$emit("updated", response.data.item);
          this.$store.commit("auth/SET", {
            user: { ...this.$store.state.auth.user, curriculum: response.data.item },
          });
          if (response.data.message) {
            this.successMessage = response.data.message;

            setTimeout(() => {
              this.successMessage = null;
            }, 10000);
          }
        })
        .catch((error) => {
          this.errorMessage = this._.get(error, ["response", "data", "message"], error.message);
          this.loading = false;
        })
        .finally(() => {
          setTimeout(() => {
            this.storeLoading = false;
          }, 500);
        });
    },
  },
};
</script>
