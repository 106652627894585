<style lang="scss" >
.vue-image-crop-upload {
  .vicp-crop {
    display: flex;
    justify-content: center;
  }
  .vicp-crop-right {
    display: none;
  }
  .vicp-wrap {
    max-width: 450px !important;
  }
}
</style>
<template>
  <div>
    <v-alert tile type="error" text dense v-if="formObject.state(name) === false">
      {{ formObject.error(name) }}
    </v-alert>
    <div v-if="currentImage">
      <div class="d-md-flex justify-space-between">
        <div>
          <v-img
            class="elevation-2"
            :aspect-ratio="thumbWidth / thumbHeight"
            :max-width="thumbWidth"
            :max-height="thumbHeight"
            width="300px"
            :src="currentImage"
          />
          <base-btn outlined class="mt-3" @click="toggleShow">
            <v-icon left>mdi-plus</v-icon>
            <span>Asenda {{ imageName }}</span>
          </base-btn>
        </div>

        <base-btn outlined small text class="mt-3" @click="destroyImage">
          <v-icon left>mdi-close</v-icon>
          Kustuta pilt
        </base-btn>
      </div>
    </div>

    <base-btn v-else outlined @click="toggleShow">
      <v-icon left>mdi-plus</v-icon>
      <span>Sisesta {{ imageName }}</span>
    </base-btn>

    <img-upload
      noCircle
      noSquare
      field="img"
      :langExt="language"
      @crop-success="cropSuccess"
      @crop-upload-success="cropUploadSuccess"
      @crop-upload-fail="cropUploadFail"
      v-model="show"
      :width="width"
      :height="height"
      :url="'/api' + path"
      :params="params"
      :headers="headers"
      img-format="png"
    ></img-upload>
  </div>
</template>

<script>
import imgUpload from "vue-image-crop-upload/upload-2.vue";

const language = {
  hint: "Üleslaadimiseks klõpsake või lohistage foto siia",
  loading: "Palun oota…",
  noSupported: "Brauserit ei toetata, palun kasutage IE10+ või muid brausereid",
  success: "Üleslaadimine õnnestus",
  fail: "Üleslaadimine ebaõnnestus",
  preview: "Eelvaade",
  btn: {
    off: "Tühista",
    close: "Sule",
    back: "Tagasi",
    save: "Salvesta",
  },
  error: {
    onlyImg: "Ainult pilt",
    outOfSize: "Pilt ületab suurusepiirangu: ",
    lowestPx:
      "Pildi suurus on liiga väike. Palun kasuta pilti, mille suurus pikslites on vähemalt: ",
  },
};
export default {
  props: {
    width: {
      type: Number,
      default: 400,
    },
    height: {
      type: Number,
      default: (400 * 4) / 3,
    },
    thumbnailWidth: {
      type: Number,
      default: null,
    },
    thumbnailHeight: {
      type: Number,
      default: null,
    },
    value: {
      type: String,
      default: null,
    },
    formObject: {
      type: Object,
      required: true,
    },
    path: {
      type: String,
      required: true,
    },
    imageName: {
      type: String,
      default: "profiilipilt",
    },
    onImageUploaded: {
      type: Function,
      default: (data, field) => {},
    },
    onImageDestroyed: {
      type: Function,
      default: (data) => {},
    },

    // slug
    name: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      language,
      show: false,
      params: {},
      headers: {
        "X-CSRF-TOKEN": this.$store.state.csrf,
      },
      thumbWidth: this.thumbnailWidth ? this.thumbnailWidth : this.width,
      thumbHeight: this.thumbnailHeight ? this.thumbnailHeight : this.height,
      currentImage: this.value,
      destroying: false,
    };
  },
  components: {
    imgUpload,
  },
  methods: {
    toggleShow() {
      this.show = !this.show;
    },
    /**
     * crop success
     *
     * [param] imgDataUrl
     * [param] field
     */
    cropSuccess(imgDataUrl, field) {
      //
    },

    /**
     * upload success
     *
     * [param] jsonData  server api return data, already json encoded
     * [param] field
     */
    cropUploadSuccess(data, field) {
      this.currentImage = data.item[this.name];
      this.onImageUploaded(data, field);
    },

    /**
     * upload fail
     *
     * [param] status    server api return error status, like 500
     * [param] field
     */
    cropUploadFail(status, field) {
      //
    },

    async destroyImage() {
      let response = await this.$dialog.confirm({
        title: "Pildi kustutamine",
        text: "Kas oled kindel, et soovid pildi kustutada?",
        actions: {
          false: "Ei, muutsin meelt",
          true: {
            text: "Jah",
            color: "primary",
          },
        },
      });
      if (response) {
        this.destroying = true;
        try {
          const response = await this.axios.delete(this.path);
          this.currentImage = null;
          this.$notify(response.data.message, "success", {
            color: "#071b24",
            icon: "mdi-close",
          });
          this.onImageDestroyed(response.data);
        } catch (error) {
          this.$catchApiErrors(error);
        } finally {
          this.destroying = false;
        }
      }
    },
  },
};
</script>