<style lang="scss">
@import "@/dependencies.scss";
.curriculum-languages {
  @media only screen and (max-width: 400px) {
  }
  @include media-breakpoint-only(xs) {
    .row {
      max-width: 250px !important;
    }
  }
}
</style>

<template>
  <div>
    <div v-if="$vuetify.breakpoint.xs">
      <v-row>
        <v-col cols="4" v-if="curriculum.profile_image_src">
          <div style="property: value">
            <v-img
              max-width="300px"
              class="mt-3 elevation-5"
              :src="curriculum.profile_image_src"
              :alt="curriculum.profile_image_alt"
            ></v-img>
          </div>
        </v-col>
        <v-col cols="8" class="d-flex align-center">
          <h1 class="text-h2 text-uppercase font-weight-black" style="line-height: 1">
            <div>{{ curriculum.firstname }}</div>
            <div class="red--text text--darken-1 darken-3">{{ curriculum.lastname }}</div>
          </h1>
        </v-col>
      </v-row>
      <h2 class="text-uppercase text-subtitle-1 left" v-if="curriculum.job_title">
        <small>{{ curriculum.job_title }}</small>
      </h2>
    </div>

    <div v-else>
      <h1 class="text-h1 text-uppercase font-weight-black" style="line-height: 1">
        <div>{{ curriculum.firstname }}</div>
        <div class="red--text text--darken-1 darken-3">{{ curriculum.lastname }}</div>
      </h1>

      <div style="max-width: 400px" v-if="curriculum.profile_image_src">
        <v-img
          :max-width="'400px'"
          class="mt-3 elevation-5"
          :src="curriculum.profile_image_src"
          :alt="curriculum.profile_image_alt"
        ></v-img>
        <div>
          <h2 class="text-uppercase text-subtitle-1 text-center mt-2" v-if="curriculum.job_title">
            {{ curriculum.job_title }}
          </h2>
        </div>
      </div>
      <h2 v-else-if="curriculum.job_title" class="text-uppercase text-subtitle-1 mt-2">
        {{ curriculum.job_title }}
      </h2>
    </div>
    <HtmlContent class="mt-3" :content="curriculum.description" />

    <v-list two-line>
      <!-- EMAIL -->
      <v-list-item>
        <v-list-item-action>
          <v-avatar class="elevation-10" :class="color">
            <v-icon dark>mdi-email</v-icon>
          </v-avatar>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title class="mb-1">Email</v-list-item-title>
          <v-list-item-subtitle v-html="curriculum.email"></v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>

      <!-- ADDRESS -->
      <v-list-item>
        <v-list-item-action>
          <v-avatar class="elevation-10" :class="color">
            <v-icon dark>mdi-map-marker</v-icon>
          </v-avatar>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title class="mb-1">Aadress</v-list-item-title>
          <v-list-item-subtitle v-html="curriculum.address"></v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>

      <!-- PHONE -->
      <v-list-item>
        <v-list-item-action>
          <v-avatar class="elevation-10" :class="color">
            <v-icon dark>mdi-phone</v-icon>
          </v-avatar>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title class="mb-1">Telefon</v-list-item-title>
          <v-list-item-subtitle v-html="curriculum.tel"></v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>

      <!-- WAGE REQUEST -->
      <v-list-item v-if="curriculum.wage_request">
        <v-list-item-action>
          <v-avatar class="elevation-10" :class="color">
            <v-icon dark>mdi-currency-eur</v-icon>
          </v-avatar>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title class="mb-1">Palgasoov</v-list-item-title>
          <v-list-item-subtitle v-html="curriculum.wage_request"></v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>

      <!-- SOCIAL MEDIA ACCOUNTS -->
      <v-list-item v-for="item in curriculum.accounts" :key="item.id">
        <v-list-item-action>
          <SocialMediaIcon :value="item.key" :color="color" />
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title class="mb-1">
            {{ _.get(socialMedias, [item.key, "title"]) }}
          </v-list-item-title>
          <v-list-item-subtitle>
            <a
              :href="`https://` + _.get(socialMedias, [item.key, 'prefix']) + item.account"
              v-if="_.get(socialMedias, [item.key, 'href'], false)"
              target="_blank"
            >
              <small
                class="text--disabled"
                v-text="_.get(socialMedias, [item.key, 'prefix'], '')"
              />
              <span>{{ item.account }}</span>
            </a>
            <div v-else>
              <small
                class="text--disabled"
                v-text="_.get(socialMedias, [item.key, 'prefix'], '')"
              />
              <span>{{ item.account }}</span>
            </div>
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
    </v-list>

  </div>
</template>

<script>
import SocialMediaIcon from "./SocialMediaIcon.vue";
import HtmlContent from "@/blocks/global/components/HtmlContent.vue";
import socialMedias from "@/blocks/jobs/socialMedias";

export default {
  components: {
    HtmlContent,
    SocialMediaIcon,
  },
  props: {
    curriculum: {
      type: Object,
      required: true,
    },
    color: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      socialMedias,
    };
  },
};
</script>