<style lang="scss">
</style>

<template>
  <v-container>
    <v-row justify="space-around">
      <v-col v-for="(item, i) in items" :key="i" class="text-center" cols="12" sm="4" md="4">
        <router-link
          :to="item.to"
          :is="item.to ? 'router-link' : 'a'"
          @click="item.onClick ? item.onClick() : undefined"
          class="link-secondary image-gradient__wrap"
          :class="{
            'link__active image-gradient__active': activeitemIds.includes(item.id),
          }"
          @click.prevent="$emit('input', item)"
        >
          <v-avatar
            :size="$vuetify.breakpoint.xl ? 140 : $vuetify.breakpoint.lg ? 130 : 120"
            class="elevation-gentle"
          >
            <v-img :aspect-ratio="1" :src="item.thumbnail">
              <div class="image-gradient" />
            </v-img>
          </v-avatar>
          <div class="mt-2 text-h6 text-center" v-html="item.title"></div>
        </router-link>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  props: {
    items: {
      type: Array,
      requireds: true,
    },
    activeitemIds: {
      type: Array,
      default: () => [],
    },
  },
};
</script>
