<template>
  <DefaultTemplate>
    <template #title>
      <div class="d-sm-flex justify-space-between">
        <div>{{ business.title }} | tööpakkumised</div>
        <div>
          <base-btn
            small
            dark
            :to="{ name: 'CreateJoblisting', params: { business_slug: business.slug } }"
          >
            <v-icon class="left">mdi-account-multiple-plus-outline</v-icon>
            Lisa tööpakkumine
          </base-btn>
        </div>
      </div>
    </template>
    <ManagerJoblistingSearchResults @unauthorized="$emit('unauthorized')" :business="business" />
  </DefaultTemplate>
</template>
    
<script >
import ManagerJoblistingSearchResults from "./ManagerJoblistingSearchResults.vue";
import DefaultTemplate from "@/blocks/page/components/templates/DefaultTemplate.vue";
import ManagerJoblistingsList from "@/blocks/jobs/joblisting/components/show/ManagerJoblistingsList.vue";
import fetchBusinessMixin from "@/blocks/business/fetchBusinessMixin";

export default {
  mixins: [fetchBusinessMixin],

  components: {
    ManagerJoblistingsList,
    DefaultTemplate,
    ManagerJoblistingSearchResults,
  },
};
</script>