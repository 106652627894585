<style lang="scss">
// @import "@/dependencies.scss";
</style>

<template>
  <v-row>
    <v-col cols="12" sm="6" md="4" lg="3" v-for="curriculum in curriculums" :key="curriculum.id">
      <CurriculumGridItem :item="curriculum"></CurriculumGridItem>
    </v-col>
  </v-row>
</template>

<script>
import CurriculumGridItem from "./CurriculumGridItem.vue";
export default {
  components: {
    CurriculumGridItem,
  },

  props: {
    curriculums: {
      type: Array,
      required: true,
    },
  },
  
};
</script>