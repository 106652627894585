<template>
  <div class="curriculums-banner-search">
    <div class="position-relative">
      <form class="" style="top: 0; left: 0; z-index: 50">
        <v-sheet
          class="search-sheet d-flex align-center"
          outlined
          light
          :class="{ 'elevation-gentle': gentle }"
          :style="gentle ? undefined : 'border: 1px solid rgba(0, 0, 0, 0.3);'"
        >
          <CurriculumSearchField
            ref="searchField"
            v-model="searchTerm"
            :disable-instant-search="disableInstantSearch"
          />

          <base-btn
            type="submit"
            @click.prevent="search()"
            color="accent"
            elevation="0"
            class="mr-2"
            :loading="searching"
          >
            <span class="d-none d-sm-block mr-2">Otsi</span>
            <v-icon>mdi-magnify</v-icon>
          </base-btn>
        </v-sheet>
      </form>
    </div>
  </div>
</template>

<script>
import CurriculumSearchField from "./CurriculumSearchField.vue";
export default {
  components: {
    CurriculumSearchField,
  },
  props: {
    light: {
      type: Boolean,
      default: false,
    },
    gentle: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      populars: ["elektri", "siseviimistleja", "aiatööd"],
      searchTerm: "",
      searching: null,
      disableInstantSearch: false,
    };
  },
  computed: {
    // populars() {
    //   return this.$store.state.curriculum.popularSearchTerms;
    // },
  },
  methods: {
    onSearchPopular(popular) {
      this.searchTerm = "";
      this.$refs.searchField.disableInstantSearch();
      for (let i = 0; i <= popular.length; i++) {
        setTimeout(() => {
          if (i === popular.length) {
            this.search();
            this.$refs.searchField.enableInstantSearch();
            return;
          }
          this.searchTerm += popular[i];
        }, i * 15);
      }
    },

    search() {
      this.searching = true;
      this.$emit("searched", this.searchTerm);
      setTimeout(() => {
        this.searching = false;
      }, 1000);
    },
  },
};
</script>
