<template>
  <div>
    <v-dialog
      v-if="isDialogComputed"
      @input="$emit('input', $event)"
      v-bind="{ ...$attrs, ...dialogProps }"
    >
      <template v-slot:activator="{ on }">
        <slot v-bind:on="on" name="activator">
          <base-btn icon v-on="on">
            <v-icon>mdi-dots-vertical</v-icon>
          </base-btn>
        </slot>
      </template>
      <slot></slot>
    </v-dialog>

    <v-menu v-else @input="$emit('input', $event)" v-bind="{ ...$attrs, ...menuProps }">
      <template v-slot:activator="{ on }">
        <slot v-bind:on="on" name="activator">
          <base-btn icon v-on="on">
            <v-icon>mdi-dots-vertical</v-icon>
          </base-btn>
        </slot>
      </template>
      <slot></slot>
    </v-menu>
  </div>
</template>

<script>
export default {
  props: {
    isDialog: {
      type: Boolean,
      default: null,
    },
    menuProps: {
      type: Object,
      default: () => {},
    },
    dialogProps: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    isDialogComputed() {
      if (this.isDialog === null) return this.$vuetify.breakpoint.mobile;
      return this.isDialog;
    },
  },
};
</script>
