<template>
  <div>
    <div class="d-sm-flex justify-space-between align-end mb-3">
      <div class="text-h6">Tööpakkumine</div>
      <JoblistingAdminPanel
        :showViewApplicationsLink="false"
        @updated="$emit('updated', $event)"
        @destroyed="
          $router.push({ name: 'BusinessJoblistings', params: { business_slug: business.slug } })
        "
        :business="business"
        :item="joblisting"
      />
    </div>

    <JoblistingListItem
      :show-deadline="true"
      :show-excerpt="false"
      :joblisting="joblisting"
      :on-link-click="onJoblistingItemClick"
    >
      <template #footer>
        <FullscreenDialog
          ref="joblistingDialog"
          route-key="joblisting"
          toolbar-title="Tööpakkumise andmed"
          btn-title="Tööpakkumise andmed"
          close-title="Sulge"
          :btn-props="{ outlined: true }"
        >
          <JoblistingDetails
            :business="business"
            :joblisting="joblisting"
            show-business-title
            show-image
            :show-share-buttons="false"
          >
            <template #apply-button>
              <div><!-- Do not delete empty div --></div>
            </template>
          </JoblistingDetails>
        </FullscreenDialog>
      </template>
    </JoblistingListItem>

    <div class="text-h6 mt-10">Kandidaadid</div>
    <v-divider class="my-3"></v-divider>

    <JobpplicationsTable
      v-if="jobapplications.length > 0"
      :business="business"
      :joblisting="joblisting"
      :jobapplications="jobapplications"
    />
    <div v-else class="text-body-2 text--secondary">Keegi ei ole veel kandideerinud.</div>
  </div>
</template>


<script>
import JoblistingAdminPanel from "../show/joblisting-item/JoblistingAdminPanel.vue";
import JobpplicationsTable from "./JobpplicationsTable.vue";
import JoblistingListItem from "../show/joblisting-item/JoblistingListItem.vue";
import JoblistingDetails from "../show/JoblistingDetails.vue";
import FullscreenDialog from "../../../jobapplication/components/save/FullscreenDialog.vue";

export default {
  components: {
    FullscreenDialog,
    JoblistingDetails,
    JoblistingListItem,
    JobpplicationsTable,
    JoblistingAdminPanel,
  },

  props: {
    business: {
      type: Object,
      required: true,
    },
    joblisting: {
      type: Object,
      required: true,
    },
    jobapplications: {
      type: Array,
      required: true,
    },
  },

  created() {
    this.cleanupRouteQuery();
  },

  data() {
    return {
      step: 2,
      joblistingDialog: false,
    };
  },

  computed: {
    curriculum() {
      return this.$store.state.auth.user.curriculum;
    },
    hasQuestions() {
      return this.joblisting.questions.length;
    },
    hasSkills() {
      return this.joblisting.skills.length;
    },
  },

  methods: {
    cleanupRouteQuery() {
      if (!this._.has(this.$route.query, "dialog")) return;
      this.$router.replace({
        name: this.$route.name,
        params: this.$route.params,
        query: {},
      });
    },
    onJoblistingItemClick() {
      this.$refs.joblistingDialog.showDialog();
    },
  },
};
</script>
