<style lang="scss">
// @import "@/dependencies.scss";
</style>

<template>
  <WideTemplate>
    <template #title>Ehitussektori tööpakkumised</template>
    <JoblistingSearchResults :store-state="$store.state.joblisting" store-accessor="joblisting/" />
  </WideTemplate>
</template>

<script>
import WideTemplate from "../../../page/components/templates/WideTemplate.vue";
import JoblistingSearchResults from "../components/show/JoblistingSearchResults.vue";
import PublicCurriculums from "../../curriculum/components/index/PublicCurriculums.vue";

export default {
  components: {
    PublicCurriculums,
    JoblistingSearchResults,
    WideTemplate,
  },

  head() {
    return this.$store.getters.content("joblistings.head");
  },
};
</script>