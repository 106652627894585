
<template>
  <v-autocomplete
    v-model="selected"
    :filter="filter"
    @change="$emit('change', selectedItem)"
    @input="emitInput"
    v-on="$listeners"
    :items="items"
    v-bind="{ outlined: true, ...$attrs }"
    :item-value="keyField"
    :dense="dense"
    @click="autoFetch ? (search = true) : null"
  >
    <template #label v-if="label">
      <span v-text="`Riik`" />
    </template>
    <template #item="slotProps">
      <CountryFlag :size="dense ? 24 : 32" :country="slotProps.item.flag" left />
      {{ slotProps.item.name }}
      <span class="ml-2" v-if="slotProps.item.name !== slotProps.item.native_name">
        ({{ slotProps.item.native_name }})
      </span>
    </template>
    <template #append-item>
      <v-list-item v-if="loading">
        <v-list-item-content class="d-flex">
          <v-progress-circular indeterminate color="info"></v-progress-circular>
        </v-list-item-content>
      </v-list-item>
      <v-list-item data-cy="load-more-countries" v-else-if="!fetched" @click="search = true">
        <v-list-item-content>
          <v-list-item-title>
            <em>
              {{ "Näita rohkem riike" }}
            </em>
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </template>
    <template #selection="slotProps">
      <CountryFlag
        :size="dense ? 24 : 32"
        :country="slotProps.item.flag.replace('.svg', '')"
        left
      />
      {{ slotProps.item.name }}
      <span span class="ml-2" v-if="slotProps.item.name !== slotProps.item.native_name">
        ({{ slotProps.item.native_name }})
      </span>
    </template>
  </v-autocomplete>
</template>

<script>
import CountryFlag from "@/blocks/jobs/curriculum/components/show/CountryFlag.vue";

export default {
  components: {
    CountryFlag,
  },
  props: {
    value: {
      type: String,
      default: null,
    },
    /**
     * If options are passed in from "defaultOptions" prop,
     * then this component will have "load more" button that fetches all options.
     */
    defaultOptions: {
      type: Array,
      default: () => [
        { name: "Eesti", code: "EE", native_name: "Eesti", calling_code: "372", flag: "ee" },
      ],
    },
    /**
     * If options are passed in from "options" prop,
     * then this compoent does not enable fetching options from database
     */
    options: {
      type: Array,
      default: null,
    },
    label: {
      type: String,
      default: "Keel",
    },
    autoFetch: {
      type: Boolean,
      default: false,
    },
    dense: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      keyField: "code",
      selected: null,
      fetchedOptions: [],
      loading: false,
      search: null,
      fetched: null,
      lastEmitted: null,
    };
  },

  created() {
    if (Array.isArray(this.options) && this.options.length > 0) {
      this.fetched = true;
    }

    const value = this.value ? this.value : null;

    if (value) this.selected = value;

    if (this.selected) this.lastEmitted = this.selected;

    if (!this.selectedItem) {
      this.search = true;
    }
  },

  watch: {
    search(value) {
      if (this.fetched || this.loading) return;
      this.fetchcountries();
    },
    value(value) {
      this.selected = value;
    },
  },

  computed: {
    items() {
      if (Array.isArray(this.options) && this.options.length > 0) {
        return this.options;
      } else if (Array.isArray(this.fetchedOptions) && this.fetchedOptions.length > 0) {
        return this.fetchedOptions;
      }
      return this.defaultOptions;
    },
    selectedItem() {
      return this.items.find((item) => item[this.keyField] === this.selected);
    },
  },

  methods: {
    filter(item, queryText, itemText) {
      const textOne = item.name.toLowerCase();
      const textTwo = item.native_name.toLowerCase();
      const textThird = item.country_name ? item.country_name.toLowerCase() : "";
      const searchText = queryText.toLowerCase();

      return (
        textOne.indexOf(searchText) > -1 ||
        textTwo.indexOf(searchText) > -1 ||
        textThird.indexOf(searchText) > -1
      );
    },
    async fetchcountries() {
      this.loading = true;
      try {
        const response = await this.axios({
          method: "get",
          url: "api/countries",
          baseURL: "/",
        });

        this.fetchedOptions = response.data;
        this.fetched = true;
        this.emitInput(this.selected);
      } catch (error) {
        console.error(error);
      } finally {
        this.loading = false;
      }
    },
    emitInput(input) {
      if (this.lastEmitted === input) return;
      this.$emit("input", input);
      this.$emit("country", this.selectedItem);
    },
  },
};
</script>
