<template>
  <div class="business page">
    <NotFound v-if="mixinBusinessNotFound"></NotFound>
    <div v-else-if="business">
      <JoblistingForm
        v-if="joblisting"
        :business="business"
        :joblisting="joblisting"
        @updated="joblisting = $event"
      />
    </div>
  </div>
</template>

<script>
import JoblistingForm from "../components/save/JoblistingForm.vue";

import NotFound from "@/blocks/not-found/views/NotFound";
import fetchBusinessMixin from "@/blocks/business/fetchBusinessMixin";
import fetchJoblistingMixin from "@/blocks/jobs/joblisting/fetchJoblistingMixin";
import emptyJoblisting from "@/blocks/jobs/joblisting/emptyJoblisting.js";

export default {
  mixins: [fetchBusinessMixin, fetchJoblistingMixin],

  components: {
    NotFound,
    JoblistingForm,
  },

  head() {
    if (!this.joblisting || !this.business) {
      return {};
    }

    return {
      ...this.business.head,
      title:
        (this.joblisting.title ? this.joblisting.title : "Lisa") +
        " tööpakkumine | " +
        this.business.head.title,
    };
  },

  created() {
    this.initialize();
  },

  data() {
    return {
      loading: true,
      joblisting: null,
    };
  },

  methods: {
    async initialize() {
      if (this.$route.name === "EditJoblisting") {
        this.mixinInitializeJoblisting();
      } else if (this.$route.name === "CreateJoblisting") {
        this.joblisting = emptyJoblisting;
      }
    },
  },
};
</script>
