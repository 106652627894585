<style lang="scss">
@import "@/dependencies.scss";
.search-location-field {
  input {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  @include media-breakpoint-up(md) {
    max-width: 160px;
    border-right: thin solid rgba(0, 0, 0, 0.12) !important;
  }
}
</style>
<template>
  <v-combobox
    class="search-location-field w-100"
    style="min-width: 180px"
    clearable
    flat
    solo
    placeholder="Asukoht"
    hide-details="auto"
    v-model="searchTerm"
    :items="options"
  ></v-combobox>
</template>

<script>
import cacheMixin from "@/mixins/cacheMixin";

export default {
  mixins: [cacheMixin],

  props: {
    value: {
      type: String,
      default: "",
    },
  },

  created() {
    this.pullCache(["options"], "JobsBannerSearchLocationField");
    // setTimeout(() => {
    this.fetchOptions();
    // }, 1000);
  },

  destroyed() {
    this.saveCache();
  },

  data() {
    return {
      options: [],
      searchTerm: this.value,
      lastInstantSearchTerm: this.value,
      lastInstantSearchTermResults: [],
      items: [],
      fetchingSearchTerms: [],
    };
  },

  computed: {
    showMenu() {
      if (!this.lastInstantSearchTerm || this.instantSearchDisabled || this.searchTerm.length < 2) {
        return false;
      }
      return true;
    },

    isTyping() {
      return this.typingIndex > 0;
    },
  },

  watch: {
    value(value) {
      this.searchTerm = value;
    },
    searchTerm(searchTerm) {
      if (searchTerm === this.value) return;
      this.$emit("input", searchTerm);
    },
  },

  methods: {
    async fetchOptions() {
      const response = await this.axios.get("joblisting/query/location-options");
      this.options = response.data;
    },
  },
};
</script>
