<style lang="scss">
@import "@/dependencies.scss";
.curriculum-languages {
  @media only screen and (max-width: 400px) {
  }
  @include media-breakpoint-only(xs) {
    .row {
      max-width: 250px !important;
    }
  }
}
</style>

<template>
  <div class="curriculum-languages d-flex justify-center d-md-block mt-3">
    <v-row justify="center" justify-md="start">
      <v-col cols="12" sm="5" md="6" lg="4" v-for="(item, i) in items" :key="i">
        <v-list-item dark>
          <v-list-item-action>
            <CountryFlag :country="item.flag"></CountryFlag>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title class="mb-1">{{ item.name }}</v-list-item-title>
            <v-list-item-subtitle>{{ level(item.level) }}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import CountryFlag from "./CountryFlag.vue";
import levels from "@/blocks/jobs/languageLevels";

export default {
  components: {
    CountryFlag,
  },
  props: {
    title: {
      type: String,
      default: null,
    },
    items: {
      type: Array,
      required: true,
    },
  },
  methods: {
    level(key) {
      return levels.find((item) => item.key === key).label;
    },
  },
};
</script>