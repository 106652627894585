<style lang="scss">
// @import "@/dependencies.scss";
</style>

<template>
  <div class="show-curriculum page">
    <NotFound v-if="mixinCurriculumNotFound"></NotFound>
    <UserCurriculum v-else-if="curriculum" :curriculum="curriculum"></UserCurriculum>
  </div>
</template>

<script>
import NotFound from "@/blocks/not-found/views/NotFound.vue";
import UserCurriculum from "@/blocks/jobs/curriculum/components/show/UserCurriculum.vue";
import fetchCurriculumMixin from "@/blocks/jobs/curriculum/fetchCurriculumMixin.js";

export default {
  mixins: [fetchCurriculumMixin],

  components: {
    UserCurriculum,
    NotFound,
  },

  created() {
    this.mixinInitializeCurriculum();
  },

  head() {
    return this.mixinLoadingCurriculum
      ? {}
      : { title: this.getName() + this.$store.getters.setting("meta_brand") };
  },

  data() {
    return {
      curriculum: null,
    };
  },

  methods: {
    getName() {
      let name = [];
      if (this.curriculum) {
        if (this.curriculum.firstname) name.push(this.curriculum.firstname);
        if (this.curriculum.lastname) name.push(this.curriculum.lastname);
      }

      if (name.length === 0) {
        name.push("Kasutaja");
      }

      return name.join(" ") + " CV";
    },
  },
};
</script>








