<style lang="scss">
.vel-img-title {
  width: 80vh !important;
  max-width: 600px !important;
  white-space: normal !important;
  line-height: 1.5 !important;
}
</style>
<template>
  <div>
    <div class="white" id="js-parent" style="position: relative !important; z-index: 1">
      <v-container class="w-100 d-none d-md-block pt-0" style="position: absolute !important">
        <v-row no-gutters :style="`min-height: ${parentHeight}px;max-height: ${parentHeight}px`">
          <v-col class="white" :cols="4"></v-col>
          <v-col :class="color" :cols="8"></v-col>
        </v-row>
      </v-container>
      <v-container
        style="position: relative !important"
        class="py-0 pl-0"
        :style="$vuetify.breakpoint.smAndUp ? 'min-height:calc(100vh - 80px) !important' : ''"
      >
        <v-row no-gutters>
          <v-col :cols="12" :md="5" class="mt-md-10 pt-md-10">
            <v-sheet class="d-flex justify-center d-md-block">
              <div class="ma-5 py-md-5">
                <CurriculumMain :color="color" :curriculum="curriculum" />
              </div>
            </v-sheet>
          </v-col>
          <v-col
            :cols="12"
            :md="7"
            class="white--text"
            :class="color"
            :style="$vuetify.breakpoint.smAndUp ? 'min-height:calc(100vh - 80px) !important' : ''"
          >
            <article class="pt-10" v-if="curriculum.experience.length">
              <CurriculumArticleTitle :class="paddingLeftClasses">
                Töökogemus
              </CurriculumArticleTitle>
              <CurriculumTimeline :items="curriculum.experience" />
            </article>

            <article class="pt-10" v-if="curriculum.education && curriculum.education.length">
              <CurriculumArticleTitle :class="paddingLeftClasses">Haridus</CurriculumArticleTitle>
              <CurriculumTimeline :items="curriculum.education" />
            </article>

            <article class="pt-10" v-if="curriculum.certificates && curriculum.certificates.length">
              <CurriculumArticleTitle :class="paddingLeftClasses">
                Koolitused ja sertifikaadid
              </CurriculumArticleTitle>
              <CurriculumTimeline :items="curriculum.certificates" />
            </article>

            <div :class="paddingLeftClasses">
              <article class="pt-10" v-if="curriculum.languages && curriculum.languages.length">
                <CurriculumArticleTitle>Keeleoskus</CurriculumArticleTitle>
                <CurriculumLanguages :items="curriculum.languages" />
              </article>

              <article class="pt-10" v-if="curriculum.skills && curriculum.skills.length">
                <CurriculumArticleTitle>Oskused</CurriculumArticleTitle>
                <CurriculumRanges :items="curriculum.skills" />
              </article>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </div>

    <div class="white" style="position: relative; z-index: 2" v-if="curriculum.references.length">
      <v-container>
        <v-row>
          <v-col>
            <CurriculumReferences :references="curriculum.references" />
          </v-col>
        </v-row>
      </v-container>
    </div>
  </div>
</template>

<script>
import CurriculumReferences from "./CurriculumReferences.vue";
import CurriculumLanguages from "@/blocks/jobs/curriculum/components/show/CurriculumLanguages.vue";
import CurriculumArticleTitle from "@/blocks/jobs/curriculum/components/show/CurriculumArticleTitle.vue";
import { getHeight } from "@/common/viewport.js";

import CurriculumRanges from "@/blocks/jobs/curriculum/components/show/CurriculumRanges.vue";
import CurriculumTimeline from "@/blocks/jobs/curriculum/components/show/CurriculumTimeline.vue";
import CurriculumMain from "@/blocks/jobs/curriculum/components/show/CurriculumMain.vue";
import HtmlContent from "@/blocks/global/components/HtmlContent.vue";

export default {
  components: {
    HtmlContent,
    CurriculumMain,
    CurriculumTimeline,
    CurriculumRanges,
    CurriculumArticleTitle,
    CurriculumLanguages,
    CurriculumReferences,
  },

  props: {
    curriculum: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      paddingLeftClasses: "pl-md-8 pl-lg-9 pl-xl-13 ",
      parentHeight: 1500,
    };
  },
  computed: {
    color() {
      return "grey darken-3";
    },
  },
  mounted() {
    this.saveVisit();
    this.$nextTick(() => {
      window.addEventListener("resize", this.onResize);
      setTimeout(() => {
        this.onResize();
      }, 100);
    });
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.onResize);
  },

  methods: {
    onResize() {
      this.setHeight();
    },

    setHeight: _.debounce(function () {
      const newHeight = getHeight("#js-parent");
      this.parentHeight = newHeight;
    }, 200),

    saveVisit() {
      if (this.curriculum.user_id === this._.get(this.$store.state.auth, ["user", "id"], null)) {
        return;
      }

      this.axios.post(`user/${this.curriculum.user_id}/curriculum/visit`);
    },
  },
};
</script>