<style lang="scss">
</style>

<template>
  <div>
    <v-alert tile type="error" text dense v-if="formObject.state('questions') === false">
      {{ formObject.error("questions") }}
    </v-alert>

    <div v-for="(item, i) in items" :key="i" class="mt-5">
      <RichTextEditor
        :disabled="disabled"
        :label="item.title"
        v-model="item.value"
        :error="state(i, 'value')"
        :error-message="error(i, 'value')"
      ></RichTextEditor>
    </div>
  </div>
</template>

<script>
import RichTextEditor from "@/blocks/jobs/joblisting/components/save/inputs/RichTextEditor.vue";

export default {
  components: {
    RichTextEditor,
  },
  props: {
    value: {
      type: Array,
      default: () => [],
    },
    options: {
      type: Array,
      required: true,
    },
    formObject: {
      type: Object,
      default: null,
    },
    name: {
      type: String,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      items: this.setItems(),
    };
  },
  computed: {
    hasError() {
      if (!this.formObject) return null;
      return this.formObject.state("questions");
    },
  },
  watch: {
    items: {
      handler: function (items) {
        this.$emit("input", this.collectItems());
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    setItems() {
      const items = this.options.map((option) => {
        const existingItem = this.value.find((item) => item.title === option.title);
        return {
          ...option,
          value: existingItem ? existingItem.value : null,
          title: option.title,
        };
      });

      return items;
    },

    collectItems() {
      const items = [];
      this.items.forEach((item) => {
        items.push({ value: item.value, title: item.title });
      });
      return items;
    },

    error(i, field) {
      if (!this.formObject) return null;
      return this.formObject.error(this.name + "." + i + "." + field);
    },
    state(i, field) {
      if (!this.formObject) return null;
      return this.formObject.state(this.name + "." + i + "." + field);
    },
  },
};
</script>