export default {
  data() {
    return {
      mixinLoadingCurriculum: null,
      mixinCurriculumNotFound: null,
      curriculum: null,
    };
  },

  watch: {
    $route() {
      this.mixinForceFetchCurriculum();
    },
  },

  computed: {
    /*
     * Determines if we already have full data of curriculum or only basic data
     * that were used to index curriculumes.
     */
    mixinIsFinalCurriculum() {
      // return this.curriculum && this.curriculum.final;
      return this.curriculum ? true : false;
    },
  },

  methods: {
    mixinInitializeCurriculum() {
      this.mixinSetLocalClonedCurriculum();
      this.mixinFetchCurriculum();
    },

    /**
     * * Clone local copy of the curriculum.
     *
     * At first, there could only be curriculum's basic information and
     * author, content etc will be fetched on demand, so we need
     * to clone new object to make sure everything re-renders
     * after full data of curriculum is fetched.
     */
    mixinSetLocalClonedCurriculum() {
      this.curriculum = this._.cloneDeep(
        this.$store.getters["curriculum/findBy"]("items", "user_id", parseInt(this.$route.params.user_id)),
      );
    },

    async mixinFetchCurriculum() {
      if (this.mixinIsFinalCurriculum) {
        return;
      }
      this.mixinForceFetchCurriculum();
    },

    async mixinForceFetchCurriculum() {
      this.mixinLoadingCurriculum = true;
      try {
        const response = await this.axios.get("user/" + this.$route.params.user_id + '/curriculum');
        this.$store.commit("curriculum/ADD_OR_UPDATE_ITEM", response.data);
        this.mixinSetLocalClonedCurriculum();
      } catch (error) {
        if (error.response.status === 404) this.mixinCurriculumNotFound = true;
      } finally {
        this.mixinLoadingCurriculum = false;
      }
    },
  },
};
