<template>
  <div>
    <NotFound
      v-if="!$store.getters['auth/isAuthenticated'] || mixinBusinessNotFound || joblistingNotFound"
    />
    <div v-else-if="business && joblisting">
      <DefaultTemplate>
        <template #title>Töökuulutuse kandidaadid</template>
        <JoblistingApplicationsContent
          @updated="joblisting = $event"
          :business="business"
          :joblisting="joblisting"
          :jobapplications="jobapplications"
        />
      </DefaultTemplate>
    </div>
  </div>
</template>
    
<script >
import JoblistingApplicationsContent from "../components/applications/JoblistingApplicationsContent.vue";
import NotFound from "../../../not-found/views/NotFound.vue";
import DefaultTemplate from "@/blocks/page/components/templates/DefaultTemplate.vue";

import fetchBusinessMixin from "@/blocks/business/fetchBusinessMixin";

export default {
  mixins: [fetchBusinessMixin],

  components: {
    DefaultTemplate,
    NotFound,
    JoblistingApplicationsContent,
  },

  watch: {
    business: {
      handler: function (business) {
        if (!business) return;
        this.initialize();
      },
      immediate: true,
    },
  },

  data() {
    return {
      business: null, // handled by mixin
      joblisting: null,
      joblistingNotFound: null,
      jobapplications: null,
      //   initialized: null,
    };
  },

  methods: {
    async initialize() {
      //   this.initialized = false;
      this.$store.commit("START_LOADING", "JoblistingJobapplications");

      try {
        const response = await this.axios.get(
          `/business/${this.business.id}/joblisting/${this.$route.params.joblisting_id}/applications`,
        );
        this.joblisting = response.data.joblisting;
        this.jobapplications = response.data.jobapplications;
        this.joblistingNotFound = false;
        // this.initialized = true;
      } catch (error) {
        if ((this._.get(error, ["response", "status"]), parseInt(error.response.status) === 404)) {
          this.joblistingNotFound = true;
        } else {
          this.$catchApiErrors(error);
        }
        // this.initialized = null;
      } finally {
        this.$store.commit("STOP_LOADING", "JoblistingJobapplications");
      }
    },
  },
};
</script>