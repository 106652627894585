<style lang="scss">
// @import "@/dependencies.scss";
</style>

<template>
  <div>
    <v-img max-width="160px" src="/storage/demo/jobs/cvsektor.png" class="mb-3" />
    <div class="text-body-2 mb-2">
      CV-SEKTOR aitab teil leida töö ehitussektoris. Kõik tööpakkumised leiad nüüd siit.
    </div>
    <v-btn color="success" :to="{ name: 'Page', params: { slug: 'cv-sektor' } }">
      Loe lähemalt
      <v-icon right>mdi-chevron-right</v-icon>
    </v-btn>
    <router-link
      :to="{ name: 'Page', params: { slug: 'ukrainlastele' } }"
      class="mt-3 pa-1 d-flex link align-center"
      v-ripple="{ class: 'yellow--text' }"
    >
      <!-- <v-avatar size="24"> -->
      <div class="my-1 mr-2">
        <v-img
          max-width="36"
          class="elevation-1"
          src="/storage/demo/jobs/flag_of_ukraine.svg"
        ></v-img>
      </div>
      <span class="text-subtitle-2 text--secondary">
        Мені потрібна допомога у складанні резюме.
      </span>
    </router-link>
  </div>
</template>

<script>
export default {};
</script>