<template>
  <div class="curriculum-ranges d-flex justify-center px-3 d-md-block pl-md-0 mt-3">
    <div style="max-width: 600px; width: 600px">
      <div v-for="(item, i) in mappedItems" :key="i">
        <div style="max-width: 70vw">
          <h3 v-if="item.title" class="text-h6 curriculum-ranges__label">
            <span class="text-wrap">{{ item.title }}</span>
          </h3>
        </div>
        <div>
          <v-slider
            :height="60"
            thumb-color="white"
            :value="item.value"
            dark
            thumb-label="always"
            readonly
          >
            <template #thumb-label>
              <span
                class="black--text text-no-wrap text-body-2"
                v-html="item.value + `<span style='margin-left:2px'>%</span>`"
              ></span>
            </template>
          </v-slider>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import skills from "@/blocks/jobs/skills";

export default {
  props: {
    title: {
      type: String,
      default: null,
    },
    items: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      skills,
      mappedItems: this.items.map((el) => {
        return { ...el, title: skills.find((item) => item.key === el.key).title };
      }),
    };
  },
};
</script>

<style lang="scss">
.curriculum-ranges {
  .curriculum-ranges__label {
    margin-left: 6px;
    // padding-bottom: -18px;
    margin-bottom: -18px;
  }
  .v-slider--horizontal .v-slider__track-container {
    height: 3px;
  }
  .v-slider__thumb-label {
    height: 25px !important;
    width: 44px !important;
    transform: translateY(0) translateY(2px) translateX(-22px) rotate(0) !important;
    border-radius: 3px 3px 0 0 !important;
    & > * {
      transform: rotate(0);
    }
  }
  .v-slider__thumb {
    display: none;
  }
  .v-slider--horizontal .v-slider__thumb-label > * {
    transform: rotate(0) !important;
  }
}
</style>
