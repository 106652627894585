
<template>
  <div>
    <v-container class="pa-0">
      <v-row class="my-0">
        <v-col>
          <slot v-bind:total="total" name="default"></slot>
          <PublicJoblistingsList show-first-divider :items="joblistings" />
        </v-col>
      </v-row>
      <v-row justify="center">
        <v-col cols="12" class="d-flex justify-center">
          <div
            class="mb-5"
            :title="allLoaded ? 'Rohkem tööpakkumisi ei ole' : 'Lae rohkem tööpakkumisi'"
          >
            <base-btn
              color="black"
              :dark="!allLoaded"
              :loading="loading"
              @click="loadMore"
              :disabled="allLoaded"
            >
              Lae rohkem tööpakkumisi
            </base-btn>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import PublicJoblistingsList from "../../joblisting/components/show/PublicJoblistingsList.vue";

import cacheMixin from "@/mixins/cacheMixin";
export default {
  mixins: [cacheMixin],
  components: {
    PublicJoblistingsList,
  },
  props: {
    initialQuery: {
      type: Object,
      default: () => {
        return {
          page: 1,
          per_page: 10,
          order_by: "updated_at",
          order_desc: true,
        };
      },
    },
    /**
     * Turn cache feature on or off. If off, then each route visit will refetch data. If on, then data will be cached in store until tab closed.
     */
    useCache: {
      type: Boolean,
      default: true,
    },

    /**
     * Set the cache key. Defaults to current route path.
     */
    cacheKey: {
      type: String,
      default: null,
    },
  },

  created() {
    this.pullCache(["joblistings", "query", "total", "loadedPage"], this.cacheKey);
    this.fetch(!this.useCache);
  },
  destroyed() {
    this.saveCache();
  },

  data() {
    return {
      loading: null,
      joblistings: [],
      query: this._.cloneDeep(this.initialQuery),
      total: null,
      loadedPage: null,
    };
  },

  computed: {
    allLoaded() {
      if (this.total === null) return null;
      return this.joblistings.length >= this.total;
    },
  },

  methods: {
    async fetch(refetch = false) {
      if (!refetch) {
        if (this.loadedPage === this.query.page) return;
      } else {
        this.query.page = 1;
        this.loadedPage = null;
      }
      this.loading = true;
      try {
        const response = await this.axios.post("joblisting/query", this.query);
        this.joblistings = refetch
          ? response.data.data
          : [...this.joblistings, ...response.data.data];

        this.query = response.data.query;
        this.total = response.data.meta.total;
        this.loadedPage = this.query.page;
      } finally {
        this.loading = false;
      }
    },
    loadMore() {
      if (this.loading) return;
      this.query.page++;
      this.fetch();
    },
  },
};
</script>
