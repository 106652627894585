export default {
  facebook: {
    key: "facebook",
    title: "Facebook",
    mdi: "mdi-facebook",
    prefix: "facebook.com/",
    href: true,
  },
  viber: {
    key: "viber",
    title: "Viber",
    src: "/storage/base/icons/viber.svg",
  },
  telegram: {
    key: "telegram",
    title: "Telegram",
    src: "/storage/base/icons/telegram.svg",
  },
  whatsapp: {
    key: "whatsapp",
    title: "Whatsapp",
    mdi: "mdi-whatsapp",
  },
  skype: {
    key: "skype",
    title: "Skype",
    mdi: "mdi-skype",
  },
  linkedin: {
    key: "linkedin",
    title: "linkedin",
    mdi: "mdi-linkedin",
    prefix: "linkedin.com/in/",
    href: true,
  },
};
