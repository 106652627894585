<style lang="scss">
</style>

<template>
  <v-container class="px-0">
    <v-row justify="center">
      <v-col cols="12" md="8">
        <v-sheet class="pa-5">
          <div class="d-flex justify-space-between">
            <v-img
              v-if="showImage"
              class="elevation-1 mb-2"
              width="200px"
              :max-width="$vuetify.breakpoint.xs ? 140 : 200"
              :max-height="$vuetify.breakpoint.xs ? (140 * 200) / 125 : 125"
              :aspect-ratio="200 / 125"
              :src="joblisting.list_image_src ? joblisting.list_image_src : business.list_image"
            >
              <div class="image-gradient" />
              <v-avatar
                class="position-absolute"
                style="bottom: 6px; left: 6px; z-index: 2"
                color="white"
                size="60"
              >
                <v-sheet color="white">
                  <v-img
                    max-width="54px"
                    max-height="54px"
                    contain
                    :src="business.logo_avatar"
                  ></v-img>
                </v-sheet>
              </v-avatar>
            </v-img>
            <BookmarkJoblisting :joblisting="joblisting"></BookmarkJoblisting>
          </div>
          <div class="d-sm-flex justify-space-between alignt-end h-100 flex-wrap">
            <h3
              class="d-flex text-h3 align-center mb-1 font-weight-bold"
              :title="`Ametikoht: ${joblisting.title}`"
            >
              {{ joblisting.title + " " }}
            </h3>
            <JoblistingDetailChips :joblisting="joblisting" :business="business" />
          </div>
          <NarrowListItemWithIcon icon="mdi-domain" color="grey" v-if="showBusinessTitle">
            <span class="text--disabled mr-2">Ettevõte:</span>
            <router-link
              target="_blank"
              :to="{ name: 'Business', params: { business_slug: business.slug } }"
            >
              {{ business.title }}
            </router-link>
          </NarrowListItemWithIcon>
          <NarrowListItemWithIcon icon="mdi-map-marker" color="grey" v-if="joblisting.location">
            <span class="text--disabled mr-2">Asukoht:</span>
            <span class="">{{ joblisting.location }}</span>
          </NarrowListItemWithIcon>
          <NarrowListItemWithIcon
            v-if="joblisting.jobs_available > 1"
            icon="mdi-account-multiple-outline"
            color="grey"
          >
            <span class="text--disabled mr-2">Vabu töökohti kokku:</span>
            <span>{{ joblisting.jobs_available }}</span>
          </NarrowListItemWithIcon>
          <div></div>
          <v-divider class="my-5"></v-divider>
          <section v-if="joblisting.expectations">
            <h4 class="text-h6 pb-2">Ootused kandidaadile</h4>
            <HtmlContent :content="joblisting.expectations" />
            <v-divider class="my-5"></v-divider>
          </section>

          <section v-if="joblisting.description" class="">
            <h4 class="text-h6 pb-2">Tööülesannete kirjeldus</h4>
            <HtmlContent :content="joblisting.description" />
            <v-divider class="my-5"></v-divider>
          </section>

          <section v-if="joblisting.benefits" class="">
            <h4 class="text-h6 pb-2">Omalt poolt pakume</h4>
            <HtmlContent :content="joblisting.benefits" />
          </section>
        </v-sheet>
      </v-col>

      <v-col cols="12" md="4">
        <v-sheet class="pa-5">
          <section v-if="joblisting.languages.length || joblisting.skills.length">
            <h3 class="text-h5 pb-3">Nõutavad oskused</h3>
            <div v-if="joblisting.languages.length">
              <h4 class="text-h6">Keeled</h4>
              <v-list>
                <NarrowListItemWithIconTwoLine
                  v-for="(item, i) in joblisting.languages"
                  :key="`language-${i}`"
                >
                  {{ item.name }}
                  <template #subtitle>
                    <span class="mr-1 text--disabled">Nõutav tase:</span>
                    {{ languageLevelName(item) }}
                  </template>
                </NarrowListItemWithIconTwoLine>
              </v-list>
            </div>
            <div v-if="joblisting.skills.length">
              <h4 class="text-h6 mt-3">Muud oskused</h4>
              <v-list>
                <NarrowListItemWithIcon v-for="(item, i) in joblisting.skills" :key="`skill-${i}`">
                  {{ item.title }}
                </NarrowListItemWithIcon>
              </v-list>
            </div>
            <v-divider class="my-5 mt-3"></v-divider>
          </section>

          <section>
            <h4 class="text-h5 pb-3">Kandideerimine</h4>
            <div>
              <NarrowListItemWithIcon icon="mdi-calendar" v-if="deadline">
                <span class="text--disabled left">Tähtaeg:</span>
                <span class="title">{{ deadline }}</span>
              </NarrowListItemWithIcon>

              <slot v-bind:joblisting="joblisting" name="apply-button">
                <base-btn
                  class="my-3"
                  color="teal accent-4"
                  :disabled="joblisting.deadline_has_passed"
                  :dark="!joblisting.deadline_has_passed"
                  large
                  block
                  :to="{ name: 'SaveJobapplication', params: $route.params }"
                >
                  Kandideeri
                </base-btn>
              </slot>
              <div v-if="joblisting.deadline_has_passed">
                <small class="text--secondary">* Kandideerimise tähtaeg on möödunud!</small>
              </div>
            </div>
          </section>

          <section v-if="joblisting.contact_person_name || joblisting.contact_person_tel">
            <h4 class="text-h6 mt-5">Kontaktisik</h4>
            <div v-html="joblisting.contact_person_name" />
            <div v-html="joblisting.contact_person_tel" />
          </section>
          <v-divider class="my-5"></v-divider>
          <ShareButtons v-if="showShareButtons" :title="joblisting.title">
            Jaga tööpakkumist
          </ShareButtons>
        </v-sheet>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import BookmarkJoblisting from "../BookmarkJoblisting.vue";
import NarrowListItemWithIconTwoLine from "./NarrowListItemWithIconTwoLine.vue";
import JoblistingDetailChips from "./JoblistingDetailChips.vue";
import format from "date-fns/format";
import parseISO from "date-fns/parseISO";
import ShareButtons from "@/blocks/news/components/ShareButtons.vue";
import NarrowListItemWithIcon from "./NarrowListItemWithIcon.vue";
import CurriculumLanguages from "@/blocks/jobs/curriculum/components/show/CurriculumLanguages.vue";
import HtmlContent from "@/blocks/global/components/HtmlContent.vue";
import levels from "@/blocks/jobs/languageLevels";

export default {
  components: {
    HtmlContent,
    CurriculumLanguages,
    NarrowListItemWithIcon,
    ShareButtons,
    JoblistingDetailChips,
    NarrowListItemWithIconTwoLine,
    BookmarkJoblisting,
  },
  props: {
    business: {
      type: Object,
      required: true,
    },
    joblisting: {
      type: Object,
      required: true,
    },
    showBusinessTitle: {
      type: Boolean,
      default: false,
    },
    showImage: {
      type: Boolean,
      default: true,
    },
    showShareButtons: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    deadline() {
      if (!this.joblisting.deadline) return null;
      const date = parseISO(this.joblisting.deadline);
      const formatted = format(date, "dd.MM.yyyy");
      return formatted;
    },
  },

  methods: {
    languageLevelName(language) {
      const level = levels.find((level) => level.key === language.level);
      return level.label;
    },
  },
};
</script>