<style lang="scss">
// @import "@/dependencies.scss";
</style>

<template>
  <div>
    <v-avatar
      class="elevation-10"
      :class="color"
      :title="item && item.title ? item.title : undefined"
    >
      <div
        v-if="!item || (!item.src && !item.mdi)"
        class="d-flex justify-center w-100 text-h5 text-uppercase white--text"
      >
        {{ value.charAt(0) }}
      </div>
      <v-icon v-else-if="item.mdi" dark>{{ item.mdi }}</v-icon>
      <v-img
        v-else
        max-width="28"
        max-height="28"
        color="white"
        :src="item.src"
        class="white--text"
      ></v-img>
    </v-avatar>
  </div>
</template>

<script>
import socialMedias from "@/blocks/jobs/socialMedias";

export default {
  props: {
    value: {
      type: String,
      required: true,
    },
    color: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      socialMedias,
    };
  },
  computed: {
    item() {
      return this._.get(this.socialMedias, this.value);
    },
  },
};
</script>