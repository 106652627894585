var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{},[_c('div',{staticClass:"white pb-5"},[_c('JobsBanner'),_c('v-container',{staticClass:"mt-5"},[_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{staticStyle:{"max-width":"320px"}},[_c('JobsAd',{staticClass:"mt-5 mt-md-3"})],1),_c('v-col',{staticClass:"job-links-col",attrs:{"cols":"12","sm":"7","lg":"8"}},[_c('JobLinks',{staticClass:"job-links"})],1)],1)],1)],1),_c('v-container',{staticClass:"mt-3 mb-5"},[_c('v-row',[(_vm.$store.state.joblisting.publishedJoblistingsTotalCount)?_c('v-col',{attrs:{"cols":"12","id":"viimati-lisatud-toopakkumised"}},[_c('group-card',{attrs:{"title":"Viimati lisatud tööpakkumised"}},[_c('div',{staticClass:"d-flex justify-center"},[_c('LatestJoblistingsSlider',{staticStyle:{"max-width":"calc(100% - 60px)"}})],1)])],1):_vm._e(),(_vm._.get(_vm.$store.state.auth.user, 'is_business_manager'))?_c('LastVisitedCurriculums',{ref:"lastVisited"}):_vm._e(),(_vm.$store.state.joblisting.publishedJoblistingsTotalCount)?_c('v-col',{attrs:{"cols":"12"}},[_c('group-card',{attrs:{"title":"Tööpakkumised ehitussektoris"}},[_c('div',{staticClass:"mx-md-5"},[_c('JoblistingsQuery',{attrs:{"initial-query":{
                page: 1,
                per_page: 10,
                order_by: 'deadline',
                order_desc: false,
                featured_first: true,
              },"cache-key":"AllJoblistings"}})],1)])],1):_c('div',{staticClass:"w-100"},[_c('div',{staticClass:"text-center w-100 text-body-2 my-5"},[_vm._v(" Juba õige pea leiad siit ehitussektori tööpakkumised! ")])])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }