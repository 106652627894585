<template>
  <div>
    <v-sheet class="mb-3">
      <BusinessBanner :business="business" :title="titleH1" :subtitle="subtitle" />
    </v-sheet>
    <v-container v-if="business.final">
      <aside>
        <h2 class="text-center text-h5 font-weight-regular text-uppercase">Ettevõtte tutvustus</h2>
        <BusinessContent class="mt-3" v-if="hasContent" :business="business">
          <template #buttons>
            <div class="mt-3">
              <base-btn
                v-if="business.offerings.length"
                class="ma-3 ml-0"
                color="black"
                elevation="0"
                dark
                @click="$vuetify.goTo('#job-description')"
              >
                Vaata tööpakkumist
                <v-icon>mdi-chevron-down</v-icon>
              </base-btn>
              <base-btn
                class="ma-3 ml-0"
                outlined
                text
                :to="{ name: 'Business', params: { business_slug: business.slug } }"
              >
                Vaata ettevõtte profiili
              </base-btn>
            </div>
          </template>
        </BusinessContent>
      </aside>

      <article id="job-description">
        <h2 class="mt-10 text-center mb-2 text-h5 font-weight-regular text-uppercase">
          Tööpakkumise tutvustus
        </h2>

        <JoblistingDetails v-if="joblisting" :joblisting="joblisting" :business="business" />

        <!-- Skeleton -->
        <v-container class="px-0" v-else>
          <v-row justify="center">
            <v-col cols="12" md="8">
              <v-sheet class="pa-5">
                <v-skeleton-loader class="mx-auto" type="card"></v-skeleton-loader>
              </v-sheet>
            </v-col>
            <v-col cols="12" md="4">
              <v-sheet class="pa-5">
                <v-skeleton-loader class="mx-auto" type="card"></v-skeleton-loader>
              </v-sheet>
            </v-col>
          </v-row>
        </v-container>
      </article>
    </v-container>
  </div>
</template>


<script>
import JoblistingDetails from "./JoblistingDetails.vue";
import BusinessContent from "@/blocks/business/components/BusinessContent.vue";
import BusinessBanner from "@/blocks/business/components/business-banner/BusinessBanner";

export default {
  components: {
    BusinessBanner,
    BusinessContent,
    JoblistingDetails,
  },

  props: {
    business: {
      type: Object,
      required: true,
    },
    joblisting: {
      type: Object,
      default: null,
    },
  },

  computed: {
    titleH1() {
      if (!this.joblisting) return null;
      return this.joblisting.title; /*  + " | " + this.business.title + " tööpakkumine"; */
    },
    subtitle() {
      if (!this.joblisting) return null;
      return /* this.joblisting.title + " | " + */ this.business.title + " tööpakkumine";
    },
    hasContent() {
      return this.business.description ||
        this.business.categories.length ||
        this.business.tags.length ||
        this.business.intro_video_url ||
        this.business.intro_image
        ? true
        : false;
    },
  },
};
</script>
