<style lang="scss">
</style>

<template>
  <div>
    <v-alert tile type="error" text dense v-if="formObject.state('skills') === false">
      {{ formObject.error("skills") }}
    </v-alert>

    <div v-for="(item, i) in items" :key="i">
      <v-divider class="mb-10" v-show="i !== 0"></v-divider>
      <v-row>
        <v-col cols="12" md="5" enter-class="d-flex align-center h-100">
          <div class="d-flex align-center h-100">
            <v-checkbox v-model="item.enabled"></v-checkbox>
            <h3
              v-if="item.title"
              class="text-h6 cursor-pointer"
              @click="item.enabled = !item.enabled"
            >
              <span class="text-wrap">{{ item.title }}</span>
            </h3>
          </div>
        </v-col>
        <v-col cols="12" md="7">
          <v-slider
            light
            :height="60"
            v-model="item.value"
            thumb-label="always"
            step="5"
            :disabled="!item.enabled"
            hide-details="auto"
          >
            <template #thumb-label>
              <span
                class="text-no-wrap text-body-2"
                v-html="item.value + `<span style='margin-left:2px'>%</span>`"
              ></span>
            </template>
          </v-slider>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import skills from "@/blocks/jobs/skills";
export default {
  props: {
    value: {
      type: Array,
      default: () => [],
    },
    formObject: {
      type: Object,
      default: null,
    },
    name: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      items: this.setItems(),
    };
  },
  computed: {
    hasError() {
      if (!this.formObject) return null;
      return this.formObject.state("skills");
    },
  },
  watch: {
    items: {
      handler: function (items) {
        this.$emit("input", this.collectItems());
      },
      deep: true,
    },
  },
  methods: {
    setItems() {
      const items = skills.map((el) => {
        const existingItem = this.value.find((item) => item.key === el.key);
        return {
          ...el,
          value: existingItem ? existingItem.value : 50,
          enabled: existingItem ? true : false,
        };
      });

      return items;
    },

    collectItems() {
      const items = [];
      this.items.forEach((item) => {
        if (item.enabled) {
          items.push({ value: item.value, key: item.key });
        }
      });
      return items;
    },
  },
};
</script>