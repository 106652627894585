<template>
  <div class="business page">
    <DefaultTemplate v-if="!$store.getters['auth/isAuthenticated']" class="pb-1">
      <template #title>Tööpakkumisele kandideerimine</template>
      <v-alert tile type="info" class="">
        <h3 class="text-h6">Palun logi sisse, et kandideerida tööpakkumisele.</h3>
      </v-alert>
      <AuthForm outlined class=""></AuthForm>
    </DefaultTemplate>
    <!-- <NotFound
      v-if="!$store.getters['auth/isAuthenticated'] || mixinBusinessNotFound || joblistingNotFound"
    /> -->
    <SaveJobapplicationPage v-else />
  </div>
</template>

<script>
import AuthForm from '../../../auth/components/AuthForm.vue';
import DefaultTemplate from '../../../page/components/templates/DefaultTemplate.vue';
import SaveJobapplicationPage from "../components/SaveJobapplicationPage.vue";
import NotFound from "@/blocks/not-found/views/NotFound";

export default {
  components: {
    NotFound,
    SaveJobapplicationPage,
        DefaultTemplate,
        AuthForm,
  },

  head() {
    return {
      title: "Tööpakkumisele kandideerimine" + this.$store.getters.setting("meta_brand"),
    };
  },
};
</script>
