<style lang="scss">
.items-grid__col {
  @include media-breakpoint-up(sm) {
    max-width: 320px;
  }
}
</style>

<template>
  <div>
    <div
      v-for="(item, i) in items"
      :key="item.id + '-' + i"
      class="joblisting-list-item image-gradient__wrap"
    >
      <div>
        <MyJobapplicationsListItem
          class=""
          v-bind="$attrs"
          :jobapplication="item"
        />

        <v-divider class="my-4" v-if="showLastDivider || items.length !== i + 1"></v-divider>
      </div>
    </div>
  </div>
</template>

<script>
import MyJobapplicationsListItem from "./MyJobapplicationsListItem.vue";

export default {
  components: {
    MyJobapplicationsListItem,
  },
  props: {
    items: {
      type: Array,
      required: true,
    },
    showLastDivider: {
      type: Boolean,
      default: true,
    },
  },
};
</script>
