<template>
  <div>
    <JoblistingListItem
      go-to-route-name="SaveJobapplication"
      :show-deadline="false"
      :show-excerpt="false"
      :joblisting="joblisting"
    >
      <template #footer>
        <v-sheet outlined>
          <v-simple-table dense bordered>
            <template v-slot:default>
              <tbody>
                <tr>
                  <th>Kandideerimise tähtaeg:</th>
                  <td>{{ deadline }}</td>
                </tr>
                <tr>
                  <th>Sina kandideerisid:</th>
                  <td>{{ " " + createdAt }}</td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-sheet>
        <!-- <div class="text-subtitle-2 text--secondary" v-if="deadline">
          <span class="font-weight-regular">Kandideerimise tähtaeg:</span>
          <span class="black--text">{{ " " + deadline }}</span>
        </div>
        <div class="text-subtitle-2 text--secondary">
          <span class="font-weight-regular">Kandideerimise aeg:</span>
          <span class="black--text">{{ " " + createdAt }}</span>
        </div>
        <div class="text-subtitle-2 text--secondary">
          <span class="font-weight-regular">Kandideerimise aeg:</span>
          <span class="black--text">{{ " " + createdAt }}</span>
        </div> -->
      </template>
    </JoblistingListItem>
  </div>
</template>

<script>
import JoblistingListItem from "../../joblisting/components/show/joblisting-item/JoblistingListItem.vue";
import format from "date-fns/format";
import parseISO from "date-fns/parseISO";
import { et } from "date-fns/locale";

export default {
  components: {
    JoblistingListItem,
  },
  props: {
    jobapplication: {
      type: Object,
      required: true,
    },
  },
  computed: {
    joblisting() {
      return this.jobapplication.joblisting;
    },
    business() {
      return this.jobapplication.business;
    },
    deadline() {
      return this.formatDate(this.joblisting.deadline);
    },
    createdAt() {
      return this.formatDate(this.jobapplication.created_at);
    },
  },

  methods: {
    formatDate(date) {
      if (!date) return null;
      try {
        return format(parseISO(date), "d. MMMM yyyy", { locale: et });
      } catch (error) {
        return null;
      }
    },
  },
};
</script>
