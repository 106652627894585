export default [
  {key: "physical_strength", title: "Füüsiline tugevus ja vastupidavus"},
  {key: "organize", title: "Organiseerimisvõime"},
  {key: "general_knowledge", title: "Üldised ehitusteadmised"},
  {key: "communication", title: "Suhtlemisoskus"},
  {key: "problem_solving", title: "Probleemide lahendamise võime"},
  {key: "technology", title: "Tehnilisus (AutoCAD, Revit)"},
  {key: "interoperability", title: "Koostöövõime"},
  {key: "scheduling", title: "Ajaplaneerimine"},
];
