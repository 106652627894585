<template>
  <v-card class="image-gradient__wrap" tile tag="article">
    <v-sheet
      @click="$router.push(goToRoute)"
      class="elevation-1 cursor-pointer"
      :style="`background: url('${
        joblisting.list_image_src ? joblisting.list_image_src : joblisting.business_list_image
      }') no-repeat fixed center; background-size: cover;`"
      height="125px"
    >
      <div class="image-gradient" style="max-height: 125px"></div>
      <v-avatar
        class="position-absolute"
        style="top: calc(125px - 6px - 60px); left: 6px; z-index: 2"
        color="white"
        size="60"
      >
        <v-sheet color="white">
          <v-img
            max-width="54px"
            max-height="54px"
            contain
            :src="joblisting.business_logo_avatar"
          ></v-img>
        </v-sheet>
      </v-avatar>
    </v-sheet>
    <v-card-text class="pa-2">
      <div class="text-left">
        <router-link
          class="link cursor-pointer"
          :is="onLinkClick ? 'span' : 'router-link'"
          :to="onLinkClick ? null : goToRoute"
          @click="onLinkClick ? onLinkClick() : null"
        >
          <h4 class="text-h6">
            <span class="d-inline-block text-truncate" style="max-width: 190px">
              {{ joblisting.title }}
            </span>
          </h4>
        </router-link>
        <div class="red--text text--darken-4 text-subtitle-2 d-flex align-center">
          <v-icon color="red " class="mr-1" small>mdi-at</v-icon>
          <span class="d-inline-block text-truncate" style="max-width: 180px">
            {{ joblisting.business_title }}
          </span>
        </div>

        <div class="text--disabled d-flex align-center mb-2" v-if="joblisting.location">
          <v-icon color="grey lighten-1" class="mr-1" small>mdi-map-marker</v-icon>
          <span class="d-inline-block text-truncate" style="max-width: 180px">
            {{ joblisting.location }}
          </span>
        </div>
        <JoblistingDetailChips
          :x-spacing="1"
          wrap
          class="mt-1"
          rounded
          :tile="false"
          x-small
          :joblisting="joblisting"
        />
        <BookmarkJoblisting
          :joblisting="joblisting"
          x-small
          :icon-attrs="{ xSmall: true }"
        ></BookmarkJoblisting>
      </div>
    </v-card-text>
    <!-- <v-card-actions></v-card-actions> -->
  </v-card>
</template>

<script>
import BookmarkJoblisting from "../../BookmarkJoblisting.vue";
import JoblistingDetailChips from "../JoblistingDetailChips.vue";
import NarrowListItemWithIcon from "../NarrowListItemWithIcon.vue";

// import JoblistingAdminPanel from "./JoblistingAdminPanel.vue";
// import BookmarkBusiness from "../BookmarkBusiness";
import format from "date-fns/format";
import parseISO from "date-fns/parseISO";
import { et } from "date-fns/locale";

export default {
  components: {
    // BookmarkBusiness,
    // JoblistingAdminPanel,

    NarrowListItemWithIcon,
    JoblistingDetailChips,
    BookmarkJoblisting,
  },
  props: {
    goToRouteName: {
      type: String,
      default: null,
    },
    onLinkClick: {
      type: Function,
      default: null,
    },
    showDeadline: {
      type: Boolean,
      default: true,
    },
    showExcerpt: {
      type: Boolean,
      default: true,
    },
    joblisting: {
      type: Object,
      required: true,
    },
  },
  computed: {
    goToRoute() {
      return {
        name: this.goToRouteName ? this.goToRouteName : "ShowJoblisting",
        params: {
          business_slug: this.joblisting.business_slug,
          joblisting_id: this.joblisting.id,
        },
      };
    },
    deadline() {
      if (!this.showDeadline) return false;
      return this.formatDate(this.joblisting.deadline);
    },
  },

  methods: {
    formatDate(date) {
      if (!date) return null;
      try {
        return format(parseISO(date), "d. MMMM yyyy", { locale: et });
      } catch (error) {
        return null;
      }
    },
  },
};
</script>
