export const years = function(from = 1990, to = "now", addCount = false) {
  var currentYear = new Date().getFullYear();
  let years = [];

  if (from === "now") from = currentYear;
  if (to === "now") to = currentYear;
  if (addCount) to = to + addCount;

  while (from <= to) {
    years.push(from++);
  }
  return years.reverse();
};
