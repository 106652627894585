var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.joblistings.length)?_c('carousel',{attrs:{"mouse-drag":false,"scrollPerPage":false,"perPageCustom":[
      [280, 1],
      [500, 2],
      [823, 3],
      [1070, 4],
      [1425, 5],
    ],"paginationEnabled":false,"navigationEnabled":"","navigationNextLabel":"<i aria-hidden=\"true\" class=\"v-icon notranslate v-icon--right mdi mdi-chevron-right theme--light\" style=\"font-size: 36px;\"></i>","navigationPrevLabel":"<i aria-hidden=\"true\" class=\"v-icon notranslate v-icon--right mdi mdi-chevron-left theme--light\" style=\"font-size: 36px;\"></i>"}},_vm._l((_vm.joblistings),function(joblisting,i){return _c('slide',{key:`slide-${i}`},[_c('div',{staticClass:"d-flex justify-center my-5 mx-1"},[_c('JoblistingGridItem',{staticStyle:{"width":"230px"},attrs:{"joblisting":joblisting}})],1)])}),1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }