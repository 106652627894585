export default {
  id: null,
  jobs_available: 1,
  list_image: null,
  title: null,
  location: null,
  workload: null,
  wage_offer_from: null,
  wage_offer_to: null,
  deadline: null,
  tags: [],
  tags_array: [],
  expectations: ``,
  description: ``,
  benefits: ``,
  languages: [
    // { // Do not uncomment default language - this would require changes in JoblistingStoreRequest.php
    //   flag: "ee",
    //   language: "et",
    //   name: "Eesti keel",
    //   level: null,
    // },
  ],
  skills: [],
  questions: [],
  contact_person_name: ``,
  contact_person_tel: ``,
};
