<style lang="scss">
</style>

<template>
  <div>
    <v-alert tile type="error" text dense v-if="formObject.state('skills') === false">
      {{ formObject.error("skills") }}
    </v-alert>

    <div v-for="(item, i) in items" :key="i">
      <v-divider class="mb-10" v-show="i !== 0"></v-divider>
      <v-row>
        <v-col cols="12" md="5" enter-class="d-flex align-center h-100">
          <div class="d-flex align-center h-100">
            <!-- <v-checkbox v-model="item.enabled"></v-checkbox> -->
            <h3 v-if="item.title" class="text-h6 cursor-pointer">
              <span class="text-wrap">{{ item.title }}</span>
            </h3>
          </div>
        </v-col>
        <v-col cols="12" md="7">
          <v-slider
            light
            :height="60"
            v-model="item.value"
            thumb-label="always"
            step="5"
            hide-details="auto"
            :error-messages="error(i, 'value')"
            :error="state(i, 'value')"
            :readonly="disabled"
          >
            <template #thumb-label>
              <span
                class="text-no-wrap text-body-2"
                v-html="item.value + `<span style='margin-left:2px'>%</span>`"
              ></span>
            </template>
          </v-slider>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
// import skills from "@/blocks/jobs/skills";
export default {
  props: {
    value: {
      type: Array,
      default: () => [],
    },
    options: {
      type: Array,
      required: true,
    },
    formObject: {
      type: Object,
      default: null,
    },
    name: {
      type: String,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      items: this.setItems(),
    };
  },
  computed: {
    hasError() {
      if (!this.formObject) return null;
      return this.formObject.state("skills");
    },
  },
  watch: {
    items: {
      handler: function (items) {
        this.$emit("input", this.collectItems());
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    setItems() {
      const items = this.options.map((el) => {
        const existingItem = this.value.find((item) => item.title === el.title);
        return {
          ...el,
          value: existingItem ? existingItem.value : 50,
          title: el.title,
        };
      });

      return items;
    },

    collectItems() {
      const items = [];
      this.items.forEach((item) => {
        items.push({
          value: item.value /*  key: item.key, */,
          title: item.title,
        });
      });
      return items;
    },

    error(i, field) {
      if (!this.formObject) return null;
      return this.formObject.error(this.name + "." + i + "." + field);
    },
    state(i, field) {
      if (!this.formObject) return null;
      return this.formObject.state(this.name + "." + i + "." + field);
    },
  },
};
</script>