<style lang="scss">
</style>

<template>
  <div>
    <div class="d-flex justify-center" v-if="!authUserId">
      <div style="width: 800px; max-width: 800px">
        <v-alert type="info">
          Kandidaatide nimekiri on nähtav vaid Ehitussektoriga liitunud ettevõtjatele. Kui oled juba
          liitunud, siis palun logi sisse kasutades allolevat vormi.
        </v-alert>

        <AuthForm outlined class="ma-3"></AuthForm>
      </div>
    </div>
    <div v-else-if="!authUserIsBusinessManager">
      <v-alert type="error" text>
        Teil puuduvad õigused selle lehe vaatamiseks - see leht on mõeldud vaid Ehitussektoriga
        liitunud ettevõtetele.
      </v-alert>
    </div>
    <div v-else>
      <div class="d-md-flex justify-space-between align-top">
        <div style="width: 500px; max-width: 90vw" class="order-md-last">
          <CurriculumSearch @searched="onSearched"></CurriculumSearch>
        </div>
        <div class="mb-10">
          <h3 class="text-h5 font-weight-thin pt-3 " v-html="constraintsDescription" />
          <v-chip
            close
            v-if="query.search_term"
            key="search-term"
            @click:close="onSearchTermClosed()"
            :title="`Otsingusõna: ` + query.search_term"
            class="ma-1"
          >
            {{ this.$capitalize(query.search_term) }}
          </v-chip>
        </div>
      </div>

      <CurriculumsGrid :curriculums="items" />

      <div class="text-center">
        <div
          class="pb-5"
          :title="allLoaded ? 'Rohkem kandidaate ei leitud' : 'Lae rohkem kandidaate'"
        >
          <base-btn
            color="black"
            :dark="!allLoaded"
            :loading="loading"
            @click="fetchNextPageItems"
            :disabled="allLoaded"
          >
            <span v-if="!total">Kandidaate ei leitud</span>
            <span v-else-if="allLoaded">Rohkem kandidaate ei ole</span>
            <span v-else>Lae rohkem kandidaate</span>
          </base-btn>

          <div
            class="pt-3 text-body-2 text--disabled"
            v-if="query.page > 1 && total - items.length > 0"
          >
            <em>
              Laadida on veel {{ total - items.length }}
              {{ total - items.length > 1 ? "kandidaati" : "kandidaat" }}
            </em>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AuthForm from "../../../../auth/components/AuthForm.vue";
import CurriculumSearch from "./CurriculumSearch.vue";
import CurriculumsGrid from "./CurriculumsGrid.vue";

export default {
  components: {
    CurriculumsGrid,
    CurriculumSearch,
    AuthForm,
  },
  props: {
    storeState: {
      type: Object,
      required: true,
    },
    storeAccessor: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      isGrid: false,
    };
  },
  watch: {
    authUserId(newValue) {
      this.fetchFirstPageItems(true);
    },
  },

  created() {
    this.fetchFirstPageItems(true);
  },

  computed: {
    authUserId() {
      return this._.get(this.$store.state.auth.user, "id");
    },
    authUserIsBusinessManager() {
      return this._.get(this.$store.state.auth.user, "is_business_manager");
    },
    constraintsDescription() {
      if (this.loading) {
        return "Palun oota..";
      } else if (!this.total) {
        return "Kriteeriumitele vastavaid kandidaate ei leitud.";
      }
      const one = this.total === 1;
      let text = ``;
      if (this.query.search_term) {
        text += `märksõnale <strong>${this.query.search_term}</strong> `;
      }

      //   const isConstrainedByCategory = this.selectedCategories && this.selectedCategories.length > 0;
      //   const isConstrainedByRegion = this.selectedRegions && this.selectedRegions.length > 0;

      text += `leiti ${this.total} `;
      text += one ? "ettevõte" : " kandidaati";

      //   /* KATEGOORIAD */
      //   if (isConstrainedByCategory) {
      //     text += one ? ", mis kuulub " : ", mis kuuluvad ";
      //     const singular = this.selectedCategories.length === 1;
      //     text += singular ? "kategooriasse " : "kategooriatesse ";
      //     text += `"${this.$joinAsSentence(
      //       this.selectedCategories.map((el) => {
      //         return el.title;
      //       }),
      //       `", "`,
      //       `" ja "`,
      //     )}"`;
      //     if (isConstrainedByRegion) text += "<br> ja ";
      //   }

      //   /* REGIOONID */
      //   if (isConstrainedByRegion) {
      //     if (!isConstrainedByCategory) text += ", mis ";
      //     text += one ? "tegutseb " : "tegutsevad ";
      //     const singular = this.selectedRegions.length === 1;
      //     text += singular ? "piirkonnas " : "piirkondades ";
      //     text += `"${this.$joinAsSentence(
      //       this.selectedRegions.map((el) => {
      //         return el.title;
      //       }),
      //       `", "`,
      //       `" ja "`,
      //     )}"`;
      //   }

      text += ".";

      return this.$capitalize(text);
    },
    loading() {
      return this.storeState.loading;
    },
    total() {
      return this.storeState.total;
    },
    query() {
      return this.storeState.query;
    },
    items() {
      return this.storeState.items;
    },
    // regions() {
    //   // must be explicitly from $store.state.curriculum, not from `storeState` property
    //   return this.$store.state.curriculum.regions;
    // },
    // categories() {
    //   // must be explicitly from $store.state.curriculum, not from `storeState` property
    //   return this.$store.state.curriculum.categories;
    // },
    // selectedCategories() {
    //   return this.categories.filter((el) => this.query.category_ids.includes(el.id));
    // },
    // selectedRegions() {
    //   return this.regions.filter((el) => this.query.region_ids.includes(el.id));
    // },
    allLoaded() {
      if (this.total === null) return null;
      return this.items.length >= this.total;
    },
  },

  methods: {
    onSearched(searchTerm) {
      this.$store.commit("curriculum/SET_QUERY", { search_term: searchTerm });
      this.fetchFirstPageItems(false);
      //   setTimeout(() => {
      //     this.$nextTick().then(
      //       this.$nextTick(() => {
      //         this.$vuetify.goTo("#business-listings");
      //       }),
      //     );
      //   }, 600);
    },
    queryGet(field) {
      const str = this._.get(this.query, field, "").toLowerCase();
      if (!str) return null;
      return str.split(",");
    },

    onSearchTermClosed(category) {
      this.$store.commit(`${this.storeAccessor}SET_QUERY`, {
        search_term: "",
      });
      this.fetchFirstPageItems();
    },

    // onCategoryClosed(category) {
    //   this.$store.commit(`${this.storeAccessor}SET_QUERY`, {
    //     category_ids: this.query.category_ids.filter((id) => id !== category.id),
    //   });
    //   this.fetchFirstPageItems();
    // },

    // onRegionClosed(region) {
    //   this.$store.commit(`${this.storeAccessor}SET_QUERY`, {
    //     region_ids: this.query.region_ids.filter((id) => id !== region.id),
    //   });
    //   this.fetchFirstPageItems();
    // },

    // onRegionSelected(regionId) {
    //   this.$store.commit(`${this.storeAccessor}SET_QUERY`, { region_ids: [regionId] });
    //   this.fetchFirstPageItems();
    // },

    fetchFirstPageItems(skipIfFetched = false) {
      this.$store.dispatch(`${this.storeAccessor}fetchFirstPageItems`, skipIfFetched);
    },

    fetchNextPageItems() {
      this.$store.dispatch(`${this.storeAccessor}fetchNextPageItems`);
    },
  },
};
</script>
