<template>
  <DefaultTemplate>
    <template #title>
      <div>Minu kandideerimised</div>
    </template>

    <JobapplicationItems />
  </DefaultTemplate>
</template>
    
<script >
import JobapplicationItems from "../index/JobapplicationItems.vue";
import DefaultTemplate from "@/blocks/page/components/templates/DefaultTemplate.vue";
export default {
  components: {
    DefaultTemplate,
    JobapplicationItems,
  },
};
</script>