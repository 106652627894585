export default {
  firstname: null,
  lastname: null,
  profile_image_src: null,
  avatar_src: null,
  profile_image_alt: null,
  job_title: null,
  description: ``,
  email: null,
  address_street: null,
  address_city: null,
  address_country: null,
  tel: null,
  wage_request: null,
  experience: [
    {
      from: null,
      to: null,
      title: null,
      description: null,
    },
  ],
  education: [
    {
      from: null,
      to: null,
      title: null,
      description: null,
    },
  ],
  certificates: [
    {
      from: null,
      to: null,
      title: null,
      description: null,
    },
  ],
  languages: [],
  skills: [
    {title: "Füüsiline tugevus ja vastupidavus", value: null, show: null},
    {title: "Organiseerimisvõime", show: null},
    {title: "Üldised ehitusteadmised", show: null},
    {title: "Suhtlemisoskus", show: null},
    {title: "Probleemide lahendamise võime", show: null},
    {title: "Tehnilisus (AutoCAD, Revit)", show: null},
    {title: "Koostöövõime", show: null},
    {title: "Ajaplaneerimine", show: null},
  ],
};
