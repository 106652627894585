<style lang="scss">
.items-grid__col {
  @include media-breakpoint-up(sm) {
    max-width: 320px;
  }
}
</style>

<template>
  <div>
    <div
      v-for="(item, i) in items"
      :key="item.id + '-' + i"
      class="joblisting-list-item image-gradient__wrap"
    >
      <div>
        <JoblistingListItem
          :show-excerpt="false"
          v-bind="$attrs"
          :for-business-manager="false"
          :joblisting="item"
        >
          <template #footer>
            <JoblistingAdminPanel
              v-if="forBusinessManager"
              @updated="$emit('updated', $event)"
              @destroyed="$emit('destroyed', $event)"
              :business="business"
              :item="item"
            />
          </template>
        </JoblistingListItem>

        <v-divider class="my-4" v-if="showLastDivider || items.length !== i + 1"></v-divider>
      </div>
    </div>
  </div>
</template>

<script>
import JoblistingAdminPanel from "./joblisting-item/JoblistingAdminPanel.vue";
import JoblistingListItem from "./joblisting-item/JoblistingListItem";
export default {
  components: {
    JoblistingListItem,
    JoblistingAdminPanel,
  },
  props: {
    items: {
      type: Array,
      required: true,
    },
    showLastDivider: {
      type: Boolean,
      default: false,
    },
    forBusinessManager: {
      type: Boolean,
      default: false,
    },
    business: {
      type: Object,
      default: null,
    },
  },
};
</script>
