<template>
  <div>
    <JoblistingListItem
      :show-deadline="false"
      :show-excerpt="false"
      :joblisting="joblisting"
      :on-link-click="onJoblistingItemClick"
    >
      <template #footer>
        <FullscreenDialog
          ref="joblistingDialog"
          route-key="joblisting"
          toolbar-title="Tööpakkumise andmed"
          btn-title="Tööpakkumise andmed"
          close-title="Jätka kandideerimist"
          :btn-props="{ outlined: true }"
        >
          <template #default="{ closeDialog }">
            <JoblistingDetails
              :business="business"
              :joblisting="joblisting"
              show-business-title
              show-image
              :show-share-buttons="false"
            >
              <template #apply-button>
                <base-btn class="my-3" color="primary" large dark block @click="closeDialog()">
                  Jätka kandideerimist
                </base-btn>
              </template>
            </JoblistingDetails>
          </template>
        </FullscreenDialog>
      </template>
    </JoblistingListItem>
    <div v-if="!hasApplied">
      <p class="mt-3">
        Oled kandideerimas ettevõtte {{ business.title }} poolt avaldatud
        <strong>"{{ joblisting.title }}"</strong>
        tööpakkumisele.
      </p>

      <p v-if="hasQuestions || hasSkills">
        Tööpakkumisele kandideerimine on imelihtne. Sul peab olema vaid täidetud Ehitussektori CV.
        Seejärel, peale mõnele küsimusele vastamist saadki juba esitada ettevõttele enda
        kandidatuuri.
      </p>
      <p v-else>
        Tööpakkumisele kandideerimine on imelihtne. Sul peab olema vaid täidetud Ehitussektori CV
        ning seejärel saadki juba esitada enda kandidatuuri ettevõttele, kes seejärel sinuga ise
        ühendust võtab.
      </p>
      <v-alert
        tile
        :type="curriculum.is_published ? 'success' : curriculumError ? 'error' : 'warning'"
        dark
        text
      >
        <div class="mb-2" v-if="curriculum.is_published">
          <strong>CV on täidetud</strong>
          <br />
          {{ curriculum.firstname }}, sinu CV on täidetud ja valmis esitamiseks.
          <br />
          Soovi korral saad oma CV üle vaadata ja seda täiendada:
        </div>
        <div class="mb-2" v-else>
          <strong>Palun täida CV</strong>
          <br />
          Kandideerimise eelduseks on täidetud CV olemasolu. Palun täida CV ja tee see avalikuks:
        </div>
        <FullscreenDialog
          ref="curriculumDialog"
          route-key="curriculum"
          toolbar-title="CV muutmine"
          :btn-title="curriculum.is_published ? `CV muutmine` : `CV täitmine`"
          close-title="Jätka kandideerimist"
          :btn-props="{ outlined: false, color: 'white', light: true, dark: false }"
        >
          <CurriculumForm
            :curriculum="curriculum"
            :userId="$store.state.auth.user.id"
            save-button-style="position: sticky; top: 64px"
            @updated="
              curriculum = $store.commit('auth/SET', {
                ...$store.state.auth.user,
                curriculum: $event,
              })
            "
          />
        </FullscreenDialog>
      </v-alert>
    </div>
  </div>
</template>


<script>
import JoblistingListItem from "../../../joblisting/components/show/joblisting-item/JoblistingListItem.vue";
import FullscreenDialog from "./FullscreenDialog.vue";
import CurriculumForm from "../../../curriculum/components/save/CurriculumForm.vue";
import JoblistingDetails from "../../../joblisting/components/show/JoblistingDetails.vue";

export default {
  components: {
    JoblistingDetails,
    CurriculumForm,
    FullscreenDialog,
    JoblistingListItem,
  },

  props: {
    jobapplication: {
      type: Object,
      required: true,
    },
    joblisting: {
      type: Object,
      required: true,
    },
    business: {
      type: Object,
      required: true,
    },
    curriculumError: {
      type: Boolean,
      default: false,
    },
    hasApplied: {
      type: Boolean,
      default: false,
    },
  },

  created() {
    this.cleanupRouteQuery();
  },

  data() {
    return {
      step: 2,
      joblistingDialog: false,
    };
  },

  computed: {
    curriculum() {
      return this.$store.state.auth.user.curriculum;
    },
    hasQuestions() {
      return this.joblisting.questions.length;
    },
    hasSkills() {
      return this.joblisting.skills.length;
    },
  },

  methods: {
    cleanupRouteQuery() {
      if (!this._.has(this.$route.query, "dialog")) return;
      this.$router.replace({
        name: this.$route.name,
        params: this.$route.params,
        query: {},
      });
    },

    onJoblistingItemClick() {
      this.$refs.joblistingDialog.showDialog();
    },

    // called from refs
    showCurriculumDialog() {
      this.$refs.curriculumDialog.showDialog();
    },
  },
};
</script>
