<style lang="scss">
</style>

<template>
  <div>
    <JobLinksGrid
      v-bind="$attrs"
      :items="items"
      v-on="$listeners"
      v-if="$vuetify.breakpoint.mdAndUp"
    />
    <JobLinksList v-bind="$attrs" :items="items" v-on="$listeners" v-else />
  </div>
</template>

<script>
import JobLinksList from "./JobLinksList";
import JobLinksGrid from "./JobLinksGrid";
export default {
  components: {
    JobLinksList,
    JobLinksGrid,
  },
  computed: {
    isAuthenticated() {
      return this.$store.state.auth.user.id !== null;
    },

    addCurriculumRoute() {
      return this.isAuthenticated
        ? { name: "SaveCurriculum", params: { user_id: this.$store.state.auth.user.id } }
        : {
            name: "Page",
            params: { slug: "lisa-enda-cv" },
            query: { intended_name: "SaveCurriculum", intended_params: "user_id" },
          };
    },

    firstBusinessSlug() {
      return this._.get(this.$store.state.auth.user, ["managed_businesses", 0, "slug"]);
    },

    findEmployeeRoute() {
      return this.firstBusinessSlug
        ? {
            name: "Curriculums",
          }
        : {
            name: "Page",
            params: { slug: "leia-tootaja" },
            query: { intended_name: ["Curriculums"] },
          };
    },

    items() {
      return [
        {
          title: "Tee end ehitussektoris nähtavaks. <br> Lisa enda CV!",
          thumbnail: "/storage/demo/jobs/istockphoto-1135562871-612x612.jpg",
          to: this.addCurriculumRoute,
        },
        {
          title: "Leia sobiv töö ehitussektoris",
          thumbnail: "/storage/demo/jobs/istockphoto-1346403797-612x612.jpg",
          onClick: () => {
            const el = document.getElementById("viimati-lisatud-toopakkumised");

            if (el) {
              el.scrollIntoView({ behavior: "smooth" });
            }
          },
        },
        {
          title: "Leia sobiv töötaja",
          thumbnail: "/storage/demo/jobs/istockphoto-1345113252-612x612.jpg",
          to: this.findEmployeeRoute,
        },
      ];
    },
  },
};
</script>
