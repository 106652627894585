<style lang="scss">
// @import "@/dependencies.scss";
.form-error {
  .quillWrapper {
    border: 1px solid rgba(255, 0, 0, 0.4);
  }
}
</style>

<template>
  <div :class="error === false ? 'form-error' : ''">
    <div class="pb-1">
      <label class="text-h6" :class="error === false ? 'red--text' : ''" :for="key" v-if="label">
        {{ label }}
      </label>
    </div>
    <VueEditor :editor-toolbar="editorToolbar" :id="key" v-bind="$attrs" v-model="innerValue" />
    <div v-if="error === false">
      <small class="red--text">{{ errorMessage }}</small>
    </div>
  </div>
</template>

<script>
import { VueEditor } from "vue2-editor";
import { randomString } from "@/common/helpers/randomString";

const defaultToolbar = [
  [{ header: [false, 1, 2, 3, 4, 5, 6] }],
  ["bold", "italic", "underline" /* "strike" */], // toggled buttons
//   [{ align: "" }, { align: "center" }, { align: "right" }, { align: "justify" }],
  ["blockquote", /* "code-block" */],
  [{ list: "ordered" }, { list: "bullet" } /* { list: "check" } */],
  [{ indent: "-1" }, { indent: "+1" }], // outdent/indent
  // [{ color: [] }, { background: [] }], // dropdown with defaults from theme
  //   ["link",  "image", "video" ],
  ["clean"], // remove formatting button
];

export default {
  components: { VueEditor },
  props: {
    value: {
      type: String,
      default: null,
    },
    editorToolbar: {
      type: Array,
      default: () => defaultToolbar,
    },
    label: {
      type: String,
      default: null,
    },
    // false if error, null or true if no error
    error: {
      type: Boolean,
      default: null,
    },
    errorMessage: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      key: randomString(),
      innerValue: this.value,
    };
  },
  watch: {
    innerValue(innerValue) {
      this.$emit("input", innerValue);
    },
  },
};
</script>