<style lang="scss">
</style>

<template>
  <v-list dense two-line>
    <v-list-item
      v-for="(item, i) in items"
      :to="item.to ? item.to : undefined"
      @click="item.onClick ? item.onClick() : undefined"
      :key="i"
      color="primary"
      @click.prevent="$emit('input', item)"
      :class="{ 'v-list-item--active': false }"
    >
      <v-list-item-action>
        <v-avatar>
          <v-img :src="item.thumbnail"></v-img>
        </v-avatar>
      </v-list-item-action>
      <v-list-item-content>
        <v-list-item-title>
          <div v-html="item.title"></div>
        </v-list-item-title>
      </v-list-item-content>
    </v-list-item>
  </v-list>
</template>

<script>
export default {
  props: {
    activeitemIds: {
      type: Array,
      default: () => [],
    },
    items: {
      type: Array,
      requireds: true,
    },
  },
};
</script>
