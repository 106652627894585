<style lang="scss">
.timeline-item-input {
  .v-textarea textarea {
    min-height: 94px !important;
  }
}
</style>

<template>
  <div class="timeline-item-input">
    <v-scroll-y-transition group>
      <div v-for="(item, i) in items" :key="item.id">
        <v-divider class="my-5" v-show="i !== 0"></v-divider>
        <v-row>
          <v-col
            cols="6"
            md="1"
            class="d-flex flex-md-column align-md-center justify-md-space-between"
          >
            <div class="text-center">
              <v-avatar color="grey lighten-3 cursor-default" size="28" class="mb-1">
                <span class="text-subtitle-2 text--secondary">{{ i + 1 + "." }}</span>
              </v-avatar>
              <v-btn title="Nihuta alla" icon @click="moveUp(i)" v-show="i !== 0">
                <v-icon>mdi-arrow-up</v-icon>
              </v-btn>
              <v-btn title="Nihuta üles" icon @click="moveDown(i)" v-show="i < items.length - 1">
                <v-icon>mdi-arrow-down</v-icon>
              </v-btn>
            </div>
          </v-col>
          <v-col cols="6" md="1" order-md="4" class="d-flex justify-end d-md-block">
            <v-menu bottom left>
              <template v-slot:activator="{ on, attrs }">
                <v-btn title="Kustuta rida" icon v-bind="attrs" v-on="on">
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </template>

              <v-list>
                <v-list-item @click="destroyRow(item.id)">
                  <v-list-item-action>
                    <v-icon>mdi-close</v-icon>
                  </v-list-item-action>
                  <v-list-item-title>Kustuta rida nr {{ i + 1 }} ?</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </v-col>
          <v-col cols="12" md="5">
            <v-row>
              <v-col v-if="!disabled['from']" :cols="disabled['to'] ? 12 : 6">
                <v-autocomplete
                  v-bind="{ ...localFormProps, ...fromProps }"
                  v-model="item.from"
                  :items="years"
                  :error-messages="error(i, 'from')"
                  :error="state(i, 'from')"
                ></v-autocomplete>
              </v-col>
              <v-col v-if="!disabled['to']" :cols="disabled['from'] ? 12 : 6">
                <v-autocomplete
                  v-bind="{ ...localFormProps, ...toProps }"
                  v-model="item.to"
                  :items="toYears"
                  :error-messages="error(i, 'to')"
                  :error="state(i, 'to')"
                ></v-autocomplete>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  v-if="!disabled['title']"
                  v-bind="{ ...localFormProps, ...titleProps }"
                  v-model="item.title"
                  :error-messages="error(i, 'title')"
                  :error="state(i, 'title')"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" md="5">
            <v-textarea
              v-if="!disabled['description']"
              rows="3"
              auto-grow
              v-model="item.description"
              v-bind="{ ...localFormProps, ...descriptionProps }"
              :error-messages="error(i, 'description')"
              :error="state(i, 'description')"
            ></v-textarea>
          </v-col>
        </v-row>
      </div>
    </v-scroll-y-transition>
    <div class="d-flex justify-start mt-5">
      <base-btn small @click="addItem()" outlined>
        <v-icon left>mdi-plus</v-icon>
        <span v-text="addLabel"></span>
      </base-btn>
    </div>
  </div>
</template>

<script>
import { reorderArray } from "@/common/helpers/reorderArray";
import { randomString } from "@/common/helpers/randomString";
import { years } from "@/common/helpers/years";
const emptyItem = {
  id: randomString(10),
  from: null,
  to: null,
  title: "",
  description: "",
};

function getItems(items) {
  return items.map((item) => {
    return {
      id: item.id ? item.id : randomString(10),
      ...item,
    };
  });
}

export default {
  props: {
    addLabel: {
      type: String,
      default: "Lisa rida",
    },
    formProps: {
      type: Object,
      default: () => {},
    },
    value: {
      type: Array,
      default: () => [],
    },
    disabledFields: {
      type: Array,
      default: () => [],
    },
    fromProps: {
      type: Object,
      default: () => {
        return { label: "Alates" };
      },
    },
    toProps: {
      type: Object,
      default: () => {
        return { label: "Kuni" };
      },
    },
    titleProps: {
      type: Object,
      default: () => {
        return { label: "Pealkiri" };
      },
    },
    descriptionProps: {
      type: Object,
      default: () => {
        return { label: "Kirjeldus" };
      },
    },
    formObject: {
      type: Object,
      default: null,
    },
    name: {
      type: String,
      default: null,
    },
  },
  computed: {
    localFormProps() {
      return { ...this.formProps, dense: true };
    },
    disabled() {
      const disabled = { from: false, to: false, title: false, description: false };

      for (let [key, value] of Object.entries(disabled)) {
        if (this.disabledFields.includes(key)) {
          disabled[key] = true;
        }
      }

      Object.keys(disabled).forEach((key) => {});

      return disabled;
    },
    toYears() {
      const years = this._.cloneDeep(this.years);
      years.unshift({ value: "now", text: "Tänaseni" });
      return years;
    },
  },
  data() {
    return {
      items: getItems(this.value),
      years: years(1950).map((year) => {
        return {
          text: year,
          value: year,
        };
      }),
    };
  },
  watch: {
    items: {
      handler: function (items) {
        this.$emit("input", items);
      },
      deep: true,
    },
  },
  methods: {
    addItem() {
      this.items.push({ ...emptyItem, id: randomString(10) });
    },
    moveUp(index) {
      if (index <= 0) return;
      reorderArray(this.items, index, index - 1);
    },
    moveDown(index) {
      if (index >= this.items.length - 1) return;
      reorderArray(this.items, index, index + 1);
    },
    destroyRow(id) {
      this.items = this.items.filter((el) => el.id !== id);
    },
    error(i, field) {
      if (!this.formObject) return null;
      return this.formObject.error(this.name + "." + i + "." + field);
    },
    state(i, field) {
      if (!this.formObject) return null;
      return this.formObject.state(this.name + "." + i + "." + field);
    },
  },
};
</script>