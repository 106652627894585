
<template>
  <v-autocomplete
    v-model="selected"
    :filter="filter"
    @change="$emit('change', selectedLanguage)"
    @input="emitInput"
    v-on="$listeners"
    :items="items"
    v-bind="{ outlined: true, ...$attrs }"
    :item-value="keyField"
    :dense="dense"
    @click="autoFetch ? (search = true) : null"
  >
    <template #label v-if="label">
      <span v-text="`Keel`" />
    </template>
    <template #item="slotProps">
      <CountryFlag :size="dense ? 24 : 36" :country="slotProps.item.flag" left />
      {{ slotProps.item.name }}
      <span class="ml-2" v-if="slotProps.item.name !== slotProps.item.native_name">
        ({{ slotProps.item.native_name }})
      </span>
    </template>
    <template #append-item>
      <v-list-item v-if="loading">
        <v-list-item-content class="d-flex">
          <v-progress-circular indeterminate color="info"></v-progress-circular>
        </v-list-item-content>
      </v-list-item>
      <v-list-item data-cy="load-more-languages" v-else-if="!fetched" @click="search = true">
        <v-list-item-content>
          <v-list-item-title>
            <em>
              {{ "Näita rohkem keeli" }}
            </em>
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </template>
    <template #selection="slotProps">
      <CountryFlag
        :size="dense ? 24 : 36"
        :country="slotProps.item.flag.replace('.svg', '')"
        left
      />
      {{ slotProps.item.name }}
      <span span class="ml-2" v-if="slotProps.item.name !== slotProps.item.native_name">
        ({{ slotProps.item.native_name }})
      </span>
    </template>
  </v-autocomplete>
</template>

<script>
import CountryFlag from "@/blocks/jobs/curriculum/components/show/CountryFlag.vue";

export default {
  components: {
    CountryFlag,
  },
  props: {
    value: {
      type: String,
      default: null,
    },
    /**
     * If options are passed in from "defaultOptions" prop,
     * then this component will have "load more" button that fetches all options.
     */
    defaultOptions: {
      type: Array,
      default: () => [
        {
          name: "Eesti keel",
          regional: "et_EE",
          key: "et",
          country_iso: "EE",
          native_name: "Eesti keel",
          flag: "ee",
        },
        {
          name: "Vene keel",
          regional: "ru_RU",
          key: "ru",
          country_iso: "RU",
          native_name: "\u0420\u0443\u0441\u0441\u043a\u0438\u0439",
          flag: "ru",
        },
        {
          name: "Inglise keel",
          regional: "en_GB",
          key: "en",
          country_iso: "GB",
          native_name: "English",
          flag: "gb",
        },
        {
          name: "Soome keel",
          regional: "fi_FI",
          key: "fi",
          country_iso: "FI",
          native_name: "Suomi",
          flag: "fi",
        },
        {
          name: "Rootsi keel",
          regional: "sv_SE",
          key: "sv",
          country_iso: "SE",
          native_name: "Svenska",
          flag: "se",
        },
        {
          name: "Saksa keel",
          regional: "de_DE",
          key: "de",
          country_iso: "DE",
          native_name: "Deutsch",
          flag: "de",
        },
      ],
    },
    /**
     * If options are passed in from "options" prop,
     * then this compoent does not enable fetching options from database
     */
    options: {
      type: Array,
      default: null,
    },
    label: {
      type: String,
      default: "Keel",
    },
    autoFetch: {
      type: Boolean,
      default: false,
    },
    dense: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      keyField: "key",
      selected: null,
      fetchedOptions: [],
      loading: false,
      search: null,
      fetched: null,
      lastEmitted: null,
    };
  },

  created() {
    if (Array.isArray(this.options) && this.options.length > 0) {
      this.fetched = true;
    }

    const value = this.value ? this.value : null;

    if (value) this.selected = value;

    if (this.selected) this.lastEmitted = this.selected;

    if (value && !this.selectedLanguage) {
      this.search = true;
    }
  },

  watch: {
    search(value) {
      if (this.fetched || this.loading) return;
      this.fetchLanguages();
    },
    value(value) {
      this.selected = value;
    },
  },

  computed: {
    items() {
      if (Array.isArray(this.options) && this.options.length > 0) {
        return this.options;
      } else if (Array.isArray(this.fetchedOptions) && this.fetchedOptions.length > 0) {
        return this.fetchedOptions;
      }
      return this.defaultOptions;
    },
    selectedLanguage() {
      return this.items.find((item) => item[this.keyField] === this.selected);
    },
  },

  methods: {
    filter(item, queryText, itemText) {
      const textOne = item.name.toLowerCase();
      const textTwo = item.native_name.toLowerCase();
      const textThird = item.country_name ? item.country_name.toLowerCase() : "";
      const searchText = queryText.toLowerCase();

      return (
        textOne.indexOf(searchText) > -1 ||
        textTwo.indexOf(searchText) > -1 ||
        textThird.indexOf(searchText) > -1
      );
    },
    async fetchLanguages() {
      this.loading = true;
      try {
        const response = await this.axios({
          method: "get",
          url: "api/languages",
          baseURL: "/",
        });

        this.fetchedOptions = response.data;
        this.fetched = true;
        this.emitInput(this.selected);
      } catch (error) {
        console.error(error);
      } finally {
        this.loading = false;
      }
    },
    emitInput(input) {
      if (this.lastEmitted === input) return;
      this.$emit("input", input);
      this.$emit("language", this.selectedLanguage);
    },
  },
};
</script>
