<template>
  <v-row class="my-10">
    <v-col cols="12" class="m-10 text-center">
      <h2 class="text-h3 text-uppercase font-weight-bold">Referentsid</h2>
      <vue-easy-lightbox
        :visible="lightboxVisible"
        :imgs="lightboxImages"
        :index="lightboxIndex"
        @hide="lightboxVisible = false"
        @on-index-change="onIndexChange"
      >
        <template #title>
          <CurriculumReferencesLightboxTitle
            v-if="lightboxItem"
            :title="lightboxItem.title"
            :description="lightboxItem.description"
            :index="lightboxIndex"
            :count="lightboxImages.length"
          ></CurriculumReferencesLightboxTitle>
        </template>
      </vue-easy-lightbox>
    </v-col>
    <v-col
      v-for="(item, i) in items"
      :key="item.id"
      class="d-flex child-flex"
      cols="6"
      sm="4"
      md="3"
      lg="2"
    >
      <v-img
        style="cursor: pointer"
        @click="showLightboxImage(i)"
        :src="item.thumbnail"
        aspect-ratio="1"
        class="grey lighten-2"
      >
        <template v-slot:placeholder>
          <v-row class="fill-height ma-0" align="center" justify="center">
            <v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular>
          </v-row>
        </template>
      </v-img>
    </v-col>
    <v-col cols="12" v-if="items.length > sliceTo">
      <div class="text-center">
        <div class="pb-5" :title="allLoaded ? 'Rohkem referentse ei ole' : 'Lae rohkem referentse'">
          <base-btn
            color="black"
            :dark="!allLoaded"
            :loading="loading"
            @click="loadMore"
            :disabled="allLoaded"
          >
            Lae rohkem
          </base-btn>
        </div>
      </div>
    </v-col>
  </v-row>
</template>

<script>
import CurriculumReferencesLightboxTitle from "./CurriculumReferencesLightboxTitle.vue";
import VueEasyLightbox from "vue-easy-lightbox";
export default {
  components: {
    VueEasyLightbox,
    CurriculumReferencesLightboxTitle,
  },
  mounted() {
    this.setLightboxItems();
  },
  props: {
    references: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      sliceTo: 12,
      loading: null,
      lightboxVisible: false,
      lightboxImages: null,
      lightboxIndex: null,
    };
  },
  computed: {
    lightboxItem() {
      if (!this.lightboxImages) return null;
      return this.lightboxImages[this.lightboxIndex];
    },
    items() {
      return this.references.slice(0, this.sliceTo);
    },
    allLoaded() {
      return this.items.length >= this.references.length;
    },
  },
  methods: {
    loadMore() {
      if (this.loading) return;
      this.loading = true;

      setTimeout(() => {
        this.sliceTo += 12;
        this.loading = false;
      }, 500);
    },
    setLightboxItems() {
      this.lightboxImages = this.references.map((el, i) => {
        return {
          ...el,
          src: el.image,
        };
      });
    },
    showLightboxImage(index) {
      this.lightboxIndex = index; // index of imgList
      this.lightboxVisible = true;
    },
    onIndexChange(oldIndex, newIndex) {
      this.lightboxIndex = newIndex;
    },
  },
};
</script>
