<template>
  <v-sheet>
    <v-data-table :headers="headers" :items="items">
      <template v-slot:item.fullname="{ item }">
        <v-avatar size="28" class="mr-2" v-if="item.avatar_src">
          <v-img :src="item.avatar_src"></v-img>
        </v-avatar>
        {{ item.fullname }}
      </template>
      <template v-slot:item.id="{ item }">
        <div class="d-flex flex-no-wrap">
          <v-chip @click="showDialog(item, 'curriculum')" class="mr-1" small>CV</v-chip>
          <v-chip small @click="showDialog(item, 'jobapplication')">Kandidatuur</v-chip>
        </div>
      </template>
    </v-data-table>
    <!-- <small>
      <pre>items: {{ items }}</pre>
      <pre>jobapplications: {{ jobapplications }}</pre>
    </small> -->

    <FullscreenDialog
      :toolbarProps="{ color: 'white', dark: false }"
      ref="curriculumDialog"
      route-key="curriculum"
      toolbar-title=""
      close-title="Tagasi"
      hide-activator
    >
      <template #toolbar-items>
        <v-toolbar-items class="ml-3">
          <v-btn
            v-bind="dialogType === 'curriculum' ? { color: 'primary' } : { text: true }"
            @click="dialogType = 'curriculum'"
          >
            CV
          </v-btn>
          <v-btn
            v-bind="dialogType === 'jobapplication' ? { color: 'primary' } : { text: true }"
            @click="dialogType = 'jobapplication'"
          >
            Kandidatuur
          </v-btn>
        </v-toolbar-items>
      </template>
      <div v-if="dialogItem">
        <div v-if="dialogType === 'curriculum'">
          <UserCurriculum :key="dialogItem.id" :curriculum="dialogItem.curriculum" />
        </div>
        <div v-else-if="dialogType === 'jobapplication'">
          <v-sheet color="gray">
            <JobapplicationContent :key="dialogItem.id" :jobapplication="dialogItem" />
          </v-sheet>
        </div>
      </div>
    </FullscreenDialog>
  </v-sheet>
</template>

<script>
import JobapplicationContent from "./JobapplicationContent.vue";
import UserCurriculum from "../../../curriculum/components/show/UserCurriculum.vue";
import FullscreenDialog from "../../../jobapplication/components/save/FullscreenDialog.vue";
export default {
  components: {
    FullscreenDialog,
    UserCurriculum,
    JobapplicationContent,
  },
  props: {
    business: {
      type: Object,
      required: true,
    },
    joblisting: {
      type: Object,
      required: true,
    },
    jobapplications: {
      type: Array,
      required: true,
    },
  },
  computed: {
    items() {
      return this.jobapplications.map((el) => {
        const c = el.curriculum;
        return {
          id: el.id,
          curriculum_id: c.id,
          avatar_src: c.avatar_src,
          fullname: c.fullname,
          tel: c.tel,
          email: c.email,
          created_at: el.created_at,
          updated_at: el.updated_at,
        };
      });
    },
  },
  data() {
    return {
      headers: [
        {
          text: "Nimi",
          //   align: "start",
          sortable: true,
          value: "fullname",
        },
        {
          text: "Email",
          //   align: "start",
          sortable: true,
          value: "email",
        },
        {
          text: "Telefon",
          //   align: "start",
          sortable: true,
          value: "tel",
        },
        {
          text: "Valikud",
          //   align: "start",
          sortable: false,
          value: "id",
        },
        // {
        //   text: "Kandideerinud",
        //   //   align: "start",
        //   sortable: true,
        //   value: "created_at",
        // },
        // { text: "Calories", value: "calories" },
      ],

      dialogType: null,
      dialogItem: null,
    };
  },
  methods: {
    showDialog(item, type) {
      this.dialogType = type;

      const jobapplication = this.jobapplications.find((el) => el.id === item.id);

      if (!jobapplication) {
        throw "Jobapplication not found!";
        return;
      }

      this.dialogItem = jobapplication;

      this.$refs.curriculumDialog.showDialog();
    },
  },
};
</script>