var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{staticClass:"h-100"},[_c('v-card',{staticClass:"my-10",attrs:{"elevation":"0","tile":""}},[_c('v-card-title',[_c('div',{staticClass:"w-100"},[_c('h1',{staticClass:"text-h4"},[_vm._v(_vm._s(_vm.content("title")))]),_c('v-divider',{staticClass:"mt-3"})],1)]),_c('v-card-text',[_c('div',{staticClass:"text-body-2",domProps:{"innerHTML":_vm._s(_vm.content('content'))}})]),(_vm.showList)?_c('div',[_c('group-card',{attrs:{"hide-title":""}},[_c('JoblistingsQuery',{attrs:{"initial-query":{
            bookmarked_by_me: true,
            page: 1,
            per_page: 10,
            order_by: 'updated_at',
            order_desc: true,
          },"use-cache":false},scopedSlots:_vm._u([{key:"default",fn:function({ total }){return [(total !== null)?_c('span',{staticClass:"text-body-2"},[_vm._v(" Jälgitud tööpakkumisi: "+_vm._s(total)+" ")]):_vm._e()]}}],null,false,208388425)})],1)],1):_c('div',{staticClass:"pb-1 d-flex justify-center"},[_c('div',{staticStyle:{"max-width":"700px"}},[_c('v-alert',{staticClass:"mx-3 mb-3",attrs:{"tile":"","type":"info"}},[_c('h3',{staticClass:"text-h6"},[_vm._v(_vm._s(_vm.content("please_authenticate")))])]),_c('AuthForm',{staticClass:"ma-3",attrs:{"outlined":""}})],1)])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }