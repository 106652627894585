<style lang="scss">
.curriculum-grid-item {
  .profile {
    transition: box-shadow 0.4s ease;
    box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14),
      0px 1px 3px 0px rgba(0, 0, 0, 0.12) !important;
  }
  &:hover {
    .profile {
      box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 13px 19px 2px rgba(0, 0, 0, 0.14),
        0px 5px 24px 4px rgba(0, 0, 0, 0.12) !important;
    }
    .name {
      text-decoration: underline;
    }
  }
}
</style>

<template>
  <div class="text-center pb-5 curriculum-grid-item">
    <span
      class="cursor-pointer"
      @click="$router.push({ name: 'ShowCurriculum', params: { user_id: item.user_id } })"
    >
      <v-avatar
        class="profile"
        :color="item.avatar_src ? `transparent` : 'grey darken-3'"
        size="164"
      >
        <v-img :src="item.avatar_src" :alt="item.profile_image_alt" v-if="item.avatar_src"></v-img>
        <v-icon v-else color="white" size="100">mdi-account</v-icon>
      </v-avatar>
      <h3 class="text-subtitle-1 mt-3 text-uppercase name">
        {{ item.firstname + " " + item.lastname }}
      </h3>
      <div class="primary--text">
        {{ item.job_title }}
      </div>
      <small class="text--disabled text-body-2">
        {{ item.address }}
      </small>
    </span>
  </div>
</template>

<script>
export default {
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
};
</script>