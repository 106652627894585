<template>
  <div>
    <v-container>
      <v-row no-gutters class="d-flex justify-center my-7">
        <v-col v-if="success || hasApplied" cols="12" sm="10" md="9" lg="8" class="min-h-100">
          <v-alert tile type="success" class="w-100">
            <div class="mb-2">
              <strong class="pb-2">Kandidatuur on esitatud</strong>
              <br />
              Suurepärane, {{ curriculum.firstname }}! Sinu kandidatuur on
              {{ business.title }} esindajale edastatud.
              <br />
              <base-btn class="mt-3" :to="{ name: 'MyJobapplications' }" outlined color="white">
                Minu kandideerimised
              </base-btn>
            </div>
          </v-alert>
        </v-col>
        <v-col v-if="!success" cols="12" sm="10" md="9" lg="8">
          <CardTemplate class="mb-5" v-for="(group, i) in fieldGroups" :key="i + 'group'">
            <template #header>
              <h2 class="text-h4">{{ group.label }}</h2>
            </template>

            <div
              v-if="group.hint"
              v-html="group.hint"
              class="font-weight-thin text-body-2 secondary--text mb-5"
            />
            <v-row>
              <v-col
                v-bind="{ cols: '12', md: '6', ...field.colProps }"
                v-for="(field, i) in group.items"
                :key="i + 'field'"
              >
                <component
                  :ref="field.ref ? field.ref : undefined"
                  :is="field.component ? field.component : 'VTextField'"
                  v-bind="field.props ? field.props : formProps"
                  :name="field.slug"
                  v-model="form[field.slug]"
                  :label="field.label"
                  :hint="field.hint"
                  :error-messages="form.error(field.slug)"
                  :error="form.state(field.slug)"
                  :form-object="form"
                />
              </v-col>
            </v-row>
          </CardTemplate>

          <base-card elevation="0" style="position: sticky; top: 0">
            <v-card-text>
              <div class="d-md-flex justify-space-between align-center">
                <v-alert
                  v-if="!hasApplied"
                  tile
                  :type="curriculumError ? 'error' : 'info'"
                  class="mr-5 my-1 text-body-2"
                  text
                >
                  <div v-if="curriculumError">
                    Palun täida ja avalda enda CV!
                    <br />
                    <base-btn class="ma-1" color="primary" @click="onEditCurriculumClick">
                      CV täitmine
                    </base-btn>
                  </div>
                  <div v-else>Kandideerimisel edastakse sinu CV tööpakkumise avaldajale.</div>
                </v-alert>
                <v-alert v-else tile :type="'success'" class="mr-5 my-1 text-body-2">
                  Sinu kandidatuur on esitatud!
                </v-alert>

                <base-btn
                  class="px-10 my-1"
                  name="submit"
                  color="'black'"
                  :dark="!hasApplied"
                  x-large
                  :loading="storeLoading"
                  @click="onApplyClick()"
                  :disabled="hasApplied"
                >
                  <div v-if="hasApplied">
                    <v-icon left>mdi-check</v-icon>
                    <span>Kandideeritud</span>
                  </div>
                  <span v-else>Kandideeri</span>
                </base-btn>
              </div>
              <v-alert tile dense v-if="form.errors.message" type="error" class="mt-3">
                <small v-html="form.errors.message" />
              </v-alert>
            </v-card-text>
          </base-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import JobapplicationQuestionsInput from "./JobapplicationQuestionsInput.vue";
import CardTemplate from "@/blocks/page/components/templates/CardTemplate.vue";
import Form from "@/common/form";
import { VTextField } from "vuetify/lib";
import { VTextarea } from "vuetify/lib";
import { VCombobox } from "vuetify/lib";
import { VueEditor } from "vue2-editor";
import SimpleRepeatableInput from "@/blocks/jobs/joblisting/components/save/SimpleRepeatableInput.vue";
import JobapplicationApplyIntroduction from "./JobapplicationApplyIntroduction.vue";
import CustomSkillsInput from "./CustomSkillsInput.vue";

const formProps = {
  hideDetails: "auto",
  persistentHint: true,
  persistentHint: true,
  filled: true,
  outlined: true,
};

export default {
  components: {
    CardTemplate,
    Form,
    VTextField,
    VTextarea,
    VCombobox,
    VueEditor,
    SimpleRepeatableInput,
    JobapplicationApplyIntroduction,
    CustomSkillsInput,
    JobapplicationQuestionsInput,
  },

  props: {
    jobapplication: {
      type: Object,
      required: true,
    },
    joblisting: {
      type: Object,
      required: true,
    },
    business: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      formProps,
      form: new Form(this.jobapplication),
      errorMessage: null,
      success: null,
      storeLoading: null,
      publishLoading: null,
      curriculumError: false,
      deadlineError: false,
    };
  },

  computed: {
    hasApplied() {
      return this.jobapplication.id ? true : false;
    },
    curriculum() {
      return this.$store.state.auth.user.curriculum;
    },

    path() {
      const path = `/joblisting/${this.joblisting.id}/jobapplication`;
      if (!this.jobapplication.id) {
        return path;
      }
      return `${path}/${this.jobapplication.id}`;
    },

    dataProps() {
      return {
        jobapplication: this.jobapplication,
        joblisting: this.joblisting,
        business: this.business,
      };
    },

    fieldGroups() {
      const items = [
        {
          label: "Tööpakkumisele kandideerimine",
          items: [
            {
              ref: "applyIntroduction",
              slug: "title",
              label: "Ametikoht",
              colProps: { md: 12 },
              component: "JobapplicationApplyIntroduction",
              props: {
                ...this.dataProps,
                curriculumError: this.curriculumError,
                hasApplied: this.hasApplied,
              },
            },
          ],
        },
      ];

      if (this.joblisting.skills && this.joblisting.skills.length)
        items.push({
          label: "Sinu oskused",
          hint: "Palun hinda enda oskuseid järgmistes valdkondades, vahemikus 0 kuni 100. <br>Väärtuse muutmiseks lohista punast pallikest paremale-vasakule.",
          items: [
            {
              slug: "skills",
              component: "CustomSkillsInput",
              colProps: { md: "12" },
              props: {
                options: this.joblisting.skills,
                disabled: this.hasApplied,
              },
            },
          ],
        });

      if (this.joblisting.questions && this.joblisting.questions.length)
        items.push({
          label: "Küsimused",
          hint: "Palun vasta järgmistele küsimustel.",
          items: [
            {
              slug: "questions",
              component: "JobapplicationQuestionsInput",
              colProps: { md: "12" },
              props: {
                options: this.joblisting.questions,
                disabled: this.hasApplied,
              },
            },
          ],
        });

      return items;
    },
  },

  methods: {
    async onApplyClick() {
      let response = await this.$dialog.confirm({
        title: "Tööpakkumisele kandideerimise kinnitamine",
        text: "Kas oled kindel, et soovid enda kandidatuuri edastada tööpakkujale?",
        actions: {
          false: "Ei, täiendan veel andmeid",
          true: {
            text: "Jah, kinnitan",
            color: "success",
          },
        },
      });

      if (response) {
        this.store();
      }
    },

    store() {
      this.storeLoading = true;
      this.curriculumError = false;
      this.deadlineError = false;

      this.form[this.jobapplication.id ? "put" : "post"](this.path)
        .then((response) => {
          if (response.data.applied) {
            this.$store.commit("jobapplication/ADD_OR_UPDATE_ITEM", response.data.item);

            this.$emit("updated", response.data.item);

            this.success = true;
            window.scrollTo(0, 0);
          } else {
            if (response.data.status === "curriculum_missing") {
              this.curriculumError = true;
            } else if (response.data.status === "deadline_passed") {
              this.deadlineError = true;
            }

            if (response.data.message) {
              this.$notify(response.data.message, "success", {
                color: "error",
                icon: "mdi-check",
              });
            }
          }
        })
        .catch((error) => {
          throw error;
        })
        .finally(() => {
          setTimeout(() => {
            this.storeLoading = false;
          }, 500);
        });
    },

    onEditCurriculumClick() {
      this.$refs["applyIntroduction"][0].showCurriculumDialog();
    },
  },
};
</script>
