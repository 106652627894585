<template>
  <v-list-item two-line class="px-0" style="min-height: 48px">
    <v-list-item-action class="my-0 mr-3">
      <slot name="icon">
        <v-icon :color="color">{{ icon }}</v-icon>
      </slot>
    </v-list-item-action>
    <v-list-item-content class="py-0">
      <v-list-item-title>
        <slot></slot>
      </v-list-item-title>
      <v-list-item-subtitle>
        <slot name="subtitle"></slot>
      </v-list-item-subtitle>
    </v-list-item-content>
  </v-list-item>
</template>

<script >
export default {
  props: {
    icon: {
      type: String,
      default: "mdi-check-circle-outline",
    },
    color: {
      type: String,
      default: "teal accent-4",
    },
  },
};
</script>