<style lang="scss">
// @import "@/dependencies.scss";
</style>

<template>
  <v-container>
    <v-row no-gutters class="d-flex justify-center my-7">
      <v-col cols="12" sm="10" md="8" lg="7">
        <CardTemplate
          class="mb-5"
          outlined
          v-if="jobapplication.skills && jobapplication.skills.length"
        >
          <template #header>
            <h2 class="text-h4">Kandidaadi hinnangud enda oskustele</h2>
          </template>

          <div class="font-weight-thin text-body-2 secondary--text mb-5" />

          <JobapplicationRanges :items="jobapplication.skills" />
        </CardTemplate>

        <CardTemplate
          class="mb-5"
          outlined
          v-if="jobapplication.questions && jobapplication.questions.length"
        >
          <template #header>
            <h2 class="text-h4">Kandidaadi vastused küsimustele</h2>
          </template>

          <div class="font-weight-thin text-body-2 secondary--text mb-5" />

          <div v-for="(item, i) in jobapplication.questions" :key="i">
            <h3 class="text-h6">{{ item.title }}</h3>
            <HtmlContent :content="item.value" class="text--secondary"></HtmlContent>
            <v-divider class="mb-3" v-if="jobapplication.questions.length > i + 1"></v-divider>
          </div>
        </CardTemplate>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import HtmlContent from "../../../../global/components/HtmlContent.vue";
import JobapplicationRanges from "./JobapplicationRanges.vue";
import CardTemplate from "../../../../page/components/templates/CardTemplate.vue";
export default {
  components: {
    CardTemplate,
    JobapplicationRanges,
    HtmlContent,
  },
  props: {
    jobapplication: {
      type: Object,
      required: true,
    },
  },
};
</script>