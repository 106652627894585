<template>
  <div class="business page">
    <NotFound v-if="mixinBusinessNotFound || joblistingNotFound" />
    <div v-else-if="business && joblisting && jobapplication">
      <v-container>
        <JobapplicationForm
          v-if="jobapplication"
          :business="business"
          :jobapplication="jobapplication"
          :joblisting="joblisting"
          @updated="jobapplication = $event"
        />
      </v-container>
    </div>
  </div>
</template>

<script>
import JobapplicationForm from "../components/save/JobapplicationForm.vue";
import NotFound from "@/blocks/not-found/views/NotFound";
import fetchBusinessMixin from "@/blocks/business/fetchBusinessMixin";
import emptyJobapplication from "@/blocks/jobs/jobapplication/emptyJobapplication.js";

export default {
  mixins: [fetchBusinessMixin],

  components: {
    NotFound,
    JobapplicationForm,
  },

  head() {
    return {
      title: "Tööpakkumisele kandideerimine" + this.$store.getters.setting("meta_brand"),
    };
  },

  created() {
    this.initialize();
  },

  data() {
    return {
      joblistingNotFound: null,
      jobapplication: null,
      joblisting: null,
      business: null,
    };
  },

  methods: {
    async initialize() {
      this.$store.commit("START_LOADING", "SaveJobapplication");

      try {
        const response = await this.axios.get(
          `/joblisting/${this.$route.params.joblisting_id}/jobapplication`,
        );
        this.joblisting = response.data.joblisting;
        const jobapplication = response.data.jobapplication;
        this.jobapplication = jobapplication ? jobapplication : emptyJobapplication;
        this.joblistingNotFound = false;
      } catch (error) {
        if (parseInt(error.response.status) === 404) {
          this.joblistingNotFound = true;
        } else {
          this.$catchApiErrors(error);
        }
      } finally {
        this.$store.commit("STOP_LOADING", "SaveJobapplication");
      }
    },
  },
};
</script>
