<template>
  <div class="business page">
    <NotFound v-if="mixinBusinessNotFound || mixinJoblistingNotFound"></NotFound>
    <Joblisting v-else-if="business" :business="business" :joblisting="joblisting" />
  </div>
</template>

<script>
import Joblisting from "@/blocks/jobs/joblisting/components/show/Joblisting.vue";
import NotFound from "@/blocks/not-found/views/NotFound";
import fetchBusinessMixin from "@/blocks/business/fetchBusinessMixin";
import fetchJoblistingMixin from "@/blocks/jobs/joblisting/fetchJoblistingMixin";

export default {
  mixins: [fetchBusinessMixin, fetchJoblistingMixin],

  components: {
    NotFound,
    Joblisting,
  },

  created() {
    this.mixinInitializeJoblisting();
  },

  head() {
    return this.mixinLoadingBusiness || this.mixinLoadingJoblisting
      ? {}
      : this.joblisting.head
    //   : {
    //       ...this.business.head,
    //       title: this.joblisting.title + " tööpakkumine | " + this.business.head.title,
    //     };
  },

  data() {
    return {
      joblisting: null, // initialized by mixin,
    };
  },
};
</script>
