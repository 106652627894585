<template>
  <v-container class="h-100">
    <v-card elevation="0" tile class="my-10">
      <v-card-title>
        <div class="w-100">
          <h1 class="text-h4">{{ content("title") }}</h1>
          <v-divider class="mt-3"></v-divider>
        </div>
      </v-card-title>
      <v-card-text>
        <div class="text-body-2" v-html="content('content')"></div>
      </v-card-text>
      <div v-if="showList">
        <group-card hide-title>
          <JoblistingsQuery
            :initial-query="{
              bookmarked_by_me: true,
              page: 1,
              per_page: 10,
              order_by: 'updated_at',
              order_desc: true,
            }"
            :use-cache="false"
          >
            <template #default="{ total }">
              <span class="text-body-2" v-if="total !== null">
                Jälgitud tööpakkumisi: {{ total }}
              </span>
            </template>
          </JoblistingsQuery>
        </group-card>
      </div>
      <div v-else class="pb-1 d-flex justify-center">
        <div style="max-width: 700px">
          <v-alert tile type="info" class="mx-3 mb-3">
            <h3 class="text-h6">{{ content("please_authenticate") }}</h3>
          </v-alert>
          <AuthForm outlined class="ma-3"></AuthForm>
        </div>
      </div>
    </v-card>
  </v-container>
</template>

<script>
import JoblistingsQuery from "../../jobs/components/JoblistingsQuery.vue";
// import BusinessListings from "../components/BusinessListings";
import AuthForm from "@/blocks/auth/components/AuthForm";

export default {
  components: {
    AuthForm,
    // BusinessListings,
    JoblistingsQuery,
  },

  head() {
    return this.content("head");
  },

  data() {
    return {
      showList: null,
    };
  },

  computed: {
    authUserId() {
      return this.$store.state.auth.user.id;
    },
  },

  watch: {
    authUserId: {
      handler: function () {
        this.handleAuthUser();
      },
      immediate: true,
    },
  },

  methods: {
    handleAuthUser() {
      this.showList = false;

      if (!this.authUserId) {
        return;
      }

      const query = {
        ...this.$store.state.business.bookmarked.query,
        page: 1,
        bookmarked_by: this.authUserId,
      };

      this.$store.commit("business/bookmarked/SET", {
        query,
        items: [],
        total: null,
        loadedPage: null,
      });

      this.showList = true;
    },

    content(key, defaultTo = "") {
      const data = this.$store.getters.content(["bookmarked_joblistings", key]);
      return data ? data : defaultTo;
    },
  },
};
</script>
