<template>
  <div :key="'curriculum' + (curriculum ? curriculum.id : '-none')">
    <div v-if="fetching"></div>
    <div v-else-if="curriculum">
      <v-container>
        <v-row no-gutters class="d-flex justify-center my-7">
          <v-col lg="11" class="d-flex justify-space-between align-end">
            <div class="mb-1">
              <h1 class="text-h2">CV Haldamine</h1>
            </div>
            <div v-if="curriculum.is_published">
              <base-btn
                color="white"
                block
                elevation="1"
                :to="{ name: 'ShowCurriculum', params: { user_id: curriculum.user_id } }"
              >
                Ava CV leht
              </base-btn>
            </div>
          </v-col>
        </v-row>
      </v-container>
      <CurriculumForm
        :curriculum="curriculum"
        :userId="$route.params.user_id"
        @updated="curriculum = $event"
      />
    </div>
    <DefaultTemplate v-else-if="!authUserId" class="pb-1">
      <template #title>CV lisamine</template>
      <v-alert tile type="info" class="mx-3 mb-3">
        <h3 class="text-h6">Enda CV lisamiseks palun logi sisse või registreeru kasutajaks.</h3>
      </v-alert>
      <AuthForm outlined class="ma-3"></AuthForm>
    </DefaultTemplate>
    <NotFound v-else />
  </div>
</template>

<script>
import Loading from "@/plugins/dialog/components/Loading.vue";
import NotFound from "@/blocks/not-found/views/NotFound.vue";
import CurriculumForm from "@/blocks/jobs/curriculum/components/save/CurriculumForm.vue";
import AuthForm from "@/blocks/auth/components/AuthForm.vue";
import DefaultTemplate from "@/blocks/page/components/templates/DefaultTemplate.vue";

export default {
  components: {
    DefaultTemplate,
    AuthForm,
    CurriculumForm,
    NotFound,
    Loading,
  },

  head() {
    return { title: "CV haldamine" + this.$store.getters.setting("meta_brand") };
  },

  computed: {
    authUserId() {
      return this.$store.state.auth.user.id;
    },
    routeId() {
      return this.$route.params.user_id;
    },
  },

  created() {
    this.fetch();
  },

  watch: {
    authUserId: {
      handler: function (newValue) {
        this.handleChange(newValue);
      },
    },
    routeId: {
      handler: function (newValue) {
        this.handleChange(newValue);
      },
    },
  },

  data() {
    return {
      fetching: false,
      curriculum: null,
    };
  },

  methods: {
    handleChange(newValue) {
      if (!newValue) {
        this.curriculum = null;
      } else {
        this.fetch();
      }
    },
    async fetch() {
      if (!this.$route.params.user_id) {
        return;
      }
      this.fetching = true;
      try {
        const response = await this.axios.get(
          `/user/${this.$route.params.user_id}/curriculum-form`,
        );
        this.curriculum = response.data;
      } catch (error) {
        // this.$catchApiErrors(error);
      } finally {
        this.fetching = false;
      }
    },
  },
};
</script>
