<template>
  <section>
    <div class="d-sm-flex w-100 justify-space-between mb-3">
      <div style="max-width: 400px" class="order-sm-last">
        <v-text-field
          dense
          outlined
          placeholder="Otsi tööpakkumist"
          :hide-details="true"
          v-model="searchTerm"
          append-icon="mdi-magnify"
          @click:append="onSearchClick"
        ></v-text-field>
      </div>
      <div>
        <v-chip
          close
          v-if="query.search_term"
          key="search-term"
          @click:close="onSearchTermClosed()"
          :title="`Otsingusõna: ` + query.search_term"
          class="ma-1 text-capitalize"
        >
          {{ query.search_term }}
        </v-chip>

        <h3 class="text-h5 font-weight-thin pb-2 pt-1" v-html="constraintsDescription" />
      </div>
    </div>

    <div class="pt-3">
      <ManagerJoblistingsList
        @updated="onUpdated"
        @destroyed="onDestroyed"
        show-last-divider
        for-business-manager
        :items="items"
        :business="business"
      />
      <div class="text-center">
        <div v-if="searchTerm && allLoaded"></div>
        <div
          v-else
          class="pb-5"
          :title="allLoaded ? 'Rohkem tööpakkumisi ei ole' : 'Lae rohkem tööpakkumisi'"
        >
          <base-btn
            color="black"
            :dark="!allLoaded"
            :loading="loading"
            @click="fetchNextPageItems"
            :disabled="allLoaded"
          >
            <span v-if="!total">Tööpakkumisi ei leitud</span>
            <span v-else-if="allLoaded">Rohkem tööpakkumisi ei ole</span>
            <span v-else>Lae rohkem tööpakkumisi</span>
          </base-btn>

          <div
            class="pt-3 text-body-2 text--disabled"
            v-if="query.page > 1 && total - items.length > 0"
          >
            <em>
              Laadida on veel {{ total - items.length }}
              {{ total - items.length > 1 ? "tööpakkumist" : "tööpakkumine" }}
            </em>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import ManagerJoblistingsList from "@/blocks/jobs/joblisting/components/show/ManagerJoblistingsList.vue";
const paginatedJoblistingsState = {
  items: [], // joblistings
  total: null,
  loadedPage: null,
  loading: false,
  query: {
    page: 1,
    per_page: 20,
    business_ids: [],
    order_by: null,
    order_desc: null,
    excluded_ids: [],
    bookmarked_by: null,
    search_term: null,
  },
};
export default {
  components: {
    ManagerJoblistingsList,
  },
  props: {
    business: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      latestJoblistings: [],
      popularJoblistings: [],
      popularSearchTerms: [],
      ...this._.cloneDeep(paginatedJoblistingsState),
      searchTerm: "",
    };
  },
  created() {
    this.fetchFirstPageItems(true);
  },
  computed: {
    constraintsDescription() {
      if (this.loading) {
        return "Palun oota..";
      } else if (!this.total) {
        return "Kriteeriumitele vastavaid tööpakkumisi ei leitud.";
      }
      const one = this.total === 1;
      let text = ``;
      if (this.query.search_term) {
        text += `märksõnale <strong>${this.query.search_term}</strong> `;
      }
      text += `leiti ${this.total} `;
      text += one ? "tööpakkumine" : "tööpakkumist";
      text += ".";
      return this.$capitalize(text);
    },

    allLoaded() {
      if (this.total === null) return null;
      return this.items.length >= this.total;
    },
  },
  methods: {
    onSearchClick() {
      this.query = { ...this.query, search_term: this.searchTerm };
      this.fetchFirstPageItems();
    },

    queryGet(field) {
      const str = this._.get(this.query, field, "").toLowerCase();
      if (!str) return null;
      return str.split(",");
    },

    onSearchTermClosed(category) {
      this.query = { ...this.query, search_term: "" };
      this.searchTerm = "";
      this.fetchFirstPageItems();
    },

    fetchFirstPageItems(skipIfFetched = false) {
      if (skipIfFetched && this.loadedPage === this.query.page) return;

      this.query = {
        ...this.query,
        page: 1,
      };

      this.fetchItems();
    },

    fetchNextPageItems() {
      if (this.loading) return;
      const page = this.query.page + 1;
      this.query = {
        ...this.query,
        page: page,
      };
      this.fetchItems();
    },

    onUpdated(updatedJoblisting) {
      const item = this.items.find((item) => parseInt(item.id) === parseInt(updatedJoblisting.id));
      if (typeof item === "undefined") {
        return;
      }
      Object.assign(item, updatedJoblisting);
      this.items = this._.clone(this.items);
    },

    onDestroyed(destroyedJoblistingId) {
      this.items = this.items.filter((el) => parseInt(el.id) !== parseInt(destroyedJoblistingId));
    },

    async fetchItems() {
      if (this.loading) return;
      this.loading = true;
      try {
        const response = await this.axios.post(
          `business/${this.business.id}/joblisting/query`,
          this.query,
        );
        const loadedPage = response.data.query.page;
        const isNewQuery = loadedPage === 1;
        const itemsReceived = response.data.data;
        this.items = isNewQuery ? itemsReceived : [...this.items, ...itemsReceived];
        this.query = {
          ...response.data.query,
          order_by: null,
          order_desc: null,
        };
        this.total = response.data.meta.total;
        this.loadedPage = loadedPage;
      } catch (e) {
        if (e.response.status == 401) {
          this.$emit("unauthorized", e);
        }
        throw e;
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
