<style lang="scss">
// @import "@/dependencies.scss";
</style>

<template>
  <WideTemplate>
    <template #title>Ehitussektori kandidaadid</template>
    <PublicCurriculums :store-state="$store.state.curriculum" store-accessor="curriculum/" />
  </WideTemplate>
</template>

<script>
import WideTemplate from "../../../page/components/templates/WideTemplate.vue";
import PublicCurriculums from "../components/index/PublicCurriculums.vue";

export default {
  components: {
    PublicCurriculums,
    WideTemplate,
  },
};
</script>