<template>
  <v-dialog v-model="isVisible" fullscreen hide-overlay transition="dialog-bottom-transition">
    <template v-slot:activator="{ on, attrs }" v-if="!hideActivator">
      <slot name="activator" v-bind="{ on, attrs }">
        <base-btn v-bind="{ dark: true, color: 'primary', ...attrs, ...btnProps }" v-on="on">
          {{ btnTitle }}
        </base-btn>
      </slot>
    </template>
    <v-card tile>
      <v-toolbar
        v-bind="{ dark: true, tile: true, color: 'primary', ...toolbarProps }"
        class="w-100"
        style="top: 0; position: sticky; z-index: 100"
      >
        <v-btn icon :dark="_.get(toolbarProps, ['dark'], true)" @click="isVisible = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title>{{ toolbarTitle }}</v-toolbar-title>
        <slot name="toolbar-items"></slot>
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-btn text @click="isVisible = false">
            {{ closeTitle }}
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>
      <v-sheet>
        <slot v-bind="{ closeDialog, isVisible }" name="default"></slot>
      </v-sheet>
    </v-card>
  </v-dialog>
</template>

<script>
import { randomString } from "@/common/helpers/randomString";
const routeKey = randomString();

export default {
  props: {
    routeKey: {
      type: String,
      default: routeKey,
    },
    btnProps: {
      type: Object,
      default: () => {},
    },
    toolbarProps: {
      type: Object,
      default: () => {},
    },
    btnTitle: {
      type: String,
      default: "Ava",
    },
    toolbarTitle: {
      type: String,
      default: "",
    },
    closeTitle: {
      type: String,
      default: "Sulge",
    },
    hideActivator: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isVisible: false,
    };
  },
  methods: {
    closeDialog() {
      this.isVisible = false;
    },
    // Used as $ref - don not clean up this method!
    showDialog() {
      this.isVisible = true;
    },
  },
  watch: {
    $route(route, oldRoute) {
      if (!route.query.dialog && oldRoute.query.dialog && oldRoute.query.dialog === this.routeKey) {
        this.isVisible = false;
      }
    },
    isVisible(isVisible) {
      const query = this.$route.query ? this.$route.query : {};
      if (isVisible) {
        this.$router.push({
          name: this.$route.name,
          params: this.$route.params,
          query: { dialog: this.routeKey },
        });
      } else if (this.$route.query.dialog && this.$route.query.dialog === this.routeKey) {
        this.$router.go(-1);
      }
    },
  },
};
</script>