<template>
  <div>
    <v-container v-if="!joblisting.id">
      <v-row no-gutters class="d-flex justify-center my-7">
        <v-col cols="12" sm="10" md="9" lg="8">
          <CardTemplate class="mb-5">
            <template #header>
              <h2 class="text-h4">Tööpakkumise lisamine ({{ business.title }})</h2>
            </template>
            <div class="d-flex flex-wrap">
              <v-text-field
                style="min-width: 300px"
                v-bind="formProps"
                name="title"
                v-model="form.title"
                label="Ametikoha nimetus"
                :error-messages="form.error('title')"
                :error="form.state('title')"
                class="mr-4 mb-4"
              />
              <base-btn
                height="56px"
                class=""
                name="submit"
                color="black"
                dark
                large
                :loading="storeLoading"
                @click="store()"
              >
                <span>Lisa tööpakkumine</span>
              </base-btn>
            </div>
            <v-alert tile dense v-if="form.errors.message" type="error" class="mt-3">
              <small v-html="form.errors.message" />
            </v-alert>
          </CardTemplate>
        </v-col>
      </v-row>
    </v-container>

    <v-container v-else>
      <v-row no-gutters class="d-flex justify-center my-7" v-if="joblisting && joblisting.id">
        <v-col lg="11">
          <div class="mb-1">
            <h1 class="text-h2">Tööpakkumise lisamine</h1>
          </div>
        </v-col>
      </v-row>

      <v-row no-gutters class="d-flex justify-center my-7">
        <v-col cols="12" sm="10" md="9" lg="8">
          <CardTemplate class="mb-5" v-for="(group, i) in fieldGroups" :key="i + 'group'">
            <template #header>
              <h2 class="text-h4">{{ group.label }}</h2>
            </template>

            <div
              v-if="group.hint"
              v-html="group.hint"
              class="font-weight-thin text-body-2 secondary--text mb-3"
            />
            <v-row class="my-5">
              <v-col
                v-bind="{ cols: '12', md: '6', ...field.colProps }"
                v-for="(field, i) in group.items"
                :key="i + 'field'"
              >
                <component
                  :is="field.component ? field.component : 'VTextField'"
                  v-bind="field.props ? field.props : formProps"
                  :name="field.slug"
                  v-model="form[field.slug]"
                  :label="field.label"
                  :hint="field.hint"
                  :error-messages="form.error(field.slug)"
                  :error="form.state(field.slug)"
                  :form-object="form"
                />
              </v-col>
            </v-row>
          </CardTemplate>
        </v-col>

        <v-col cols="12" sm="2" md="3" lg="3" class="min-h-100">
          <base-card elevation="0" class="ml-3 pt-3" style="position: sticky; top: 0">
            <v-card-text>
              <div v-if="joblisting.jobapplications_count" class="mb-5">
                <div class="text-h6">
                  Kandidaate:
                  <strong>{{ joblisting.jobapplications_count }}</strong>
                </div>
                <base-btn
                  target="_blank"
                  :to="{
                    name: 'JoblistingApplications',
                    params: { business_slug: business.slug, joblisting_id: joblisting.id },
                  }"
                  color="blue"
                  v-bind="joblisting.jobapplications_count ? { dark: true } : { disabled: true }"
                  class="mt-2"
                >
                  <v-icon left>mdi-open-in-new</v-icon>
                  Vaata kandidaate
                </base-btn>
              </div>
              <div class="">
                <div class="text-h6">Tööpakkumise staatus:</div>
                <v-switch
                  v-model="form.is_published"
                  :label="form.is_published ? 'Avaldatud' : 'Mitteavalik'"
                ></v-switch>

                <!-- <div class="my-3" v-if="joblisting.is_published">
                  <base-btn
                    outlined
                    text
                    block
                    :to="{
                      name: 'ShowJoblisting',
                      params: { business_slug: business.slug, joblisting_id: joblisting.id },
                    }"
                  >
                    Ava tööpakkumine
                  </base-btn>
                </div> -->
              </div>

              <div class="text-center">
                <base-btn
                  name="submit"
                  color="black"
                  dark
                  large
                  block
                  :loading="storeLoading"
                  @click="store()"
                >
                  <span>Salvesta</span>
                </base-btn>
                <div class="text-left mt-2" v-if="joblisting.jobapplications_count">
                  <small class="text--secondary">
                    * Kandidaatidega tööpakkumist on võimalik muuta ainult osaliselt.
                  </small>
                </div>
              </div>
              <v-alert tile dense v-if="form.errors.message" type="error" class="mt-3">
                <small v-html="form.errors.message" />
              </v-alert>
            </v-card-text>
          </base-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import NarrowListItemWithIcon from "@/blocks/jobs/joblisting/components/show/NarrowListItemWithIcon.vue";
import JoblistingPublishToggle from "@/blocks/jobs/joblisting/components/show/joblisting-item/JoblistingPublishToggle.vue";
// import CurrencyInput from "./inputs/CurrencyInput.vue";
import RichTextEditor from "@/blocks/jobs/joblisting/components/save/inputs/RichTextEditor.vue";
import DateInput from "@/blocks/jobs/joblisting/components/save/inputs/DateInput.vue";
import CardTemplate from "@/blocks/page/components/templates/CardTemplate.vue";
import Form from "@/common/form";
import emptyCurriculum from "@/blocks/jobs/curriculum/emptyCurriculum";
import { VTextField } from "vuetify/lib";
import { VTextarea } from "vuetify/lib";
import { VCombobox } from "vuetify/lib";
import LanguagesInput from "@/blocks/jobs/curriculum/components/save/LanguagesInput.vue";
import ImageUpload from "@/blocks/jobs/curriculum/components/save/ImageUpload.vue";
import { VueEditor } from "vue2-editor";
import SimpleRepeatableInput from "@/blocks/jobs/joblisting/components/save/SimpleRepeatableInput.vue";

const formProps = {
  hideDetails: "auto",
  persistentHint: true,
  persistentHint: true,
  filled: true,
  outlined: true,
  disabled: false,
};

export default {
  components: {
    VTextField,
    VTextarea,
    VCombobox,
    CardTemplate,
    DateInput,
    LanguagesInput,
    ImageUpload,
    VueEditor,
    RichTextEditor,
    JoblistingPublishToggle,
    NarrowListItemWithIcon,
    SimpleRepeatableInput,
  },

  props: {
    joblisting: {
      type: Object,
      required: true,
    },
    business: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      emptyCurriculum,
      form: new Form(this.joblisting),
      errorMessage: null,
      storeLoading: null,
      publishLoading: null,
      formPropsWithoutDisabled: formProps,
    };
  },

  computed: {
    formProps() {
      const data = this._.cloneDeep(formProps);
      if (this.joblisting.jobapplications_count) data.disabled = true;

      return data;
    },
    path() {
      const path = `/business/${this.business.id}/joblisting`;
      if (!this.joblisting.id) {
        return path;
      }
      return `${path}/${this.joblisting.id}`;
    },

    fieldGroups() {
      return [
        {
          label: "Üldandmed",
          items: [
            {
              slug: "title",
              label: "Ametikoht",
            },
            {
              slug: "workload",
              label: "Töökoormus",
              component: "VCombobox",
              props: {
                ...this.formProps,
                items: ["Täiskohaga", "Osalise koormusega", "Ajutine"],
              },
            },
            {
              slug: "location",
              label: "Asukoht",
            },
            {
              slug: "deadline",
              label: "Kandideerimise tähtaeg",
              component: "DateInput",
              props: this.formPropsWithoutDisabled,
            },
            {
              slug: "wage_offer_from",
              label: "Töötasu alates (€, bruto)",
              colProps: { md: "4" },
              props: {
                ...this.formProps,
                type: "number",
              },
            },
            {
              slug: "wage_offer_to",
              label: "Töötasu kuni (€, bruto)",
              colProps: { md: "4" },
              props: {
                ...this.formProps,
                type: "number",
              },
            },

            {
              slug: "jobs_available",
              label: "Vabade töökohtade arv",
              colProps: { md: "4" },
              props: {
                ...this.formPropsWithoutDisabled,
                type: "number",
                min: 1,
              },
            },
            {
              slug: "tags_array",
              label: "Märksõnad",
              component: "VCombobox",
              colProps: { md: 12 },
              props: {
                ...this.formPropsWithoutDisabled,
                items: ["elektritööd", "maalritööd", "projekteerimine", "transport", "mehhaanika"],
                chips: true,
                multiple: true,
                clearable: true,
                "deletable-chips": true,
                smallChips: true,
                delimiters: [","],
              },
            },
          ],
        },
        {
          label: "Tööpakkumise tunnuspilt",
          hint: `Määramata jätmisel kuvatakse tööpakkumise tunnuspildina ettevõtte tunnuspilt.`,
          items: [
            {
              slug: "list_image_src",
              label: "",
              component: "ImageUpload",
              colProps: { md: "12" },
              props: {
                path: this.path + "/list-image",
                imageName: "tunnuspilt",
                height: 627,
                width: 1200,
                thumbnailHeight: 125,
                thumbnailWidth: 200,
                onImageUploaded: (data, field) => {
                  this.$store.commit("joblisting/ADD_OR_UPDATE_ITEM", data.item);
                },
                onImageDestroyed: (data) => {
                  this.$store.commit("joblisting/ADD_OR_UPDATE_ITEM", data.item);
                },
              },
            },
          ],
        },
        {
          label: "Tööpakkumise kirjeldus",
          items: [
            {
              label: "Ootused kandidaadile",
              slug: "expectations",
              component: "RichTextEditor",
              colProps: { md: "12" },
              props: this.formPropsWithoutDisabled,
            },
            {
              label: "Tööülesannete kirjeldus",
              slug: "description",
              component: "RichTextEditor",
              colProps: { md: "12" },
              props: this.formPropsWithoutDisabled,
            },
            {
              label: "Omalt poolt pakume",
              slug: "benefits",
              component: "RichTextEditor",
              colProps: { md: "12" },
              props: this.formPropsWithoutDisabled,
            },
          ],
        },
        {
          label: "Nõutav keeleoskus",
          items: [
            {
              slug: "languages",
              component: "LanguagesInput",
              colProps: { md: "12" },
              props: {
                formProps: this.formProps,
                addLabel: "Lisa keel",
                disabledLevels: [1],
                // hasLevels: false,
              },
            },
          ],
        },

        {
          label: "Hinnatavad oskused",
          hint: "Sisesta siia need oskused või isikuomadused, mida soovid, et kandideerija peaks kandideerimisel hindama. Kandideerija annab endale antud valdkonnas hinnangu 0-st 100-ni. Nii saad infot, et kui tugevaks kandideerija end just selles valdkonnas peab.",
          items: [
            {
              slug: "skills",
              component: "SimpleRepeatableInput",
              colProps: { md: "12" },
              props: {
                formProps: this.formProps,
                addLabel: "Lisa oskus",
                itemLabel: "Oskuse nimetus",
                itemPlaceholder: "N: Dokumentatsiooni lugemine",
              },
            },
          ],
        },
        {
          label: "Küsimused kandidaadile",
          hint: "Nendele küsimustele peab kandideerija kandideerimisel vabas vormis vastama.",
          items: [
            {
              slug: "questions",
              component: "SimpleRepeatableInput",
              colProps: { md: "12" },
              props: {
                formProps: this.formProps,
                addLabel: "Lisa küsimus",
                itemLabel: "Küsimus",
                itemPlaceholder: "N: Miks arvad, et sobid sellele ametikohale?",
              },
            },
          ],
        },
        {
          label: "Tööpakkumise kontaktandmed",
          hint: 'Sisesta siia personaliga tegeleva töötaja kontaktandmed. Neid andmeid kuvatakse "Kandideeri" nupu juures. Jäta tühjaks, kui soovid et kontaktisiku andmeid ei kuvata.',
          items: [
            {
              slug: "contact_person_name",
              label: "Töötaja nimi",
              props: this.formPropsWithoutDisabled,
            },
            {
              slug: "contact_person_tel",
              label: "Telefoninumber",
              props: this.formPropsWithoutDisabled,
            },
            // {
            //   label: "Personaliga tegelev töötaja",
            //   slug: "contact_person",
            //   component: "RichTextEditor",
            //   colProps: { md: "12" },
            // },
          ],
        },
      ];
    },
  },

  methods: {
    store() {
      this.storeLoading = true;

      this.form[this.joblisting.id ? "put" : "post"](this.path)
        .then((response) => {
          this.$store.commit("joblisting/ADD_OR_UPDATE_ITEM", response.data.item);

          this.$emit("updated", response.data.item);

          if (response.data.message) {
            this.$notify(response.data.message, "success", {
              color: "#071b24",
              icon: "mdi-check",
            });
          }

          if (this.$route.name === "CreateJoblisting") {
            this.$router.replace({
              name: "EditJoblisting",
              params: { business_slug: this.business.slug, joblisting_id: response.data.item.id },
              query: { keep: 1 },
            });
          }
        })
        .catch((error) => {
          this.errorMessage = this._.get(error.response.data, "message", error.message);
          this.loading = false;
        })
        .finally(() => {
          setTimeout(() => {
            this.storeLoading = false;
          }, 500);
        });
    },
  },
};
</script>
