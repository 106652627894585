

<template>
  <div>
    <v-timeline
      align-top
      class="v-timeline--one-sided"
      dark
      :reverse="$vuetify.breakpoint.smAndUp"
      :dense="$vuetify.breakpoint.xs"
    >
      <v-timeline-item fill-dot left color="white" small v-for="(item, i) in items" :key="i">
        <template #opposite v-if="$vuetify.breakpoint.smAndUp">
          <div :class="`headline font-weight-bold white--text`">
            <div v-text="item.from" />
            <div v-text="item.to" />
          </div>
        </template>
        <div v-if="$vuetify.breakpoint.xs">
          <div :class="` font-weight-bold white--text mb-2`">
            <span v-text="item.from" />
            -
            <span v-text="item.to" />
          </div>
        </div>
        <h4 class="text-h5">
          {{ item.title }}
        </h4>
        <div class="text-body-1 mt-2 mr-5 mr-sm-10" v-html="item.description" />
      </v-timeline-item>
    </v-timeline>
  </div>
</template>

<script>
export default {
  props: {
    items: {
      type: Array,
      required: true,
    },
  },
};
</script>

<style lang="scss">
@import "@/dependencies.scss";
.v-timeline--one-sided {
  &.v-timeline:not(.v-timeline--dense) .v-timeline-item--after .v-timeline-item__body {
    max-width: calc(80% - 48px) !important;
  }

  &:not(.v-timeline--dense)::before {
    left: calc(20% - 1px) !important;
    right: initial;
  }
  .v-timeline-item__opposite {
    flex: 1 1 auto;
    align-self: start !important;
    max-width: calc(20% - 48px) !important;
  }

  @include media-breakpoint-down(sm) {
    &.v-timeline:not(.v-timeline--dense) .v-timeline-item--after .v-timeline-item__body {
      max-width: calc(70% - 48px) !important;
    }
    &:not(.v-timeline--dense)::before {
      left: calc(30% - 1px) !important;
    }
    .v-timeline-item__opposite {
      max-width: calc(30% - 48px) !important;
    }
  }
}

.theme--dark.v-timeline .v-timeline-item__dot {
  height: 16px !important;
  width: 16px !important;
  margin: 4px !important;
}
</style>