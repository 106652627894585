<template>
  <div class="multi-image-upload container">
    <div v-if="!fetched" class="text-center py-5 my-5">
      <v-progress-circular indeterminate></v-progress-circular>
    </div>
    <div v-else>
      <div class="pb-1">
        <v-scroll-y-transition group>
          <div v-for="(img, i) in items" :key="img.id">
            <v-divider class="my-5" v-show="i !== 0"></v-divider>
            <v-row>
              <v-col
                cols="6"
                md="1"
                class="d-flex flex-md-column align-md-center justify-md-space-between"
              >
                <div class="text-center">
                  <v-avatar color="grey lighten-3 cursor-default" size="28" class="mb-1">
                    <span class="text-subtitle-2 text--secondary">{{ i + 1 + "." }}</span>
                  </v-avatar>
                  <v-btn title="Nihuta alla" icon @click="moveUp(i)" v-show="i !== 0">
                    <v-icon>mdi-arrow-up</v-icon>
                  </v-btn>
                  <v-btn
                    title="Nihuta üles"
                    icon
                    @click="moveDown(i)"
                    v-show="i < items.length - 1"
                  >
                    <v-icon>mdi-arrow-down</v-icon>
                  </v-btn>
                </div>
              </v-col>
              <v-col cols="6" md="1" order-md="4" class="d-flex justify-end d-md-block">
                <v-menu bottom left>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn title="Kustuta rida" icon v-bind="attrs" v-on="on">
                      <v-icon>mdi-close</v-icon>
                    </v-btn>
                  </template>

                  <v-list>
                    <v-list-item @click="destroy(img)">
                      <v-list-item-action>
                        <v-icon>mdi-close</v-icon>
                      </v-list-item-action>
                      <v-list-item-title>Kustuta rida nr {{ i + 1 }} ?</v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </v-col>
              <v-col cols="12" md="5">
                <v-row>
                  <v-col cols="12">
                    <v-text-field
                      dense
                      label="Pealkiri"
                      v-bind="formProps"
                      v-model="img.title"
                      :error-messages="error(i, 'title')"
                      :error="state(i, 'title')"
                      class="mb-3"
                    ></v-text-field>
                    <v-textarea
                      dense
                      label="Kirjeldus"
                      v-bind="formProps"
                      v-model="img.description"
                      :error-messages="error(i, 'description')"
                      :error="state(i, 'description')"
                      rows="2"
                      auto-grow
                      hint="Millised olid teie ülesanded ja saavutused?"
                    ></v-textarea>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="12" md="5">
                <img
                  :src="img.thumbnail"
                  :alt="'Photo ' + img.id"
                  class="img-thumbnail"
                  style="border: none"
                />
              </v-col>
            </v-row>
          </div>
        </v-scroll-y-transition>
        <draggable v-if="false" v-model="items" class="v-row" @end="reorder()">
          <v-col v-for="(img, i) in items" :key="i" cols="12">
            <div class="text-center slide-links-wrap">
              <div class="image-relocate position-relative">
                <img
                  :src="img.thumbnail"
                  :alt="'Photo ' + img.id"
                  class="img-thumbnail"
                  style="border: none"
                />
                <div class="delete m-1">
                  <a
                    href="#destroy"
                    @click.prevent="destroy(img)"
                    title="Delete"
                    class="btn btn-dark btn-sm m-1"
                    style="border-radius: 50% !important"
                  >
                    <i class="la la-trash-alt"></i>
                  </a>
                </div>
                <div class="mt-1 mb-3">
                  <div class="d-flex" v-if="updateImageDescription.includes(img.id)">
                    <v-text-field
                      id="input-small"
                      class="mx-1"
                      size="sm"
                      placeholder="Foto allkiri"
                      v-model="img.description"
                    ></v-text-field>
                    <a
                      href="#"
                      class="btn btn-primary btn-sm"
                      @click.prevent="saveDescription(img)"
                      title="Save"
                    >
                      <i class="las la-save"></i>
                    </a>
                  </div>
                  <div v-else>
                    <small>
                      {{ img.description }}
                    </small>
                    <a href="#update" @click.prevent="updateImageDescription.push(img.id)">
                      <small>
                        {{ img.description ? "[muuda]" : "[lisa allkiri]" }}
                      </small>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </v-col>
        </draggable>
      </div>
      <div v-if="loading">
        <div :class="loading ? '' : 'invisible'" class="">
          <v-progress-circular
            :value="uploadProgress"
            indeterminate
            variant="info"
            striped
            animated
            class="mt-2"
          ></v-progress-circular>
          <p class="lead">Palun oota kuni fotosi laetakse..</p>
        </div>
      </div>

      <div class="mt-5">
        <vue-dropzone
          v-if="entryId"
          ref="imageDropzone"
          :options="dropzoneOptions"
          :useCustomSlot="true"
          id="items-upload"
          @vdropzone-success="fileUploaded"
          @vdropzone-total-upload-progress="updateUploadProgress"
          @vdropzone-file-added="onFileAdded()"
          @vdropzone-queue-complete="onQueueCompleted()"
          class="dropzone border-radius w-100"
        >
          <div class="py-3">
            <div class="container">
              <slot>
                <!-- Desktop -->
                <div class="d-none-sm-down">
                  <i class="fas fa-cloud-upload-alt"></i>
                  <p
                    class="h3 pb-4 text-muted"
                    v-html="
                      loading
                        ? 'Palun oota..'
                        : 'Referentsi fotode üleslaadimiseks lohista fotod siia'
                    "
                  ></p>
                  <div
                    class="subtitle d-none-sm-down"
                    v-html="loading ? '' : '... või kliki peale ja vali fotod kaustast.'"
                  ></div>
                </div>

                <!-- Mobile -->
                <div class="d-md-none">
                  <i class="fas fa-cloud-upload-alt"></i>
                  <p
                    class="h3 pb-4 text-muted"
                    v-html="loading ? 'Palun oota..' : 'Foto lisamiseks kliki sellel väljal'"
                  ></p>
                </div>
              </slot>
            </div>
          </div>
        </vue-dropzone>
      </div>
    </div>
  </div>
</template>

<script>
import vue2Dropzone from "vue2-dropzone";
import "vue2-dropzone/dist/vue2Dropzone.min.css";
import draggable from "vuedraggable";
import { reorderArray } from "@/common/helpers/reorderArray";

export default {
  components: {
    vueDropzone: vue2Dropzone,
    draggable: draggable,
  },
  props: {
    value: {
      type: Array,
      default: null,
    },
    entryId: {
      type: Number,
      required: true,
    },
    entryType: {
      type: String,
      required: true,
    },
    formProps: {
      type: Object,
      default: () => {},
    },
    formObject: {
      type: Object,
      default: null,
    },
    name: {
      type: String,
      default: null,
    },
  },

  created() {
    if (this.value === null || !this.value.length) {
      this.fetchImages();
    } else {
      this.items = this._.cloneDeep(this.value);
      this.fetched = true;
    }
  },

  data() {
    return {
      dropzoneOptions: {
        url: "/backpack-image-gallery/store",
        thumbnailWidth: 400,
        addRemoveLinks: true,
        createImageThumbnails: true,
        headers: { "X-CSRF-TOKEN": this.$store.state.csrf },
        params: { entry_type: this.entryType, entry_id: this.entryId },
      },
      items: [],
      uploadProgress: 1,
      loading: false,
      fetched: false,
      nextSequence: 1,
      updateImageDescription: [],
    };
  },

  watch: {
    items: {
      handler: function (items) {
        this.$emit("input", items);
      },
      deep: true,
    },
  },

  methods: {
    fetchImages() {
      this.axios
        .post(
          "/backpack-image-gallery/index",
          {
            entry_type: this.entryType,
            entry_id: this.entryId,
          },
          { baseURL: "/" },
        )
        .then((response) => {
          this.items = response.data;
          this.fetched = true;
        });
    },
    destroy(img) {
      this.axios
        .post(
          "/backpack-image-gallery/" + img.id + "/destroy",
          {
            entry_type: this.entryType,
            entry_id: this.entryId,
          },
          { baseURL: "/" },
        )
        .then((response) => {
          if (response.data.deleted === img.id) {
            this.items = this.items.filter((object) => object.id != img.id);
            this.$notify("Kustutatud.", "success", {
              color: "#071b24",
              icon: "mdi-close",
            });
          }
        });
    },
    saveDescription(img) {
      this.axios
        .put(
          "/backpack-image-gallery/" + img.id,
          {
            description: img.description,
            entry_type: this.entryType,
            entry_id: this.entryId,
          },
          { baseURL: "/" },
        )
        .then((response) => {
          img.description = response.data.description;
          this.updateImageDescription = this.updateImageDescription.filter(
            (object) => object != img.id,
          );
        });
    },
    reorder() {
      if (this.items.length < 2) return;

      let form = {
        entry_type: this.entryType,
        entry_id: this.entryId,
        order: [],
      };

      this.items.forEach(function (element, index) {
        form.order.push({
          sequence: index,
          id: element.id,
        });
      });

      this.axios
        .post("/backpack-image-gallery/reorder ", form, { baseURL: "/" })
        .then((response) => {
          //
        });
    },
    fileUploaded(file, response) {
      this.items.push(response);
    },
    updateUploadProgress(totaluploadprogress, totalBytes, totalBytesSent) {
      this.uploadProgress = totaluploadprogress - 20;
    },
    onFileAdded() {
      if (!this.loading) {
        this.loading = true;
      }
    },
    onQueueCompleted() {
      this.uploadProgress = 100;
      setTimeout(() => {
        this.loading = false;
        this.uploadProgress = 1;
      }, 1000);
    },
    moveUp(index) {
      if (index <= 0) return;
      reorderArray(this.items, index, index - 1);
    },
    moveDown(index) {
      if (index >= this.items.length - 1) return;
      reorderArray(this.items, index, index + 1);
    },
    error(i, field) {
      if (!this.formObject) return null;
      return this.formObject.error(this.name + "." + i + "." + field);
    },
    state(i, field) {
      if (!this.formObject) return null;
      return this.formObject.state(this.name + "." + i + "." + field);
    },
  },
};
</script>

<style lang="scss">
.multi-image-upload {
  input {
    min-height: 31px !important;
  }
  .VueCarousel-slide:hover {
  }
  .vue-dropzone {
    border: 2px dashed #0087f7;
  }

  .dz-message {
    margin: 30px 50px;
    max-height: max-content;
    align-self: center;
  }
  .image-relocate {
    cursor: all-scroll;
    img {
      transition: all 200ms linear;
    }
  }

  .btn-round {
    display: block;
    position: absolute;
    top: 8px;
    right: 8px;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background: #00b3e7;
    text-align: center;
  }

  .reorder-text {
    transition: all 0.2s ease-in-out;
    opacity: 0;
    color: #fff;
    font-weight: 900;
    font-size: 1.5rem;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    position: absolute;

    // .image-container:hover & {
    //   opacity: 1 !important;
    // }
    // z-index: 99;
  }

  .delete {
    color: #fff;
    position: absolute;
    top: 0;
    right: 0;
    transition: all 200ms linear;
    opacity: 0;
  }

  .image-relocate:hover {
    .delete {
      opacity: 1;
    }

    // img {
    //   transform: scale(1.06);
    // }

    .img-cover {
      opacity: 0.3;
    }

    .reorder-text {
      opacity: 1;
    }
  }
  .tooltip-inner {
    padding: 0.5rem !important;
  }
  .dz-success {
    //   opacity: 0 !important;
    //   transition: opacity 1s ease-in-out;
    display: none !important;
  }
  .dropzone.dz-started .dz-message {
    display: initial;
  }
}
</style>
