<template>
  <div class="page">
    <NotFound v-if="mixinBusinessNotFound || unauthorized"></NotFound>
    <ShowBusinessJoblistings
      @unauthorized="unauthorized = true"
      v-else-if="business"
      :business="business"
    />
  </div>
</template>

<script>
import ShowBusinessJoblistings from "../components/save/ShowBusinessJoblistings.vue";
import NotFound from "@/blocks/not-found/views/NotFound";
import fetchBusinessMixin from "@/blocks/business/fetchBusinessMixin";

export default {
  mixins: [fetchBusinessMixin],

  components: {
    NotFound,
    ShowBusinessJoblistings,
  },

  data() {
    return {
      unauthorized: false,
    };
  },

  head() {
    return this.mixinLoadingBusiness ? {} : { title: this.business.title + " | tööpakkumised" };
  },
};
</script>
