<template>
  <div>
    <div>
      <v-sheet
        class="position-relative"
        :dark="darkTheme != '0'"
        :height="isMobile ? undefined : 500"
        tile
        color="grey darken-4"
      >
        <transition name="fade-slow" mode="in-out">
          <template v-for="(image, i) in images">
            <v-overlay
              v-if="i === currentImageIndex"
              :key="i + '-img'"
              :opacity="image.overlay_opacity ? parseInt(image.overlay_opacity) / 100 : 0"
              :color="image.overlay_opacity && image.bg_color ? image.bg_color : null"
              absolute
              :z-index="0"
              :style="
                image.bg_image
                  ? `background:url(&quot;` +
                    image.bg_image +
                    `&quot;) no-repeat scroll center center;background-color:` +
                    (image.bg_color ? image.bg_color : '#4527a0')
                  : 'background-color:' + (image.bg_color ? image.bg_color : '#4527a0')
              "
            ></v-overlay>
          </template>
        </transition>
        <div>
          <div :style="'z-index: 1;'">
            <div class="h-100 position- d-flex align-center justify-center">
              <v-container class="joblistings-banner" :key="isMobile ? 'mobile' : 'desktop'">
                <v-row>
                  <v-col cols="12">
                    <div class="position-relative">
                      <div class="d-flex h-100">
                        <div
                          :style="!isMobile ? `margin-top: 130px` : ''"
                          class="mr-md-5 flex-shrink-1"
                        >
                          <JobsBannerSearch light @searched="onSearched" />
                        </div>
                      </div>
                    </div>
                  </v-col>
                </v-row>
              </v-container>
            </div>
          </div>
        </div>
      </v-sheet>
    </div>
  </div>
</template>

<script>
import JobsHeader from "../../layout/JobsHeader.vue";
import JobsBannerSearch from "./JobsBannerSearch";
export default {
  components: {
    JobsBannerSearch,
    JobsHeader,
  },

  mounted() {
    let timeout = parseInt(this.$store.getters.content(["joblistings", "timer_interval"]));

    if (!timeout || isNaN(timeout)) {
      timeout = 7000;
    } else if (timeout < 2000) {
      timeout = 2000;
    }

    if (this.images.length > 1) {
      this.interval = setInterval(() => {
        this.toggleImageSrc();
      }, timeout);
    }
  },
  beforeDestroy() {
    clearInterval(this.interval);
  },

  data() {
    return {
      interval: null,
      currentImageIndex: 0,
      active: 1,
    };
  },

  computed: {
    darkTheme() {
        return true;
    },
    images() {
      const items = this.$store.getters.content(["joblistings", "page_hero"]);
      if (!items || !Array.isArray(items)) return [];
      return items.filter((item) => {
        if (this.$vuetify.breakpoint.xs && item.small_screen) {
          return true;
        } else if (
          (this.$vuetify.breakpoint.sm || this.$vuetify.breakpoint.md) &&
          item.medium_screen
        ) {
          return true;
        } else if (this.$vuetify.breakpoint.lgAndUp && item.large_screen) {
          return true;
        }

        return false;
      });
    },
    bannerCssVars() {
      // theme.useHeroImg && theme.selectedStockPhoto ? theme.selectedStockPhoto : undefined;
      const styles = {
        "--bg-gradient-deg": 6 + "deg",
        "--bg-gradient-percent-1": 5 + "%",
        "--bg-gradient-percent-2": 20 + "%",
      };
      return styles;
    },

    isMobile() {
      return this.$vuetify.breakpoint.smAndDown;
    },
  },

  methods: {
    async onSearched({ searchTerm, locationTerm }) {
      this.$store.commit(`joblisting/SET_QUERY`, {
        search_term: searchTerm,
        location_term: locationTerm,
      });
      await this.$store.dispatch(`joblisting/fetchFirstPageItems`, false);
      this.$router.push({ name: "SearchJoblisting" });
    },
    toggleImageSrc() {
      if (this.currentImageIndex === this.images.length - 1) {
        this.currentImageIndex = 0;
        return;
      }
      this.currentImageIndex = this.currentImageIndex + 1;
    },
    image(type) {
      return "/storage/" + this.$store.getters.content(["joblistings", type]);
    },
  },
};
</script>
