<template>
  <div :class="`cur-ref-img-title`" :key="index">
    <div class="text-h4">{{ title }}</div>
    <div style="max-width: 600px; min-width: 300px" class="mt-2 text-body-2">{{ description }}</div>
    <div class="d-flex justify-end w-100">
      <small>{{ index + 1 }} / {{ count }}</small>
    </div>
  </div>
</template>

<script >
export default {
  props: {
    title: {
      type: String,
      required: true,
    },
    description: {
      type: String,
      required: true,
    },
    index: {
      type: Number,
      required: true,
    },
    count: {
      type: Number,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.cur-ref-img-title {
  background-color: black;
  padding: 6px 9px;
  color: #fff !important;
  font-size: 14px !important;
  overflow: hidden;
  position: absolute;
  left: 50%;
  bottom: 60px;
  transform: translate(-50%);
  font-size: 14px;
  line-height: 1.5;
  text-align: center;
  color: #ccc;
  opacity: 0.8;
  cursor: default;
  transition: opacity 0.4s ease-in-out;
  &:hover {
    opacity: 1;
  }
}
</style>
