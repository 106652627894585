<template>
  <v-avatar
    v-bind="{ size, ...$attrs }"
    :style="'background-image: url(' + flagsPath + ')'"
    :class="{
      'mr-3': left,
      'ml-3': right,
      'elevation-2': circular,
      'flag-icon': !circular,
    }"
    :is="circular ? 'v-avatar' : 'span'"
  ></v-avatar>
</template>

<script>
const flagsBasePath = "/storage/flags";
export default {
  props: {
    country: {
      type: String,
      required: true,
    },

    circular: {
      type: Boolean,
      default: true,
    },

    left: {
      type: Boolean,
      default: false,
    },

    right: {
      type: Boolean,
      default: false,
    },
    size: {
      type: Number,
      default: 36,
    },
  },
  computed: {
    flagsPath() {
      const folder = this.circular ? "1x1" : "4x3";
      return `${flagsBasePath}/${folder}/${this.country}.svg`;
    },
  },
};
</script>

<style lang="scss">
.flag-icon-background {
  background-size: contain;
  background-position: 50%;
  background-repeat: no-repeat;
}

.flag-icon {
  @extend .flag-icon-background;
  position: relative;
  display: inline-block;
  width: (4 / 3) * 1em;
  line-height: 1em;
  &:before {
    content: "\00a0";
  }
  &.flag-icon-squared {
    width: 1em;
  }
}
</style>
