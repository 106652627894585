<style lang="scss">
.joblistings-banner-search {
  margin-top: 80px;
  margin-bottom: 80px;
  @include media-breakpoint-up(md) {
    margin-top: initial;
    margin-bottom: initial;
  }
  h1 {
    font-size: 18px;
    @include media-breakpoint-up(md) {
      font-size: 22px;
    }
    @include media-breakpoint-up(lg) {
      font-size: 28px;
    }
    .lead {
      font-size: 36px;

      @include media-breakpoint-up(md) {
        font-size: 48px;
      }
      @include media-breakpoint-up(lg) {
        font-size: 48px;
      }
    }
  }
  @include media-breakpoint-up(md) {
    .search-text-field {
      font-size: 1.125rem;
      //   font-size: 1rem;
    }
  }
  .search-sheet {
    @include media-breakpoint-up(md) {
      width: 650px;
    }
  }
}
</style>

<template>
  <div class="joblistings-banner-search">
    <div>
      <h1 class="alternative-headings-font mb-2" style="font-size: 36px" v-html="coverTitle"></h1>
      <p v-if="coverSubtitle" class="text-body-1 mb-2" v-html="coverSubtitle"></p>
      <div class="mb-5 pt-2">
        <JobLinkRoutes>
          <template #default="{ addCurriculumRoute, addJoblistingRoute }">
            <base-btn
              :to="addCurriculumRoute"
              class="mb-2 mr-2"
              style="background-color: rgba(0, 0, 0, 0.5)"
            >
              <v-icon left>mdi-badge-account-horizontal-outline</v-icon>
              Lisa enda CV
            </base-btn>
            <base-btn
              :to="addJoblistingRoute"
              class="mb-2"
              style="background-color: rgba(0, 0, 0, 0.6)"
            >
              <v-icon left>mdi-badge-account-horizontal-outline</v-icon>
              Lisa töökuulutus
            </base-btn>
          </template>
        </JobLinkRoutes>
      </div>
    </div>
    <div class="position-relative">
      <div class="position-md-absolute" style="top: 0; left: 0; z-index: 50">
        <JobsSearchField v-on="$listeners" v-bind="$attrs" />
      </div>
    </div>
  </div>
</template>

<script>
import JobLinkRoutes from "../job-links/JobLinkRoutes.vue";
import JobsSearchField from "./JobsSearchField.vue";
export default {
  components: {
    JobsSearchField,
    JobLinkRoutes,
  },
  computed: {
    coverTitle() {
      return this.parseText("joblistings.cover_title", "Maailma parim kliendisuhete platvorm");
    },
    coverSubtitle() {
      return this.parseText("joblistings.cover_subtitle", null);
    },
  },
  methods: {
    parseText(getter, defaultTo = "") {
      const text = this.$store.getters.content(getter);
      if (!text) return defaultTo;
      return this.$replaceAll(text, "\r\n", "<br>");
    },
  },
};
</script>
