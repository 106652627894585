export default {
  data() {
    return {
      mixinLoadingJoblisting: null,
      mixinJoblistingNotFound: null,
      joblisting: null,
    };
  },

  watch: {
    $route() {
      this.mixinForceFetchJoblisting();
    },
  },

  computed: {
    /*
     * Determines if we already have full data of joblisting or only basic data
     * that were used to index joblistings.
     */
    mixinIsFinalJoblisting() {
      // return this.joblisting && this.joblisting.final;
      return this.joblisting ? true : false;
    },
  },

  methods: {
    mixinInitializeJoblisting() {
      this.mixinSetLocalClonedJoblisting();
      this.mixinFetchJoblisting();
    },

    /**
     * * Clone local copy of the joblisting.
     *
     * At first, there could only be joblisting's basic information and
     * author, content etc will be fetched on demand, so we need
     * to clone new object to make sure everything re-renders
     * after full data of joblisting is fetched.
     */
    mixinSetLocalClonedJoblisting() {
      this.joblisting = this._.cloneDeep(
        this.$store.getters["joblisting/findBy"]("items", "id", parseInt(this.$route.params.joblisting_id)),
      );
    },

    async mixinFetchJoblisting() {
      if (this.mixinIsFinalJoblisting) {
        return;
      }
      this.mixinForceFetchJoblisting();
    },

    async mixinForceFetchJoblisting() {
      this.mixinLoadingJoblisting = true;
      try {
        const response = await this.axios.get("joblisting/" + this.$route.params.joblisting_id);
        this.$store.commit("joblisting/ADD_OR_UPDATE_ITEM", response.data);
        this.mixinSetLocalClonedJoblisting();
      } catch (error) {
        if (error.response.status === 404) this.mixinJoblistingNotFound = true;
      } finally {
        this.mixinLoadingJoblisting = false;
      }
    },
  },
};
