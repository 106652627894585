<template>
  <form>
    <v-sheet
      class="search-sheet d-md-flex align-center w-100"
      :class="{ 'elevation-gentle': gentle }"
      :style="gentle ? undefined : 'border: 1px solid rgba(0, 0, 0, 0.3);'"
      outlined
      light
    >
      <JobsSearchLocationField v-if="$vuetify.breakpoint.mdAndUp" v-model="locationTerm" />

      <JobsSearchQueryField
        class="w-100"
        :location-term="locationTerm"
        v-model="searchTerm"
        :disable-instant-search="disableInstantSearch"
      />

      <div class="d-flex align-center d-md-block">
        <JobsSearchLocationField v-if="$vuetify.breakpoint.smAndDown" v-model="locationTerm" />

        <base-btn
          type="submit"
          @click.prevent="onSearchClick()"
          color="accent"
          elevation="0"
          class="mr-2"
          :loading="searching"
        >
          <span class="d-none d-sm-block mr-2">Otsi</span>
          <v-icon>mdi-magnify</v-icon>
        </base-btn>
      </div>
    </v-sheet>
  </form>
</template>


<script>
import JobsSearchLocationField from "./JobsSearchLocationField.vue";
import JobsSearchQueryField from "./JobsSearchQueryField";
export default {
  components: {
    JobsSearchQueryField,
    JobsSearchLocationField,
  },
  props: {
    light: {
      type: Boolean,
      default: false,
    },
    gentle: {
      type: Boolean,
      default: true,
    },
    search: {
      type: String,
      default: null,
    },
    location: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      locationTerm: this.location,
      searchTerm: this.search,
      searching: null,
      disableInstantSearch: false,
    };
  },

  methods: {
    onSearchClick() {
      this.searching = true;
      this.$emit("searched", { searchTerm: this.searchTerm, locationTerm: this.locationTerm });
      setTimeout(() => {
        this.searching = false;
      }, 1000);
    },
  },
};
</script>
