<style lang="scss">
.VueCarousel-navigation-button {
  outline: none !important;
}
</style>

<template>
  <div>
    <carousel
      v-if="joblistings.length"
      :mouse-drag="false"
      :scrollPerPage="false"
      :perPageCustom="[
        [280, 1],
        [500, 2],
        [823, 3],
        [1070, 4],
        [1425, 5],
      ]"
      :paginationEnabled="false"
      navigationEnabled
      navigationNextLabel='<i aria-hidden="true" class="v-icon notranslate v-icon--right mdi mdi-chevron-right theme--light" style="font-size: 36px;"></i>'
      navigationPrevLabel='<i aria-hidden="true" class="v-icon notranslate v-icon--right mdi mdi-chevron-left theme--light" style="font-size: 36px;"></i>'
    >
      <slide v-for="(joblisting, i) in joblistings" :key="`slide-${i}`">
        <div class="d-flex justify-center my-5 mx-1">
          <JoblistingGridItem style="width: 230px" :joblisting="joblisting" />
        </div>
      </slide>
    </carousel>
  </div>
</template>

<script>
import JoblistingGridItem from "../../joblisting/components/show/joblisting-item/JoblistingGridItem.vue";
import { Carousel, Slide } from "vue-carousel";
import cacheMixin from "@/mixins/cacheMixin";

export default {
  mixins: [cacheMixin],
  components: {
    Carousel,
    Slide,
    JoblistingGridItem,
  },
  props: {
    excludedIds: {
      type: Array,
      default: () => [],
    },
  },

  created() {
    this.pullCache(["joblistings", "query", "total", "loadedPage"], "LatestJoblistings");
    this.fetch();
  },
  destroyed() {
    this.saveCache();
  },

  data() {
    return {
      loading: null,
      joblistings: [],
      query: {
        page: 1,
        per_page: 20,
        order_by: "created_at",
        order_desc: true,
        excluded_ids: this.excludedIds,
      },
      total: null,
      loadedPage: null,
    };
  },
  computed: {
    allLoaded() {
      if (this.total === null) return null;
      return this.joblistings.length >= this.total;
    },
  },
  methods: {
    async fetch() {
      if (this.loadedPage === this.query.page) return;

      this.loading = true;
      try {
        const response = await this.axios.post("joblisting/query", this.query);
        this.joblistings = [...this.joblistings, ...response.data.data];
        this.query = response.data.query;
        this.total = response.data.meta.total;
        this.loadedPage = this.query.page;
      } finally {
        this.loading = false;
      }
    },
    loadMore() {
      if (this.loading) return;
      this.query.page++;
      this.fetch();
    },
  },
};
</script>