<style lang="scss">
// @import "@/dependencies.scss";
</style>

<template>
  <div>
    <slot v-bind="{ addCurriculumRoute, addJoblistingRoute }" name="default"></slot>
  </div>
</template>

<script>
export default {
  computed: {
    isAuthenticated() {
      return this.$store.state.auth.user.id !== null;
    },
    addCurriculumRoute() {
      return this.isAuthenticated
        ? { name: "SaveCurriculum", params: { user_id: this.$store.state.auth.user.id } }
        : {
            name: "Page",
            params: { slug: "lisa-enda-cv" },
            query: { intended_name: "SaveCurriculum", intended_params: "user_id" },
          };
    },

    addJoblistingRoute() {
      const firstBusinessSlug = this._.get(this.$store.state.auth.user, [
        "managed_businesses",
        0,
        "slug",
      ]);
      return firstBusinessSlug
        ? {
            name: "CreateJoblisting",
            params: { business_slug: firstBusinessSlug },
          }
        : {
            name: "Page",
            params: { slug: "lisa-toopakkumine" },
            query: { intended_name: "CreateJoblisting", intended_params: "business_slug" },
          };
    },
  },
};
</script>