<style lang="scss">
@import "@/dependencies.scss";

@include media-breakpoint-down(sm) {
  .job-links-col {
    max-width: 380px;
  }
  .job-links {
    padding-left: 20px;
  }
}
</style>

<template>
  <div class="">
    <div class="white pb-5">
      <JobsBanner />
      <v-container class="mt-5">
        <v-row justify="center">
          <v-col style="max-width: 320px">
            <JobsAd class="mt-5 mt-md-3" />
          </v-col>
          <v-col cols="12" sm="7" lg="8" class="job-links-col">
            <JobLinks class="job-links" />
          </v-col>
        </v-row>
      </v-container>
    </div>
    <v-container class="mt-3 mb-5">
      <v-row>
        <v-col
          cols="12"
          id="viimati-lisatud-toopakkumised"
          v-if="$store.state.joblisting.publishedJoblistingsTotalCount"
        >
          <group-card title="Viimati lisatud tööpakkumised">
            <div class="d-flex justify-center">
              <LatestJoblistingsSlider style="max-width: calc(100% - 60px)" />
            </div>
          </group-card>
        </v-col>

        <LastVisitedCurriculums
          ref="lastVisited"
          v-if="_.get($store.state.auth.user, 'is_business_manager')"
        />

        <v-col cols="12" v-if="$store.state.joblisting.publishedJoblistingsTotalCount">
          <group-card title="Tööpakkumised ehitussektoris">
            <!-- <h2 class="text-h5 font-weight-regular text-uppercase text-center">
              Tööpakkumised ehitussektoris
            </h2> -->

            <div class="mx-md-5">
              <JoblistingsQuery
                :initial-query="{
                  page: 1,
                  per_page: 10,
                  order_by: 'deadline',
                  order_desc: false,
                  featured_first: true,
                }"
                cache-key="AllJoblistings"
              />
            </div>
          </group-card>
        </v-col>
        <div v-else class="w-100">
          <div class="text-center w-100 text-body-2 my-5">
            Juba õige pea leiad siit ehitussektori tööpakkumised!
          </div>
        </div>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import JobsBanner from "../components/hero/JobsBanner.vue";
import LastVisitedCurriculums from "../components/LastVisitedCurriculums.vue";
import JoblistingsQuery from "../components/JoblistingsQuery.vue";
import LatestJoblistingsSlider from "../components/LatestJoblistingsSlider.vue";
import JobsAd from "../components/JobsAd.vue";
import JobLinks from "../components/job-links/JobLinks.vue";

export default {
  components: {
    JobLinks,
    JobsAd,
    LatestJoblistingsSlider,
    JoblistingsQuery,
    LastVisitedCurriculums,
    JobsBanner,
  },

  head() {
    return this.$store.getters.content("joblistings.head");
  },

  computed: {
    hideLastVisitedCurriculums() {
      return this._.get(this.$refs, ["lastVisited", "curriculums"], []).length === 0;
    },
  },
};
</script>